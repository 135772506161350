import { InputAdornment, TextField, Typography } from '@mui/material';
import { goBack } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js';
import { CONNECT_PRICE_CHANGE } from '../../constants/actionTypes';
import fetchData from '../../fetch';
import { fromUnitAmount } from '../../utils/unitAmountHelper';
import OnBack from '../Auth/OnBack';
import styles from './settings.module.scss';

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser.data,
        company: state.common.company,
        connected_account: state.common.connected_account,
        connected_products: state.common.connected_products,
        connected_prices: state.prices.connected_prices
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGoBack: () => dispatch(goBack()),
    onPriceChange: (payload) => dispatch({ type: CONNECT_PRICE_CHANGE, payload })
});

const Products = (props) => {
    const { t } = useTranslation();
    const [accountProducts, setAccountProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [onboardingMessage, setOnboardingMessage] = useState('');
    const location = useLocation();
    const history = useHistory();
    // const [present] = useIonToast();
    // const presentToast = (message) => {
    //     present({
    //         message: message,
    //         duration: 1500,
    //         position: 'top',
    //         icon: checkmarkCircleSharp,
    //         color: 'success'
    //     });
    // };

    const {
        register,
        reset,
        control,
        handleSubmit,
        getValues,
        formState: { isDirty, errors, defaultValues }
    } = useForm({
        defaultValues: {}
    });

    const productPeriod = [
        { id: t('Month'), name: 'month' },
        { id: t('Year'), name: 'year' }
    ];

    useEffect(() => {
        if (props.connected_account) {
            if (!props.connected_account?.onboarded) {
                history.replace(`${window.location.origin}/profile/account}`);
            }
        }
    }, [props.connected_account]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const fromStripe = queryParams.get('fromStripe');
        if (fromStripe) {
            setRedirect(true);
            setOnboardingMessage(t('SetPricesText'));
        }
    }, []);

    useEffect(() => {
        if (props.connected_products) {
            setAccountProducts(
                props.connected_products.filter((ca) => ca.metadata['setup-price'] === 'account')
            );
        } else {
            history.push('/profile/settings/account');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.connected_products]);

    useEffect(() => {
        let defaultValues = {};
        for (const accountProduct of accountProducts) {
            defaultValues[accountProduct.id] = props.connected_prices
                .filter((p) => p.product === accountProduct.id)
                .map((p) => fromUnitAmount(p.price.unit_amount, p.price.currency))
                .pop();
        }
        reset(defaultValues);
    }, [props.connected_prices, accountProducts]);

    const priceSelect = async (data) => {
        setIsLoading(true);
        try {
            for (let product of Object.keys(data)) {
                console.info(product);
                if (defaultValues[product] !== data[product]) {
                    const price = await fetchData.Prices.Create(
                        product,
                        props.connected_account.accountId,
                        data[product]
                    );
                    props.onPriceChange({ product, price });
                }
            }
            if (redirect) {
                history.push('/home');
            }
        } catch (error) {
            console.log(error.message);
        } finally {
            setIsLoading(false);
            // presentToast(t('ProductsPricesChanged'));
        }
    };

    return (
        <div>
            <OnBack
                defaultHref={'/profile/settings'}
                onClick={handleSubmit(priceSelect)}
                title={t('Product')}
                showButtonOrLoader={isLoading ? 'loader' : 'button'}
                buttonDisabled={!isDirty}
                buttonText={t('Save')}
            />
            <form style={{ minHeight: '0px' }}>
                <div
                    style={{
                        width: '100%',
                        maxWidth: '900px',
                        minHeight: '100vh',
                        margin: '0 auto',
                        position: 'relative',
                        padding: '5% 12px'
                    }}>
                    <main>
                        {onboardingMessage && (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {onboardingMessage}
                            </div>
                        )}
                        <div
                            className={styles.settingsList}
                            style={{ background: 'white', border: 'none' }}>
                            {accountProducts.map((p) => (
                                <TextField
                                    sx={{
                                        borderBottom: 'none !important',
                                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                                            {
                                                borderColor: 'black'
                                            }
                                    }}
                                    fullWidth
                                    defaultValue="EUR"
                                    type="number"
                                    key={p.id}
                                    {...register(p.id)}
                                    placeholder={`${p.name} price`}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment
                                                sx={{ color: 'white' }}
                                                position="start">
                                                <Typography color={'black'}>€</Typography>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            ))}
                        </div>
                    </main>
                </div>
            </form>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
