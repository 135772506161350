import React, { useEffect, useState } from 'react';
import styles from './eventListItem.module.scss';
import { useTranslation } from 'react-i18next';
import placeholder from '../../assets/images/placeholder.png';

function EventThumbnail(props) {
    const { t } = useTranslation();
    const [thumbnail, setThumbnail] = useState(props?.imagePreview ?? '');

    useEffect(() => {
        async function fetchThumbnail() {
            if (window.cordova) {
                setThumbnail(await props.event.getThumbnail(250, 150));
            } else {
                if (props.width && props.height) {
                    setThumbnail(await props.event.getThumbnail(props.width, props.height));
                } else {
                    setThumbnail(await props.event.getThumbnail(2000, 1000));
                }
            }
        }
        fetchThumbnail();
    }, [props.event]);

    useEffect(() => setThumbnail(props.imagePreview), [props?.imagePreview]);

    return (
        <img
            style={{ borderRadius: props.borderRadius ?? '' }}
            src={thumbnail || placeholder}
            className={styles.thumbnail}
            alt={t('EventThumbnail')}
        />
    );
}

export default EventThumbnail;
