import React, { useState } from 'react';
import styles from '../Event/eventListItem.module.scss';
import { Link } from 'react-router-dom';
import EventTag from '../Event/EventTag';
import actions from '../../assets/images/actions.svg';
import EventPlanOptions from './EventPlanOptions.js';
import { parseMatchTime } from '../../utils.js';
import { connect } from 'react-redux';
import EventThumbnail from '../Event/EventThumbnail';
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser,
        isAdmin: state.user.currentUser.groups && state.user.currentUser.groups.includes('Admin'),
        company: state.common.company
    };
};

function EventPlanItem(props) {
    const { t } = useTranslation();
    const event = props.event;
    const [isClicked, setIsClicked] = useState(false);
    const [isAdmin] = useState(props.isAdmin);
    const premiumOnly = process.env.REACT_APP_PREMIUM_ONLY === 'true';

    const buttonClicked = (e, isOpen) => {
        e.preventDefault();
        setIsClicked(isOpen);
    };

    let eventURL =
        isAdmin && event.stream_status === 'upcoming'
            ? `/events/${event.id}/modify`
            : premiumOnly
            ? `/events/${event.id}`
            : `/events/${event.id}`;

    return (
        <div className={styles.eventItem}>
            <Link to={eventURL}>
                <EventThumbnail event={event} />
                <div className={styles.eventDetails}>
                    <h3>{event.title}</h3>
                    <p>{parseMatchTime(event.start_date)}</p>
                    <aside>
                        {event.tags &&
                            event.tags.map((tag) => {
                                return <EventTag key={tag} tag={tag} />;
                            })}
                    </aside>
                </div>
            </Link>

            <button onClick={(e) => buttonClicked(e, true)}>
                <img src={actions} alt={t('EventActions')} />
            </button>

            {isClicked && (
                <EventPlanOptions
                    isAdmin={isAdmin}
                    company={props.company}
                    event={event}
                    isClicked={isClicked}
                    setIsClicked={setIsClicked}
                />
            )}
        </div>
    );
}

export default connect(mapStateToProps, null)(EventPlanItem);
