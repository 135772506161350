import sanity from '../../sanity';
import { UPLOADING_STATUS } from '../../constants';

export default class Event {
    constructor(props) {
        this.id = props._id || props.id;
        this.title = props.title;
        this.description = props.description;
        this.trailer = props.trailer;
        this.trailerUrl = props.trailerUrl;
        this.event_type = props.event_type;
        this.slug = props.slug;
        this.live_stream_url = props.liveStreamUrl;
        this.stream_status = props.streamStatus;
        this.stream_type = props.stream_type;
        this.tags = props.tags;
        this.thumbnail = props.thumbnail;
        this.sports_team = props.sports_team;
        this.end_date = props.end_date;
        if (props.endDate) {
            const endDate = new Date(props.endDate);
            this.end_date = endDate.toISOString();
        }
        const startDate = new Date(props.startDate);
        this.startDate = startDate;
        this.start_date = startDate.toISOString();
        if (props.start_stream_time) {
            this.start_stream_time = props.start_stream_time;
        }
        this.processing_progress =
            props.processingStatus === UPLOADING_STATUS
                ? props?.progress / 2
                : 50 + (props.processing_progress || 0) / 2;
        this.processingStatus = props.processingStatus;
        this.correlation_id = props.correlation_id;
        this.channel_status = props.channel_status;
        this.web_socket_connection_field = props.web_socket_connection_field;
        this.mediaLivePushEndpoint = props.mediaLivePushEndpoint;
        this.user_id = props.user_id;
        this.channel_id = props.channel_id;
        this.stream_arn = props.stream_arn;
        this.chat_room_arn = props.chat_room_arn;
        this.sticky_widgets = props.sticky_widgets;
        this.pay_per_view_price = props.pay_per_view_price;
        this.pay_per_view_price_amount = props.pay_per_view_price_amount;
        this.pay_per_view_price_currency = props.pay_per_view_price_currency;
        this.subscriber_exclusive = props.subscriber_exclusive || false;
        this.isPremium =
            props.subscriber_exclusive ||
            props.pay_per_view_price !== undefined ||
            props.isPremium ||
            false;
    }

    getTitle() {
        return this.title;
    }

    async getThumbnail(width, height) {
        if (this.thumbnail) {
            if (!this.thumbnail.image_asset) {
                if (this.thumbnail._ref) {
                    const asset = (await sanity.General.GetImageFromReference(this.thumbnail._ref))
                        ?.image_asset?.asset;
                    return sanity.urlFor(asset).width(width).height(height);
                } else return null;
            }

            return sanity.urlFor(this.thumbnail.image_asset).width(width).height(height);
        }
        return null;
    }

    static new(props) {
        return new Event(props);
    }
}
