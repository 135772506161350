import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getSymbolForCurrency } from '../../../utils';
import colors from '../../../utils/colors';
import styles from './profile.module.scss';

const StatsDesktop = (props) => {
    const { t } = useTranslation();

    return (
        <Box className={styles.stats}>
            <Box>
                <PlayArrowIcon
                    sx={{
                        backgroundColor: colors.primary,
                        width: '35px',
                        height: '35px',
                        borderRadius: '50%'
                    }}
                />
                <Typography fontSize={'30px'}>
                    {getSymbolForCurrency(props.currency)} {props.totalEarned}
                </Typography>
                <Typography sx={{ opacity: 0.5 }}>{t('TotalEarned')}</Typography>
            </Box>
            <Box>
                <PlayArrowIcon
                    sx={{
                        backgroundColor: colors.secondary,
                        width: '35px',
                        height: '35px',
                        borderRadius: '50%'
                    }}
                />
                <Typography fontSize={'30px'}>{props.totalStreams}</Typography>
                <Typography sx={{ opacity: 0.5 }}>{t('TotalStreams')}</Typography>
            </Box>
            <Box>
                <PlayArrowIcon
                    sx={{
                        backgroundColor: colors.primary,
                        width: '35px',
                        height: '35px',
                        borderRadius: '50%'
                    }}
                />
                <Typography fontSize={'30px'}>{props.totalStorage}/100GB</Typography>
                <Typography sx={{ opacity: 0.5 }}>{t('TotalStorage')}</Typography>
            </Box>
        </Box>
    );
};

export default StatsDesktop;
