import {
    CLEAR_EVENT_STREAMING,
    CREATE_EVENT,
    EVENT_LIST_PAGE_LOADED,
    EVENT_PAGE_LOADED,
    EVENT_PAGE_UNLOADED,
    EVENT_STREAMING_UPDATE,
    EVENT_UPDATE,
    MESSAGE_RECEIVED,
    REACTION_RECEIVED,
    REPORT_MESSAGE,
    REPORT_MESSAGE_CLEAR,
    RESET_WATCH_COUNT,
    SET_CHAT_MESSAGES,
    SET_CHAT_ROOM,
    SET_WATCH_COUNT_RESULT,
    WATCHING_NOW
} from '../constants/actionTypes';

import Event from '../core/entitites/event.entity';
export default (
    state = {
        chatMessages: [],
        chatLoading: true,
        events: [],
        eventStreamed: null,
        activeStreamingSlug: null,
        reportMessage: null,
        currentEvent: null,
        currentlyWatching: 0,
        views: {}
    },
    action
) => {
    switch (action.type) {
        case EVENT_LIST_PAGE_LOADED: {
            let events = [];
            if (!action.error) {
                events = action.payload;
            }
            return {
                ...state,
                events
            };
        }

        case EVENT_PAGE_LOADED:
            return {
                ...state,
                currentEvent: action.payload
            };

        case WATCHING_NOW:
            return {
                ...state,
                currentlyWatching: action.payload
            };

        case EVENT_PAGE_UNLOADED:
            return {
                ...state,
                currentEvent: null,
                chatLoading: true,
                chatMessages: []
            };

        case SET_CHAT_ROOM:
            return {
                ...state,
                roomId: action.payload
            };

        case MESSAGE_RECEIVED:
            return { ...state, chatMessages: [...state.chatMessages, action.payload] };

        case REACTION_RECEIVED: {
            const recived = state.reactionsReceived ? state.reactionsReceived : 0;
            return { ...state, reactionsReceived: recived + 1 };
        }
        case SET_CHAT_MESSAGES:
            return {
                ...state,
                chatMessages: [...action.payload, ...state.chatMessages],
                chatLoading: true
            };
        case EVENT_UPDATE: {
            let _events = state.events;
            const event = Event.new(action.payload);
            if (_events.find((x) => x.id === action.payload._id)) {
                _events = _events.map((x) => {
                    if (x.id === action.payload._id) {
                        return event;
                    }
                    return x;
                });
            } else {
                _events = [..._events, event];
            }
            return {
                ...state,
                events: _events
            };

            // console.log('action.payload', action.payload);
            // if (state.currentEvent) {
            //     if (state.currentEvent.id == action.payload._id) {
            //         return {
            //             ...state,
            //             currentEvent: sanity.Events.MapUpdatedEvent(action.payload)
            //         };
            //     } else return state;
            // } else if (state.events) {
            //     const filtered = state.events.filter((event) => event.id !== action.payload._id);
            //     filtered.push(sanity.Events.MapUpdatedEvent(action.payload));
            //     return {
            //         ...state,
            //         events: filtered
            //     };
            // } else {
            //     return { ...state, events: [sanity.Events.MapUpdatedEvent(action.payload)] };
            // }
        }
        case EVENT_STREAMING_UPDATE: {
            if (action?.payload?.slug === state.activeStreamingSlug)
                return {
                    ...state,
                    eventStreamed: action.payload
                };
            else return state;
        }
        case CREATE_EVENT: {
            return {
                ...state,
                activeStreamingSlug: action.payload.slug.current
            };
        }
        case CLEAR_EVENT_STREAMING:
            return { ...state, activeStreamingSlug: null, eventStreamed: null };
        case REPORT_MESSAGE: {
            return {
                ...state,
                reportMessage: action.payload
            };
        }
        case REPORT_MESSAGE_CLEAR: {
            return {
                ...state,
                reportMessage: null
            };
        }
        case SET_WATCH_COUNT_RESULT: {
            const views = action.payload;
            let event = state.currentEvent;
            if (event && event.id === views.eventId) {
                event = { ...event, ...views };
            }
            return {
                ...state,
                currentEvent: event,
                views: views
            };
        }
        case RESET_WATCH_COUNT: {
            return {
                ...state,
                views: {}
            };
        }
        default:
            return state;
    }
};
