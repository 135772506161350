import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import photo from '../../assets/images/homeImage.png';
import AccountCreation from './AccountCreation';
import styles from './login.module.scss';
import cognitoService from '../../core/service/cognito.service';
import { connect } from 'react-redux';
import { SET_USER_DATA } from '../../constants/actionTypes';
import NavMobile from '../Nav/NavMobile';
import NavDesktop from '../Nav/NavDesktop';
import logo from '../../assets/images/logo.svg';

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser
    };
};

const mapDispatchToProps = (dispatch) => ({
    onSetUserData: (payload) => dispatch({ type: SET_USER_DATA, payload })
});

const LoginPage = (props) => {
    const { t } = useTranslation();
    const [showImage, setShowImage] = useState(true);
    const [username, setUsername] = useState('');
    const [emailError, setEmailError] = useState('');
    const [confirmCode, setConfirmCode] = useState(false);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const authReq = await cognitoService.authenticate(username);
            props.onSetUserData({ username, step: true });
            if (authReq) {
                setEmailError(null);
                setShowImage(false);
                setConfirmCode(true);
            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
            if (e.code && e.code === 'UserLambdaValidationException') {
                setEmailError(t('UserNotExistMessage'));
                return;
            } else if (e.code && e.code === 'UserNotFoundException') {
                setEmailError(t('UserNotExistMessage'));
                return;
            }

            setEmailError(t('UserNotExistMessage'));
        }
    };

    return (
        <Box className={styles.registerContainer}>
            {isMobile && <NavMobile />}
            <Box className={styles.content}>
                {!isMobile && (
                    <Box className={styles.flex} sx={{ padding: 0, margin: '0 -4rem' }}>
                        <img
                            style={{ cursor: 'pointer' }}
                            onClick={() => history.push('/home')}
                            src={logo}
                            alt="logo"
                        />
                        <Box className={styles.flexNav}>
                            <Link>{t('About')}</Link>
                            <Link>{t('TermsOfConditions')}</Link>
                        </Box>
                    </Box>
                )}
                {isMobile && showImage && (
                    <Box className={styles.imageWrapper}>
                        <Box className={styles.backgroundImage}></Box>
                        <Box className={styles.gradientOverlay}></Box>
                    </Box>
                )}
                <Box
                    className={styles.registerContent}
                    sx={{ margin: isMobile && showImage ? '60% auto 0 !important' : '' }}>
                    <Typography
                        sx={{ margin: '1rem 0', zIndex: 2 }}
                        fontFamily={'InstrumentSans-bold'}
                        fontSize={'40px'}
                        textAlign={'center'}>
                        {t('WelcomeBack')}
                    </Typography>
                    <AccountCreation
                        isLogin={true}
                        handleSubmit={handleSubmit}
                        setUsername={setUsername}
                        username={username}
                        confirmCode={confirmCode}
                        emailError={emailError}
                        loading={loading}
                        setEmailError={setEmailError}
                    />
                    <Typography textAlign={'center'}>
                        {t('FirstTime')}
                        <Link to={'/register'} style={{ color: '#00D180' }}>
                            {t('SignUp')}
                        </Link>
                    </Typography>
                </Box>
            </Box>
            {!isMobile && (
                <Box className={styles.imageSection} sx={{ overflow: 'hidden' }}>
                    <img className={styles.image} src={photo} alt="home-image" />
                </Box>
            )}
        </Box>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
