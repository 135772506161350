import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { CircularProgress, IconButton, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import fetch from '../../../fetch';
import { getSymbolForCurrency } from '../../../utils';
import { fromUnitAmount } from '../../../utils/unitAmountHelper';
import OnBack from '../../Auth/OnBack';
import styles from '../../Profile/Customize/customize.module.scss';

const Transactions = () => {
    const user = useSelector((state) => state.user.currentUser);
    const isAdmin = useSelector(
        (state) => state.user.currentUser.groups && state.user.currentUser.groups.includes('Admin')
    );
    const connected_account = useSelector((state) => state.common.connected_account);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [transactionsList, setTransactionsList] = useState([]);

    const handleItemClick = (item) => {
        const link = document.createElement('a');
        link.download = item.number;
        link.href = item.invoice_pdf;
        link.click();
    };

    useEffect(() => {
        const fetchData = async () => {
            if (user && user.premium_services?.subscription) {
                setLoading(true);

                try {
                    const accountId = !isAdmin ? connected_account?.accountId : undefined;
                    const transactionsList = await fetch.Invoice.getAllInvoices(
                        user.premium_services?.subscription,
                        accountId
                    );
                    setTransactionsList(transactionsList);
                } catch (error) {
                    console.error('Error:', error);
                }

                setLoading(false);
            }
        };

        fetchData();
    }, [user]);

    return (
        <div>
            <OnBack defaultHref={'/profile'} title={t('TransactionHistory')} />
            <div className={styles.profileWrapper}>
                <div style={{ padding: '2vh 2vw 0vh' }}>
                    {loading ? (
                        <div
                            style={{
                                position: 'relative'
                            }}>
                            <CircularProgress
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '45%',
                                    transform: 'translate(-50%, -50%)'
                                }}
                            />
                        </div>
                    ) : (
                        <>
                            <div>
                                {transactionsList.map((item) => (
                                    <div
                                        key={item.id}
                                        className={styles.flexBetween}
                                        style={{
                                            borderBottom: '1px solid rgba(198, 198, 200, 0.5)'
                                        }}>
                                        <div
                                            className={styles.flexBetween}
                                            style={{
                                                padding: '2vh 0 '
                                            }}>
                                            <Typography
                                                style={{ marginRight: '12px', color: 'gray' }}>
                                                {`${getSymbolForCurrency(
                                                    item.currency
                                                )} ${fromUnitAmount(
                                                    item.amount_paid + item.amount_remaining,
                                                    item.currency
                                                )}`}
                                            </Typography>
                                            <Typography>
                                                {dayjs(item.created * 1000).format(
                                                    'MMM D, YYYY h:mm A'
                                                )}
                                            </Typography>
                                            {/* <Typography>{item.created}</Typography> */}
                                        </div>
                                        <IconButton onClick={() => handleItemClick(item)}>
                                            <FileDownloadOutlinedIcon />
                                        </IconButton>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Transactions;
