import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
    REFETCH_USER_DATA,
    SET_USER_DATA,
    SOCKET_CONNNECT,
    SPINNER_VISIBLE
} from '../../constants/actionTypes';
import cognitoService from '../../core/service/cognito.service';
import styles from '../../styles/auth.module.scss';
import OnBack from './OnBack';

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser
    };
};

const mapDispatchToProps = (dispatch) => ({
    onSocketConnect: () => dispatch({ type: SOCKET_CONNNECT }),
    onSpinner: (payload) => dispatch({ type: SPINNER_VISIBLE, payload }),
    onSetUserData: (payload) => dispatch({ type: SET_USER_DATA, payload }),
    onRefetchUserData: () => dispatch({ type: REFETCH_USER_DATA })
});

const EnterCodeStep = (props) => {
    const { t } = useTranslation();
    const [code, setCode] = useState(['', '', '', '']);
    const [errorMessage, setErrorMessage] = useState('');
    const history = useHistory();
    const firstInputRef = useRef(null);

    useEffect(() => {
        if (code.join('').length === 4 && code[0] !== 'D') {
            handleSubmit();
        }
    }, [code]);

    useEffect(() => {
        if (firstInputRef.current) {
            firstInputRef.current.addEventListener('input', handleAutofill);
        }
        return () => {
            if (firstInputRef.current) {
                firstInputRef.current.removeEventListener('input', handleAutofill);
            }
        };
    }, []);

    const handleAutofill = (event) => {
        const { value } = event.target;
        console.log(`handleAutofill - Value: ${value}`);
        if (value.length > 1) {
            const newCode = value.split('').slice(0, 4);
            setCode(newCode);
        }
    };

    const handleSubmit = async () => {
        try {
            props.onSpinner(true);
            const codeStr = code.join('');
            const resp = await cognitoService.respondToChallengeSMS(codeStr);
            if (resp) {
                const currentUser = await cognitoService.getUserData();
                setErrorMessage(null);
                codeSuccess();
                // setTimeout(() => {
                if (currentUser.data.given_name && currentUser.data.family_name) {
                    props.onSocketConnect();
                    props.onSetUserData(currentUser);
                    props.onRefetchUserData();
                    props.onSpinner(false);
                    // history.push('/events');
                    return;
                }
                props.onSocketConnect();
                props.onSpinner(false);
                history.push('/user-info');
                // }, 1000);
            }
        } catch (e) {
            props.onSpinner(false);
            if (e.code === 'NotAuthorizedException') {
                props.onSetUserData({ step: false });
                history.push('/');
                return;
            }
            setErrorMessage(e.message);
            setCode(['', '', '', '']);
        }
    };

    const handleCodeInput = (event, index) => {
        const { value } = event.target;
        console.log(`handleCodeInput - Index: ${index}, Value: ${value}`);
        if (/^\d*$/.test(value)) {
            const newCode = [...code];
            if (value.length > 1) {
                const splitValues = value.split('');
                for (let i = 0; i < splitValues.length && i < 4; i++) {
                    newCode[i] = splitValues[i];
                }
                setCode(newCode);
            } else {
                newCode[index] = value;
                setCode(newCode);
                if (value && index < 3) {
                    document.getElementById(`input${index + 1}`).focus();
                }
            }
        }
    };

    const handleKeyDown = (event, index) => {
        if (event.key === 'Backspace') {
            event.preventDefault();
            const newCode = [...code];
            if (newCode[index] === '') {
                if (index > 0) {
                    document.getElementById(`input${index - 1}`).focus();
                }
            } else {
                newCode[index] = '';
                setCode(newCode);
            }
        }
    };

    const handlePaste = (event) => {
        const pasteData = event.clipboardData.getData('text').slice(0, 4);
        console.log(`handlePaste - Paste Data: ${pasteData}`);
        if (/^\d{4}$/.test(pasteData)) {
            setCode(pasteData.split(''));
        }
    };

    const codeSuccess = () => {
        const successMessage = 'DONE';
        setCode(successMessage.split(''));
    };

    return (
        <div>
            <OnBack defaultHref="/" title={t('EnterCodeInstruction')} />
            <div className={styles.authWrapper}>
                <form
                    className={`${styles.codeGroupWrapper} ${
                        errorMessage ? styles.errorForm : ''
                    }`}>
                    <div className={styles.code}>
                        <p>{t('CodeSendTo')}</p>
                        <span>{props.user.username}</span>
                        <div className={styles.codeGroup} id="code-group">
                            {code.map((digit, index) => (
                                <input
                                    type="text"
                                    inputMode="numeric"
                                    autoComplete="one-time-code"
                                    key={index}
                                    className="code"
                                    value={digit}
                                    onChange={(event) => handleCodeInput(event, index)}
                                    onKeyDown={(event) => handleKeyDown(event, index)}
                                    onInput={(event) => handleCodeInput(event, index)}
                                    onPaste={handlePaste}
                                    id={`input${index}`}
                                />
                            ))}
                        </div>
                        <p className={styles.authError}>{errorMessage}</p>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(EnterCodeStep);
