import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import videojs from 'video.js';
import 'videojs-contrib-quality-levels';
import 'videojs-landscape-fullscreen';
import 'videojs-mobile-ui';
import 'videojs-mobile-ui/dist/videojs-mobile-ui.css';
import overlay from 'videojs-overlay';
import {
    SET_CONTROL_BAR,
    SET_VIDEO_MUTED_STATE,
    SET_WATCH_COUNT
} from '../../constants/actionTypes';
import { getVideoQualityPreset } from '../../helpers/helpers';
import sanity from '../../sanity';
import useInterval from '../hooks/useInterval';
import styles from './player.module.scss';
import './videojs-airplay.scss';
import './videojs-overlay.scss';
import './videojs-custom-seeker.css';

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser,
        _user: state.user.currentUser,
        videoState: state.appState.video,
        company: state.common.company,
        controlBar: state.common.company
    };
};

const mapDispatchToProps = (dispatch) => ({
    setVideoMuted: (payload) => dispatch({ type: SET_VIDEO_MUTED_STATE, payload }),
    setControlBar: (payload) => dispatch({ type: SET_CONTROL_BAR, payload }),
    setWatchCount: (payload) => dispatch({ type: SET_WATCH_COUNT, payload })
});

const Player = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [player, setPlayer] = useState(undefined);
    const [bannerVisible, setBannerVisible] = useState(false);
    const [logo, setLogo] = useState(false);
    const [isPortrait, setIsPortrait] = useState(false);
    const playerRef = useRef();
    const [user, setUser] = useState(props.user);
    const [errorFlag, setErrorFlag] = useState(true);
    const [flag, setFlag] = useState(false);

    useEffect(() => {
        setLogo(
            props.company &&
                props.company.company_logo_text &&
                sanity.urlFor(props.company.company_logo_text.asset._ref)
        );
    }, [props]);

    useEffect(() => {
        if (props.user.data) {
            setUser(props.user);
            if (player) {
                var playPromise = player.play();

                if (playPromise !== undefined) {
                    playPromise
                        .then((_) => {
                            // Automatic playback started!
                            // Show playing UI.
                        })
                        .catch((error) => {
                            // Auto-play was prevented
                            // Show paused UI.
                            console.error(error);
                        });
                }
            }
        }
    }, [props.user]);

    const LoggedInHash = props.uniqueHash;
    const videoJsOptions = {
        preload: 'auto',
        autoplay: 'muted',
        controls: true,
        playsinline: true,
        sources:
            props.event.trailer && props.showTrailer
                ? [
                      {
                          src: props.event.trailerUrl,
                          type: 'application/x-mpegURL'
                      }
                  ]
                : [
                      {
                          src: props.streamSource,
                          type: 'application/x-mpegURL'
                      }
                  ],
        techOrder: ['html5', 'HLS'],
        // plugins: {
        //     airPlay: {
        //         addButtonToControlBar: true // defaults to `true`
        //     }
        // },
        errorDisplay: false
    };

    let _videoPausedFlag = false;
    let intervalRef;
    let watchIntervalRef = useRef();
    let tenPercentWatched = useRef(false);
    // if (props.pastWidgets) {
    //     // eslint-disable-next-line react-hooks/rules-of-hooks
    //     intervalRef = useInterval(() => {
    //         props.videoTimeCallback(player.currentTime(), player.duration());
    //         if (player.currentTime() < 0) {
    //             window.clearInterval(intervalRef.current);
    //         }
    //     }, 10000);
    // }

    useEffect(() => {
        if (player) {
            player.muted(!player.muted());
        }
    }, [player]);

    useEffect(() => {
        const styleElement = document.createElement('style');
        styleElement.textContent =
            '.player-dimensions.vjs-fluid:not(.vjs-audio-only-mode) { padding-top: 56.25% !important; }';

        document.head.appendChild(styleElement);

        return () => {
            // Cleanup: Remove the style element when the component is unmounted
            document.head.removeChild(styleElement);
        };
    }, [isPortrait]);

    useEffect(() => {
        const p = videojs(playerRef.current, videoJsOptions, props.onPlayerReady);
        setPlayer(p);

        return () => {
            if (p) {
                p.dispose();
                setPlayer(null);
            }
            if (intervalRef) {
                window.clearInterval(intervalRef.current);
            }
        };
    }, []);

    useEffect(() => {
        window[LoggedInHash] = props._user?.token ? true : false;
    }, [props._user]);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden) {
                player.muted(true);
            } else {
                player.muted(false);
            }
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);

        if (player) {
            const loadedPlugins = videojs.getPlugins();

            // if (!loadedPlugins.airPlay) player.airPlay();
            if (!loadedPlugins.overlay) videojs.registerPlugin('overlay', overlay);
            //
            // if (!loadedPlugins.hlsQualitySelector)
            //     videojs.registerPlugin('hlsQualitySelector', qualitySelector);
            //
            // if (typeof player.hlsQualitySelector == 'function')
            //     player.hlsQualitySelector({ displayCurrentQuality: true });

            player.overlay({
                content: overlayTopLeftContent(),
                debug: false,
                overlays: [
                    {
                        start: 'pause',
                        end: 'play',
                        attachToControlBar: true,
                        align: 'top-left'
                    },
                    {
                        start: 'userActive',
                        end: 'userInActive',
                        attachToControlBar: true,
                        align: 'top-left'
                    }
                ]
            });

            player.on('loadedmetadata', () => {
                const videoWidth = player.videoWidth();
                const videoHeight = player.videoHeight();
                setIsPortrait(videoWidth < videoHeight);
            });

            player.on('pause', () => {
                _videoPausedFlag = true;
                clearInterval(player._updateInterval);
                player.trigger('userInActive');
            });

            player.on('play', () => {
                _videoPausedFlag = false;
                watchIntervalRef = setInterval(() => {
                    try {
                        const currentTime = player.currentTime();
                        const duration = player.duration();
                        if (!tenPercentWatched.current && currentTime >= 0.1 * duration) {
                            tenPercentWatched.current = true; // Set the flag to true to avoid multiple triggers
                            props.setWatchCount({
                                user: props.user.data.sub,
                                eventId: props.event.id,
                                companyId: props.company._id
                            });
                            clearInterval(watchIntervalRef.current);
                        }
                    } catch (e) {
                        //ignore this error
                    }
                }, 3000);
                player.trigger('userInActive');
            });

            player.on('error', () => {
                if (errorFlag) {
                    props.setTechnicalError(true);
                    setErrorFlag(false);
                }
            });

            player.on('useractive', () => _videoPausedFlag != true && player.trigger('userActive'));

            player.on('userinactive', () => player.trigger('userInActive'));

            // Listen for the 'timeupdate' event, which fires as the video is playing
            // player.on('timeupdate', throttle(function() {
            //
            // } , 5000 ));

            // Listen for the 'ended' event, which fires when the video finishes playing
            player.on('ended', function () {
                // Log the total time played when the video ends
                //console.log('Total time played: ' + totalTimePlayed + ' seconds');
                if (props.event.trailer) {
                    if (props.showTrailer) {
                        props.setTrailerEnded(true);
                    }
                    props.setShowPremiumContent(true);
                }
            });

            player.ready(() => {
                //? setTimeout required for eventLoop
                setTimeout(() => {
                    if (player.player_) {
                        //? Listen for all volumeChanges events and update state if player is muted or not.
                        player.on('volumechange', () => props.setVideoMuted(player.muted()));
                        player.muted(props.videoState.muted);
                    }
                }, 100);

                // handleControlBar();
                //? check if user is logged in if they are not, stop video after x seconds and lock controls.
                // let interval = setInterval(() => {
                //     (() => {
                //         if ((user.loaded && user.data) || !player?.player_) {
                //             clearInterval(interval);
                //             handleControlBar('');
                //             return;
                //         }

                //         var whereYouAt = player.player_.currentTime();

                //         if (user.loaded && !user.data) {
                //             player.pause();
                //             setBannerVisible(true);
                //             // addBannerForNonExistingUser();
                //             setFlag(true);
                //             clearInterval(interval);
                //             // player.play = () => {};
                //         }
                //     })();
                // }, 4000);
            });

            registerEventChange();
        }
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            if (watchIntervalRef) {
                clearInterval(watchIntervalRef.current);
            }
        };
    }, [player, props.displaySeconds, user]);

    const handleControlBar = (display = 'none') => {
        try {
            let controlBar = document.getElementsByClassName('vjs-control-bar')[0];
            if (controlBar) controlBar.style.display = display;
        } catch (e) {
            console.info(e);
        }
    };

    // const addBannerForNonExistingUser = () => {
    //     let controlBar = document.getElementsByClassName('vjs-control-bar')[0];
    //     props.setControlBar(player.controlBar);
    //     controlBar.style.display = 'flex';
    //     controlBar.style.flexDirection = 'row';
    //     controlBar.style.justifyContent = 'center';

    //     while (controlBar.firstChild) controlBar.removeChild(controlBar.firstChild);

    //     var ButtonComp = videojs.getComponent('button');
    //     var signUp = new ButtonComp(player, {
    //         clickHandler: function () {
    //             history.push('/register');
    //         }
    //     });
    //     signUp.addClass('sign-up');
    //     player.controlBar.addChild(signUp);
    //     document.querySelector('.sign-up').innerHTML = `<p style="color: white">${t(
    //         'SignupForFullVideo'
    //     )}</p>`;
    //     document.querySelector('.sign-up').style.width = 'fit-content';
    // };

    const overlayTopLeftContent = () =>
        `
            <img src=${
                props.company &&
                props.company.company_logo_text &&
                sanity.urlFor(props.company.company_logo_text.asset._ref)
            } style="height: 25px; width: auto;" alt="Logo" /> <br /> 
            <div style="font-size: 12px;">
                ${props.event.title}
            </div>
        `;

    const registerEventChange = () => {
        let _show = true; // this fixes: current closures issue.
        player
            .qualityLevels()
            .on(
                'change',
                () =>
                    _show &&
                    setPreference(props.user != null ? getVideoQualityPreset(props.user) : 360) &&
                    (_show = false)
            );
    };

    const setPreference = (videoPreset) => {
        if (!props.isLive) {
            if (!player?.hlsQualitySelector?._qualityButton?.items) return false;

            player.hlsQualitySelector._qualityButton.items[videoPreset]?.handleClick();
        }
        return true;
    };

    return (
        <>
            <div className={`data-vjs-player video-container `}>
                <video
                    id="player"
                    preload="metadata"
                    ref={playerRef}
                    className="video-js vjs-default-skin vjs-big-play-centered"
                    data-setup='{ "fluid" : true }'
                    playsInline
                />
            </div>

            {/* {flag ? (
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                        setFlag(false);
                        history.push('/register');
                    }}
                    className={styles.child}>
                    <Typography
                        color={'white !important'}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            width: '100%',
                            padding: '1%'
                        }}>
                        {t('SignupForFullVideo')}
                    </Typography>
                </div>
            ) : null} */}

            {/* {bannerVisible && (
                <div
                    onClick={() => setBannerVisible(false)}
                    className={`${styles.wrapperOverlay} ${styles.unselectable}`}
                    style={{ backdropFilter: 'blur(4px)' }}>
                    <Box className={styles.content}>
                        <Typography variant="h5" sx={{ marginY: 4 }}>
                            {t('SignupContinueWatching')}
                        </Typography>
                        <img
                            src={logo}
                            style={{ height: '75px', width: '75px', borderRadius: '50%' }}
                            alt="Logo"
                        />
                        <Typography variant="h6" sx={{ marginY: 4 }}>
                            {t('SignupForOtherContent')}
                        </Typography>
                        <Button
                            onClick={() => {
                                setFlag(false);
                                history.push('/register');
                            }}
                            sx={{
                                color: 'white',
                                backgroundColor: 'black',
                                paddingY: '12px',
                                marginY: '12px',
                                width: '100%',
                                textTransform: 'capitalize',
                                '&:active, &:focus, &:hover': {
                                    color: 'white',
                                    backgroundColor: 'black'
                                }
                            }}>
                            {t('SignUp')}
                        </Button>
                        <Button
                            onClick={() => setBannerVisible(false)}
                            variant="text"
                            sx={{
                                color: 'black',
                                paddingY: '12px',
                                textTransform: 'capitalize',
                                width: '100%',
                                border: '1px solid black',
                                '&:active, &:focus, &:hover': {
                                    color: 'black',
                                    backgroundColor: 'white'
                                }
                            }}>
                            {t('Cancel')}
                        </Button>
                    </Box>
                </div>
            )} */}
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Player);
