import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { EVENT_LIST_PAGE_LOADED } from '../../constants/actionTypes';
import sanity from '../../sanity';
import OnBack from '../Auth/OnBack';
import CreateVideoPage from '../CreateVideo/CreateVideoPage';
import EventPlanItem from './EventPlanItem';
import EventPlanTabs from './EventPlanTabs';
import styles from './eventPlan.module.scss';

const mapStateToProps = (state) => ({
    events: state.event.events,
    isAdmin: state.user.currentUser.groups && state.user.currentUser.groups.includes('Admin'),
    user: state.user.currentUser,
    company: state.common.company
});

const mapDispatchToProps = (dispatch) => ({
    onLoad: (payload) => dispatch({ type: EVENT_LIST_PAGE_LOADED, payload })
});

function EventPlan(props) {
    const { t } = useTranslation();
    useEffect(() => {
        props.onLoad(sanity.Events.GetEvents(props.isAdmin));
    }, []);

    const [scheduleEventOpen, setScheduleEventOpen] = useState(false);
    const [activeTab, setActiveTab] = useState(1);
    const [eventList, setEventList] = useState([]);

    const openEvent = () => {
        setScheduleEventOpen(true);
    };
    const goBack = () => {
        setScheduleEventOpen(false);
    };

    useEffect(() => {
        if (props.events) {
            if (activeTab === 1) {
                // put events in scheduled tab
                setEventList(props.events.filter((event) => event.stream_status === 'upcoming'));
            } else if (activeTab === 2) {
                // put events in published tab
                setEventList(props.events.filter((event) => event.stream_status === 'archived'));
            } else {
                // put events in scheduled tab
                setEventList(props.events.filter((event) => event.stream_status === 'publishing'));
            }
        }
    }, [props.events, activeTab]);

    useEffect(() => {
        let list = document.getElementById('events');
        list.classList.add('animateList');
        const timeout = setTimeout(() => {
            list.classList.remove('animateList');
        }, 500);
        return () => clearTimeout(timeout);
    }, [activeTab]);

    return (
        <div>
            <OnBack defaultHref={'/profile'} title={t('EventPlanner')} />
            {!scheduleEventOpen && (
                <div className={styles.profileWrapper}>
                    {!props.company?.disable_company && (
                        <div className={styles.btnOverlay}>
                            <button onClick={openEvent}>{t('ScheduleEvent')}</button>
                        </div>
                    )}
                    <h2>{t('Broadcasts')}</h2>
                    <EventPlanTabs setActiveTab={setActiveTab} activeTab={activeTab} />
                    <div id={'events'}>
                        {eventList
                            .sort((e1, e2) => e2.startDate.getTime() - e1.startDate.getTime())
                            .map((teamEvent) => {
                                // console.log("TEAM EVENT: ", teamEvent)
                                return <EventPlanItem key={teamEvent.id} event={teamEvent} />;
                            })}
                    </div>
                </div>
            )}
            {scheduleEventOpen && (
                <CreateVideoPage back={goBack} setScheduleEventOpen={setScheduleEventOpen} />
            )}
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(EventPlan);
