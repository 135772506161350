import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import logo from '../../assets/images/logo.svg';
import { SET_USER_DATA, SOCKET_DISCONNNECT } from '../../constants/actionTypes';
import cognitoService from '../../core/service/cognito.service';
import styles from '../CreateVideo/createVideo.module.scss';

const mapStateToProps = (state) => ({
    isAdmin: state.user.currentUser.groups && state.user.currentUser.groups.includes('Admin')
});

const mapDispatchToProps = (dispatch) => ({
    onLogout: () => {
        dispatch({
            type: SET_USER_DATA,
            payload: {
                data: null,
                token: null,
                loaded: true,
                imagePreview: null,
                name: null,
                nickname: null,
                step: false,
                surname: null,
                username: null
            }
        });
        dispatch({ type: SOCKET_DISCONNNECT });
    }
});

const NavDesktop = (props) => {
    const { t } = useTranslation();
    const path = window.location.pathname;
    const history = useHistory();

    const deleteCookie = (name) => {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    };

    const onLogout = () => {
        cognitoService.logoutUser();
        props.onLogout();
        deleteCookie('PremiumStatus');
        history.push('/');
    };
    return (
        <Box className={`${styles.flexBetween} ${props.hasBackground ? styles.overlay : ''}`}>
            <Box className={styles.flex}>
                <img
                    style={{ cursor: 'pointer' }}
                    onClick={() => history.push(props.isAdmin ? '/home' : 'my-library')}
                    src={logo}
                    alt="logo"
                />
                <Box className={styles.flexNav}>
                    <Link>{t('About')}</Link>
                    <Link>{t('TermsOfConditions')}</Link>
                </Box>
            </Box>
            <Box
                className={styles.rightButtons}
                sx={{
                    backgroundColor:
                        path === '/my-library' || path.includes('edit') ? '#EBF0EC !important' : ''
                }}>
                <Box
                    onClick={() => history.push('/my-library')}
                    className={
                        path === '/my-library' || path.includes('edit')
                            ? styles.primaryButton
                            : styles.whiteButton
                    }>
                    {t('MyLibrary')}
                </Box>
                {props.isAdmin && (
                    <Box
                        onClick={() => history.push('/profile')}
                        className={
                            path === '/profile' ? styles.secondaryButton : styles.whiteButton
                        }>
                        {t('Profile')}
                    </Box>
                )}
                <Box
                    onClick={onLogout}
                    className={path === '/profile' ? styles.whiteButton : styles.blackButton}>
                    {t('SignOut')}
                </Box>
            </Box>
        </Box>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(NavDesktop);
