import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import sanity from '../../sanity';
import { parseMatchTime } from '../../utils';
import actions from '../../assets/images/actions.svg';
import EventTag from '../Event/EventTag';
import styles from '../Event/eventListItem.module.scss';
import EventOptions from '../Event/EventOptions';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

// import photo2 from '../../assets/images/photo2.jpeg';
// import { parseMatchTime } from '../../utils.js';
// import sanityClient from '../client.js';

export default function BlogPostItem(props) {
    const { t } = useTranslation();
    const blogPost = props.blogPost;

    const [isClicked, setIsClicked] = useState(false);
    // const [present] = useIonToast();
    // const presentToast = (message) => {
    //     present({
    //         message: message,
    //         duration: 5000,
    //         position: 'top',
    //         icon: checkmarkCircleSharp,
    //         color: 'success'
    //     });
    // };

    const buttonClicked = (e, isOpen) => {
        e.preventDefault();
        setIsClicked(isOpen);
    };

    const notify = (message) => {
        if (message === 'Blog post already exists') {
            // presentToast(message);
        } else {
            // presentToast(message);
        }
    };

    return (
        <div>
            <div className={styles.eventItem}>
                <Link to={`/blog/${blogPost.slug.current}`}>
                    <img
                        src={sanity.urlFor(blogPost.mainImage.asset._ref)}
                        alt={blogPost.mainImage.alt}
                        className={styles.thumbnail}
                    />
                    <div className={styles.eventDetails}>
                        <h3>{blogPost.title}</h3>
                        <p>{parseMatchTime(blogPost._createdAt)}</p>
                        <aside>
                            {blogPost.tags &&
                                blogPost.tags.map((tag) => {
                                    return <EventTag key={tag} tag={tag} />;
                                })}
                        </aside>
                    </div>
                </Link>

                <button onClick={(e) => buttonClicked(e, true)}>
                    <img src={actions} alt={t('BlogActions')} />
                </button>
                {isClicked && (
                    <EventOptions
                        event={blogPost}
                        isClicked={isClicked}
                        setIsClicked={setIsClicked}
                        notify={notify}
                    />
                )}
            </div>
        </div>
    );
}
