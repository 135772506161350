import {
    Button,
    CircularProgress,
    FormControlLabel,
    InputAdornment,
    Modal,
    Switch,
    TextField,
    Typography
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { CONNECT_PRICE_CHANGE, EVENT_LIST_PAGE_LOADED } from '../../../constants/actionTypes';
import fetchData from '../../../fetch';
import sanity from '../../../sanity';
import { parseMatchTime } from '../../../utils';
import { isUrlValid } from '../../../utils.js';
import { fromUnitAmount } from '../../../utils/unitAmountHelper';
import OnBack from '../../Auth/OnBack';
import Thumbnails from '../../CreateVideo/Thumbnails';
import stylesNav from '../../Profile/settings.module.scss';
import EventTag from '../EventTag';
import stylesEventList from '../eventListItem.module.scss';
import styles from './modifyEvent.module.scss';

const mapStateToProps = (state) => {
    return {
        isAdmin: state.user.currentUser.groups && state.user.currentUser.groups.includes('Admin'),
        events: state.event.events,
        connected_account: state.common.connected_account,
        connected_products: state.common.connected_products,
        connected_prices: state.prices.connected_prices
    };
};

const mapDispatchToProps = (dispatch) => ({
    onLoad: (payload) => dispatch({ type: EVENT_LIST_PAGE_LOADED, payload }),
    onPriceChange: (payload) => dispatch({ type: CONNECT_PRICE_CHANGE, payload })
});

function ModifyEvent(props) {
    const PRODUCT_FIELDS_PREFIX = 'product.';
    const { connected_account, connected_products, connected_prices } = props;
    const { t } = useTranslation();
    const [isAdmin] = useState(props.isAdmin);
    const [photo, setPhoto] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [saveChangesDisabled, setSaveChangesDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const eventId = history.location.pathname.split('/').at(2);
    const hiddenFileInput = useRef(null);
    const [thumbnailsURL, setThumbnailsURL] = useState([]);
    const [chosenThumbnail, setChosenThumbnail] = useState(null);
    const [eventProducts, setEventProducts] = useState([]);

    let ar = [];
    const findProperEvent = () => props.events.find((x) => x.id === eventId);
    const [event, setEvent] = useState(findProperEvent());
    const [openDatepicker, setOpenDatepicker] = useState(false);
    const [imageChanged, setImageChanged] = useState(false);
    const {
        register,
        reset,
        handleSubmit,
        control,
        formState: { errors, defaultValues, isValid }
    } = useForm({
        defaultValues: { start_date: dayjs() }
    });
    const fileInputRef = useRef(null);

    var date = new Date();
    var now = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
    // const [present] = useIonToast();
    // const presentToast = (message, icon, color) => {
    //     present({
    //         message: message,
    //         duration: 1500,
    //         position: 'top',
    //         icon: icon,
    //         color: color
    //     });
    // };

    const handleSave = async (data) => {
        try {
            setLoading(true);
            console.info(data);
            const startDate = dayjs(data?.start_date?.$d).valueOf();
            console.info(dayjs(defaultValues.start_date.$d).valueOf());
            if (
                startDate !== dayjs(defaultValues.start_date.$d).valueOf() &&
                startDate < new Date()
            ) {
                // presentToast('Please select date in the future', closeCircleSharp, 'danger');
            } else {
                let prices = {};
                for (let product of Object.keys(data.product)) {
                    if (data.product[product]) {
                        let price;
                        if (defaultValues.product[product] != data.product[product]) {
                            price = await fetchData.Prices.Create(
                                product,
                                props.connected_account.accountId,
                                data.product[product]
                            );

                            props.onPriceChange({ product: product, price });
                        } else {
                            price = props.connected_prices
                                .filter((pp) => pp.product === product)
                                .pop();
                        }
                        if (price.price) {
                            prices[price.price.metadata['sub-type']] = price.id;
                        } else {
                            prices[price.metadata['sub-type']] = price.id;
                        }
                    }
                }
                if (chosenThumbnail !== null) {
                    await fetchData.Event.UpdateEvent({
                        method: 'PATCH',
                        body: JSON.stringify({
                            event: {
                                title: data.title,
                                startDate: new Date(startDate),
                                pay_per_view_price: prices['pay-per-view'],
                                _id: event.id
                            },
                            thumbnailURL: chosenThumbnail
                        })
                    });
                } else {
                    let thumbnail = null;
                    if (imageChanged) {
                        const image = await fetchData.Event.UploadThumbnail({
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({
                                img: {
                                    data: imagePreview.split(';base64,')[1],
                                    name: uuidv4()
                                }
                            })
                        });
                        if (image?._id) {
                            thumbnail = {
                                _ref: image._id,
                                _type: 'reference'
                            };
                            event.thumbnail = thumbnail;

                            setEvent(event);
                            setPhoto(null);
                        }
                    }
                    if (thumbnail) {
                        await fetchData.Event.UpdateEvent({
                            method: 'PATCH',
                            body: JSON.stringify({
                                event: {
                                    title: data.title,
                                    startDate: new Date(startDate),
                                    pay_per_view_price: prices['pay-per-view'],
                                    _id: event.id,
                                    thumbnail: thumbnail
                                }
                            })
                        });
                    } else {
                        await fetchData.Event.UpdateEvent({
                            method: 'PATCH',
                            body: JSON.stringify({
                                event: {
                                    title: data.title,
                                    startDate: new Date(startDate),
                                    pay_per_view_price: prices['pay-per-view'],
                                    _id: event.id,
                                    subscriber_exclusive: true
                                }
                            })
                        });
                    }
                }
                // presentToast('Event updated', checkmarkCircleSharp, 'success');

                setTimeout(() => {
                    history.goBack();
                }, 1800);
            }
        } catch (error) {
            console.log(error.message);
        } finally {
            setLoading(false);
        }
    };

    if (props.isAdmin !== true) history.goBack();

    useEffect(() => {
        event == undefined && props.onLoad(sanity.Events.GetEvents(isAdmin));
    }, []);

    useEffect(() => {
        const event = findProperEvent();
        setEvent(event);
    }, [props.events]);

    useEffect(() => {
        if (props.connected_products) {
            setEventProducts(
                props.connected_products.filter((ca) => ca.metadata['setup-price'] === 'event')
            );
        }
    }, [props.connected_products]);

    const resetAsyncForm = useCallback(async () => {
        let values = { title: event?.title, start_date: dayjs(event.startDate) };
        let productPrices;
        if (event.pay_per_view_price && connected_prices.length > 0) {
            const price = connected_prices.find((p) => p.price.id === event.pay_per_view_price);
            console.log('PRICE: ', price);
            if (!price) {
                const price = await fetchData.Prices.Get(
                    event.pay_per_view_price,
                    connected_account.accountId
                );
                productPrices = {
                    [price.product]: fromUnitAmount(price.unit_amount, price.currency)
                };
                props.onPriceChange({ product: price.product, price });
            } else {
                productPrices = {
                    [price.product]: fromUnitAmount(price.price.unit_amount, price.price.currency)
                };
            }
        }
        values = { ...values, product: { ...productPrices } };

        reset(values); // asynchronously reset your form values
    }, [reset, event, connected_prices]);

    useEffect(() => {
        if (event) {
            resetAsyncForm();
        }
    }, [event, connected_prices]);

    useEffect(() => {
        const fetchThumbnail = async () => {
            if (event) {
                setImagePreview(await event.getThumbnail(140, 90));
            }
        };
        fetchThumbnail();
        const fetchThumbnails = async () => {
            if (event && event.live_stream_url) {
                const isLiveStreamedVideo =
                    event.live_stream_url.includes('fnt-ivs-live-streaming');

                let url = isLiveStreamedVideo
                    ? event.live_stream_url.replace('hls/master.m3u8', '')
                    : event.live_stream_url.replace('/main.m3u8', '');

                let thumbnailURL;
                let eventSpec = url.split('/').pop();

                let search = true;
                let i = 0;
                while (search) {
                    thumbnailURL = isLiveStreamedVideo
                        ? `${url}thumbnails/thumb${i}.jpg`
                        : url + `/thumbnails/${eventSpec}thumbnail.000000${i}.jpg`;

                    let status = await isUrlValid(thumbnailURL);
                    if (status) {
                        ar.push(thumbnailURL);
                        thumbnailURL = '';
                    } else {
                        search = false;
                    }

                    i++;
                }

                console.log('THUMNBAIL: ', thumbnailURL);
                setThumbnailsURL(ar);
            }
        };
        fetchThumbnails();
    }, [event]);

    const chooseThumbnail = (thumb) => {
        if (thumb != null) {
            if (chosenThumbnail != null && thumb === chosenThumbnail) {
                setChosenThumbnail(null);
            } else setChosenThumbnail(thumb);
        }
    };

    const handleImageChange = async (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let currentFile = e.target.files[0];
        reader.onloadend = () => {
            setImagePreview(reader.result);
            currentFile = reader.result;
        };
        reader.readAsDataURL(currentFile);
        setImageChanged(true);
    };

    const handleFileUploadClick = (e) => {
        setChosenThumbnail(null);
        e.stopPropagation();
        fileInputRef.current.click();
    };

    useEffect(() => {
        setPhoto(imagePreview);
    }, [imagePreview]);

    return (
        <div>
            <OnBack defaultHref={'/events'} title={t('ChangeEvent')} />
            <div className={stylesNav.profileWrapper}>
                <main>
                    {isAdmin === false && (
                        <div className={styles.noPerms}>
                            <h1>Insufficient permissions</h1>
                            <button onClick={() => history.goBack()}>Go back</button>
                        </div>
                    )}

                    {isAdmin === true && event && (
                        <>
                            <div>
                                {thumbnailsURL && (
                                    <>
                                        <small>
                                            *{t('ScrollToSelectImage')} <br /> *
                                            {t('ChooseOneOrUpload')}.
                                        </small>
                                        <div className={styles.autoThumbnailList}>
                                            {thumbnailsURL &&
                                                thumbnailsURL.map((el, index) => {
                                                    return (
                                                        <img
                                                            key={el}
                                                            id={index}
                                                            src={el}
                                                            style={{
                                                                height: '100px',
                                                                margin: '10px'
                                                            }}
                                                            className={
                                                                chosenThumbnail == el
                                                                    ? styles.test
                                                                    : ''
                                                            }
                                                            onClick={() => chooseThumbnail(el)}
                                                        />
                                                    );
                                                })}
                                        </div>
                                    </>
                                )}

                                {!thumbnailsURL ||
                                    (thumbnailsURL.length == 0 && (
                                        <>
                                            <small>*{t('CannotGenerateThumbnail')}</small>
                                            <br />
                                            <br />
                                        </>
                                    ))}
                            </div>

                            <small>*{t('ClickOnThumbnailToChange')}</small>
                            <div
                                style={{
                                    display: isMobile ? '' : 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginBottom: isMobile ? '2vh' : ''
                                }}>
                                <div className={`${stylesEventList.resetThumbnails}`}>
                                    <Thumbnails
                                        imagePreview={imagePreview}
                                        setImagePreview={setImagePreview}
                                        fileInputRef={fileInputRef}
                                        handleFileUploadClick={handleFileUploadClick}
                                        handleImageChange={handleImageChange}
                                    />
                                </div>
                                <div className={stylesEventList.eventDetails}>
                                    <h3>{event.title}</h3>
                                    <p className={styles.modifyEventPara}>
                                        {parseMatchTime(event.start_date)}
                                    </p>
                                    <aside>
                                        {event.tags &&
                                            event.tags.map((tag) => {
                                                return <EventTag key={tag} tag={tag} />;
                                            })}
                                    </aside>
                                </div>
                                <input
                                    accept="image/*"
                                    type="file"
                                    style={{ display: 'none' }}
                                    ref={hiddenFileInput}
                                    onChange={async (event) => {
                                        setPhoto(await handleImageChange(event));
                                    }}
                                    disabled={saveChangesDisabled}
                                />
                            </div>
                            <form
                                className={stylesNav.resetInput}
                                style={{ minHeight: '100%', marginTop: '2%' }}
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                                onSubmit={handleSubmit(handleSave)}>
                                <TextField
                                    {...register('title', {
                                        required: true
                                    })}
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                    label={t('VideoTitle')}
                                />
                                <div className={stylesNav.resetMuiDatepicker}>
                                    <Controller
                                        name="start_date"
                                        control={control}
                                        //        rules={{ required: true }}
                                        render={({ field: { onChange, value, ...rest } }) => (
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateTimePicker
                                                    label="Choose a date"
                                                    sx={{
                                                        width: '100%',
                                                        marginBottom: '1vh'
                                                    }}
                                                    open={openDatepicker}
                                                    value={value}
                                                    onClose={() => setOpenDatepicker(false)}
                                                    slotProps={{
                                                        textField: {
                                                            onClick: () => setOpenDatepicker(true)
                                                        }
                                                    }}
                                                    onChange={onChange}
                                                />
                                            </LocalizationProvider>
                                        )}
                                    />
                                </div>
                                <div style={{ marginTop: '1%' }}>
                                    {eventProducts.map((p) => (
                                        // eslint-disable-next-line react/jsx-key
                                        <div key={p.id}>
                                            <TextField
                                                type="number"
                                                label={t(`${p.metadata['sub-type']}Price`)}
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                sx={{ marginBottom: '1rem' }}
                                                {...register(`${PRODUCT_FIELDS_PREFIX}${p.id}`)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment
                                                            sx={{ color: 'white' }}
                                                            position="start">
                                                            <Typography color={'black !important'}>
                                                                €
                                                            </Typography>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>
                                <div style={{ paddingBottom: isMobile ? '15%' : '5%' }}>
                                    {loading ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                marginTop: '12px'
                                            }}>
                                            <CircularProgress
                                                sx={{
                                                    width: '50px !important',
                                                    height: '50px !important'
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <button
                                            className={stylesNav.saveButton}
                                            disabled={saveChangesDisabled}>
                                            {t('SaveChanges')}
                                        </button>
                                    )}
                                </div>
                            </form>
                        </>
                    )}
                </main>
            </div>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ModifyEvent);
