import DeleteIcon from '@mui/icons-material/Delete';
import {
    Autocomplete,
    Button,
    CircularProgress,
    IconButton,
    InputAdornment,
    Modal,
    TextField,
    Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Resizer from 'react-image-file-resizer';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import background from '../../assets/images/create-video-bg.png';
import thumbnail_placeholder from '../../assets/media/event_thumbnail_placeholder.jpg';
import { CONVERT_STATUS, UPLOADING_STATUS } from '../../constants';
import {
    CONNECT_PRICE_CHANGE,
    CONVERSION_STATUS,
    CONVERT_VIDEO,
    CREATE_EVENT,
    SET_CAMERA_FACING_MODE,
    SET_IVS_STREAM_CONFIG,
    SET_STREAMING_CLIENT,
    SET_USER_DATA,
    SET_VIDEO_DEVICES,
    SET_VIDEO_SELECT_DEVICE,
    SET_VIDEO_THUMBNAIL,
    SET_VIDEO_TITLE,
    SPINNER_VISIBLE,
    UPLOAD_VIDEO
} from '../../constants/actionTypes';
import fetchData from '../../fetch';
import { getBase64 } from '../../helpers/FileReader';
import { isColorSimilar } from '../../utils';
import { fromUnitAmount } from '../../utils/unitAmountHelper';
import stylesProfile from '../Nav/menu.module.scss';
import UserProfile from '../Nav/UserProfile';
import { EventTypes } from '../Profile/Streaming/Helpers/eventTypes';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import styles from './createVideo.module.scss';
import PermissionsModal from './PermissionsModal';
import Thumbnails from './Thumbnails';
import cognitoService from '../../core/service/cognito.service';

const useStyles = makeStyles(() => ({
    root: {
        height: '100%',
        width: '100%',
        display: 'block',
        background: 'linear-gradient(#291d42, black, black)',
        color: 'white'
    },
    headerWrapper: {
        display: 'block',
        textAlign: 'center'
    },
    closeButton: {
        position: 'absolute !important',
        top: 5,
        right: 5
    },
    contentContainer: {
        display: 'block',
        height: '100%',
        color: 'white',
        padding: 20
        // backgroundImage: `url(../../assets/images/create-video-bg.png)`
        // padding: theme.spacing(2),
        // marginTop: theme.spacing(2),
    },
    uploadContainer: {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 8,
        padding: 8,
        gap: '20%',
        borderRadius: 8,
        color: 'white' // Set text color to white
    },
    textField: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'gray'
        },
        '& .MuiOutlinedInput-input::placeholder': {
            color: 'gray'
        },
        color: 'white',
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white'
        }
    },
    label: {
        '& .MuiInputLabel-root': {
            color: 'gray'
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: 'white'
        }
    },
    textFieldError: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'red !important'
        },
        '& .MuiOutlinedInput-input::placeholder': {
            color: 'red !important'
        },
        color: 'red !important',
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'red !important'
        }
    },
    labelError: {
        '& .MuiInputLabel-root': {
            color: 'red !important'
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: 'red !important'
        }
    },
    switch: {
        '& .MuiSwitch-track': {
            backgroundColor: 'lightgray'
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: 'white'
        },
        '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
            backgroundColor: '#32E7B2'
        }
    },
    datepicker: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white'
        },
        '& .MuiInputLabel-root': {
            color: 'white'
        },
        '& .MuiInputBase-input': {
            color: 'white'
        }
    }
}));

// const streamConfig = IVSBroadcastClient.STANDARD_LANDSCAPE;

// const API_ENDPOINTS = {
//     createStream: `${process.env.REACT_APP_API_URL}/ivs/createStream`,
//     prepareStreamEvent: `${process.env.REACT_APP_API_URL}/ivs/prepare-stream`
// };

const mapStateToProps = (state) => {
    return {
        ...state.liveStreaming,
        user: state.user.currentUser,
        convertVideoJobIds: state.schedule.convertVideoJobIds,
        conversionStatus: state.schedule.conversionStatus,
        company: state.common.company,
        spinner: state.common.spinner,
        upload: state.common.upload,
        connected_account: state.common.connected_account,
        connected_products: state.common.connected_products,
        connected_prices: state.prices.connected_prices
    };
};
const mapDispatchToProps = (dispatch) => ({
    setIvsConfig: (ivsConfig) =>
        dispatch({
            type: SET_IVS_STREAM_CONFIG,
            payload: ivsConfig
        }),
    setStreamingClient: (streamingClient) =>
        dispatch({
            type: SET_STREAMING_CLIENT,
            payload: streamingClient
        }),
    onNewEvent: (payload) => dispatch({ type: CREATE_EVENT, payload }),
    onConvertVideo: (payload) => dispatch({ type: CONVERT_VIDEO, payload }),
    onUploadVideo: (payload) => dispatch({ type: UPLOAD_VIDEO, payload }),
    onSpinner: (payload) => dispatch({ type: SPINNER_VISIBLE, payload }),
    onRequestForConversionStatus: (payload) => dispatch({ type: CONVERSION_STATUS, payload }),
    onSetVideoTitle: (payload) => dispatch({ type: SET_VIDEO_TITLE, payload }),
    onSetVideoThumbnail: (payload) => dispatch({ type: SET_VIDEO_THUMBNAIL, payload }),
    onPriceChange: (payload) => dispatch({ type: CONNECT_PRICE_CHANGE, payload }),
    setCameraFacingMode: (payload) => dispatch({ type: SET_CAMERA_FACING_MODE, payload }),
    setVideoDevices: (payload) => dispatch({ type: SET_VIDEO_DEVICES, payload }),
    setVideoSelectDevice: (payload) => dispatch({ type: SET_VIDEO_SELECT_DEVICE, payload }),
    onSetUserData: (payload) => dispatch({ type: SET_USER_DATA, payload })
});

const CreateVideoPageV2 = (props) => {
    const date = new Date();
    const now = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
    const PRODUCT_FIELDS_PREFIX = 'product.';

    const classes = useStyles();
    const history = useHistory();

    const [videoTitle, setVideoTitle] = useState('');
    const [videoDescription, setVideoDescription] = useState('');
    const [minimumVideoPrice, setMinimumVideoPrice] = useState(0);
    const [videoTitleError, setVideoTitleError] = useState('');
    const [videoDescriptionError, setVideoDescriptionError] = useState('');
    const [videoPriceError, setVideoPriceError] = useState('');
    // const [streamDetails, setStreamDetails] = useState(false);
    const inputRefs = useRef([]);
    const { t } = useTranslation();
    const eventTypes = EventTypes();

    const [imagePreview, setImagePreview] = useState(null);
    const [photo, setPhoto] = useState('');

    const { data: user } = props.user;

    const [eventType, setEventType] = useState('video_event');
    const [streamType, setStreamType] = useState('url_pull');
    const [videoFile, setVideoFile] = useState(null);
    const [trailerFile, setTrailerFile] = useState(null);
    const [videoFileType, setVideoFileType] = useState(null);
    const [trailerFileType, setTrailerFileType] = useState(null);

    const [endDateFieldEnabled, setEndDateFieldEnabled] = useState(false);
    const [videoDuration, setVideoDuration] = useState(null);
    const [eventProducts, setEventProducts] = useState([]);
    const [jobId, setJobId] = useState(uuidv4());
    const [fileUpload, setFileUpload] = useState(false);
    const [fileUploadError, setFileUploadError] = useState('');
    const [trailerFileUpload, setTrailerFileUpload] = useState(false);
    // const [trailerFileUploadError, setTrailerFileUploadError] = useState('');
    const upload = props.upload.find((x) => x.jobId === jobId);
    const fileInputRef = useRef(null);
    const trailerInputRef = useRef(null);
    const thumbnailInputRef = useRef(null);
    // const [subExclusive, setSubExclusive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [premiumModal, setPremiumModal] = useState(false);
    const [titleClassName, setTitleClassName] = useState(`${classes.textField} ${classes.label}`);
    const [descriptionClassName, setDescriptionClassName] = useState(
        `${classes.textField} ${classes.label}`
    );
    const [priceClassName, setPriceClassName] = useState(`${classes.textField} ${classes.label}`);
    const [videoOrientation, setVideoOrientation] = useState('');
    const [showPermissionsModal, setShowPermissionsModal] = useState(false);
    let backgroundColor = '#DBDDE0';
    // const [present] = useIonToast();
    // const presentToast = (message, icon, color, duration) => {
    //     present({
    //         message: message,
    //         duration: duration,
    //         position: 'top',
    //         icon: icon,
    //         color: color
    //     });
    // };

    const {
        register,
        reset,
        handleSubmit,
        setValue,
        watch,
        control,
        formState: { errors, defaultValues, isValid }
    } = useForm({
        defaultValues: {}
    });

    const price = watch('product');
    const videoPrice = price ? Object.values(price)[0] : '';

    const handleFocus = (index) => () => {
        if (index == 0) {
            setTitleClassName(`${classes.textField} ${classes.label}`);
            setVideoTitleError('');
        }
        if (index == 1) {
            setDescriptionClassName(`${classes.textField} ${classes.label}`);
            setVideoDescriptionError('');
        }
        if (index == 3) {
            setPriceClassName(`${classes.textField} ${classes.label}`);
            setVideoPriceError('');
        }

        inputRefs.current[index].style.color = 'white';
    };

    const handleBlur = (index) => () => {
        inputRefs.current[index].style.color = 'gray';
    };

    const registerRef = (ref) => {
        if (ref && !inputRefs.current.includes(ref)) {
            inputRefs.current.push(ref);
        }
    };

    useEffect(() => {
        if (videoFile && videoFile?.name) {
            setVideoFileType(videoFile.name.toLowerCase().split('.').pop());
        }
    }, [videoFile]);

    useEffect(() => {
        if (videoFileType) {
            props.onUploadVideo({ file: videoFile, type: videoFileType, jobId });
        }
    }, [videoFileType]);

    useEffect(() => {
        if (trailerFile && trailerFile?.name) {
            setTrailerFileType(trailerFile.name.toLowerCase().split('.').pop());
        }
    }, [trailerFile]);

    useEffect(() => {
        if (trailerFileType) {
            props.onUploadVideo({ file: trailerFile, type: trailerFileType, jobId, trailer: true });
        }
    }, [trailerFileType]);

    useEffect(() => {
        async function fetchData() {
            if (props.user.data != null) {
                const userData = props.user;
                if (userData.data['custom:log_counter'] === undefined) {
                    cognitoService.setLogNumber(1);
                    userData.data['custom:log_counter'] = parseInt('1');
                    props.onSetUserData(userData);
                    setOpenModal(true);
                } else {
                    const logNumber = parseInt(userData.data['custom:log_counter']);
                    if (logNumber < 10) {
                        cognitoService.setLogNumber(logNumber + 1);
                        userData.data['custom:log_counter'] = logNumber + 1;
                        props.onSetUserData(userData);
                    }
                }
            }
        }

        fetchData();
    }, []);

    useEffect(() => {
        let defaultValues = {};
        let showPriceField = {};
        if (props.connected_prices && props.connected_prices.length > 0) {
            for (const eventProduct of eventProducts) {
                showPriceField = { ...showPriceField, [eventProduct.id]: false };
                defaultValues[eventProduct.id] = props.connected_prices
                    .filter((p) => p.product === eventProduct.id)
                    .map((p) => fromUnitAmount(p.price.unit_amount, p.price.currency))
                    .pop();
            }
        }
        reset({ product: { ...defaultValues } });
    }, [props.connected_prices, eventProducts]);

    useEffect(() => {
        if (props.connected_products && props.connected_products.length > 0) {
            setEventProducts(
                props.connected_products.filter((ca) => ca.metadata['setup-price'] === 'event')
            );
        }
    }, [props.connected_products]);

    const resizeFile = (file) => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                140,
                90,
                'PNG',
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                'blob'
            );
        });
    };

    const handleImageChange = async (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let currentFile = e.target.files[0];
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        const fileExtension = currentFile.name.split('.').pop().toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
            // presentToast(
            //     'Please select a valid image file (jpg, jpeg, png).',
            //     closeCircleSharp,
            //     'danger',
            //     1500
            // );
            return;
        }

        reader.onloadend = () => {
            setImagePreview(reader.result);
        };

        try {
            if (currentFile) {
                const image = await resizeFile(currentFile);
                setPhoto(image);
                reader.readAsDataURL(image);
            }
        } catch (err) {
            console.error('ERROR with thumbnail: ', err);
        }
    };

    const deleteVideo = () => {
        setFileUpload(false);
        setVideoFile(null);
        setVideoDuration(null);
        setVideoFileType(null);
        setImagePreview(null);
    };

    const deleteTrailer = () => {
        setTrailerFileUpload(false);
        setTrailerFile(null);
        setTrailerFileType(null);
    };

    const calculateMinimumPrice = (video) => {
        const size = video.size / (1024 * 1024);
        if (size <= 100) {
            return 2;
        } else if (size <= 500) {
            return 8;
        } else if (size <= 1000) {
            return 15;
        } else {
            return 25;
        }
    };

    const handleVideoUpload = async (event, type) => {
        const allowedVideoExtensions = ['mp4', 'mov', 'avi', 'mkv', 'flv', 'wmv', 'webm'];

        const file = event.target.files[0];
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (!allowedVideoExtensions.includes(fileExtension)) {
            // presentToast('Please upload a video', closeCircleSharp, 'danger', 1500);
            return;
        }
        if (type === 'trailer') {
            // setTrailerFileUploadError('');
            setTrailerFileUpload(true);
            setTrailerFile(event.target.files[0]);
        }
        if (type === 'video') {
            setFileUploadError('');
            setFileUpload(true);
            setVideoFile(event.target.files[0]);
            const minPrice = calculateMinimumPrice(event.target.files[0]);
            // console.log('MIN PRICE: ', { [eventProducts[0]?.id]: minPrice.toString() });
            setValue('product', { [eventProducts[0]?.id]: minPrice.toString() });
            setMinimumVideoPrice(minPrice);
            if (event.target.files[0]) {
                await getVideoDuration(event.target.files[0]);
            }
        }
    };

    const getVideoDuration = async (video) => {
        setVideoDuration(null);
        if (!video?.type?.includes('video/')) {
            setEndDateFieldEnabled(true);
        } else if (endDateFieldEnabled) {
            setEndDateFieldEnabled(false);
        }
        try {
            let _fileReader = new FileReader();
            _fileReader.onload = function (e) {
                let blob = new Blob([e.target.result], {
                        type: video.type
                    }),
                    // eslint-disable-next-line no-undef
                    url = (URL || webkitURL).createObjectURL(blob),
                    videoDom = document.createElement('video');
                videoDom.preload = 'metadata';
                videoDom.addEventListener('loadedmetadata', function () {
                    setVideoDuration(videoDom.duration);
                    const isPortrait = videoDom.videoHeight > videoDom.videoWidth;
                    setVideoOrientation(isPortrait ? 'portrait' : 'landscape');
                    // eslint-disable-next-line no-undef
                    (URL || webkitURL).revokeObjectURL(url);
                });

                videoDom.src = url;
            };
            _fileReader.readAsArrayBuffer(video);
        } catch (e) {
            console.warn({ e });
            setEndDateFieldEnabled(true);
            return false;
        }
    };

    const createEvent = async (data) => {
        if (streamType === 'url_pull' && !fileUpload) {
            setFileUploadError(t('RequiredVideo'));
            scrollToTop();
            return;
        }
        if (videoTitle.trim() === '') {
            setTitleClassName(`${classes.textFieldError} ${classes.labelError}`);
            setVideoTitleError(t('RequiredTitle'));
            scrollToTop();
            return;
        }
        if (videoDescription.trim() === '') {
            setDescriptionClassName(`${classes.textFieldError} ${classes.labelError}`);
            setVideoDescriptionError(t('RequiredDescription'));
            scrollToTop();
            return;
        }
        if (parseFloat(videoPrice) < minimumVideoPrice || videoPrice === '') {
            setPriceClassName(`${classes.textFieldError} ${classes.labelError}`);
            setVideoPriceError(
                t('RequiredPrice', { minimumPrice: `${minimumVideoPrice.toString()}€` })
            );
            return;
        }

        console.info(data);
        const startDate = dayjs(data?.start_date?.$d).valueOf() ?? now;
        let endDate = null;
        if (videoDuration && !isNaN(videoDuration) && startDate) {
            endDate = new Date(new Date(startDate).getTime() + Math.ceil(videoDuration) * 1000);
        }

        if (startDate) {
            if (startDate < now) {
                // presentToast('Please select date in the future', closeCircleSharp, 'danger', 1500);
            } else {
                let _thumbnail = null;
                setLoading(true);
                try {
                    if (photo) {
                        const base64Image = await getBase64(photo);
                        const data = base64Image.split(';base64,')[1];
                        const requestOptions = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({
                                img: {
                                    data,
                                    name: new Date().getTime()
                                }
                            })
                        };
                        const res = await fetchData.Event.UploadThumbnail(requestOptions);

                        _thumbnail = { ..._thumbnail, _ref: res._id };
                    }

                    const _url = upload?.upload_url;
                    // const _eventTitle = data.title;
                    // const _description = data.description;
                    const _eventSlug = videoTitle.toLowerCase().replace(/[^a-z0-9]/gi, '-');
                    const _eType = eventTypes.find((et) => et.value === eventType);
                    const _resource_creation_time = 1.5;
                    let priceId;
                    for (let product of Object.keys(data.product)) {
                        if (data[product] || data.product[product]) {
                            if (defaultValues.product[product] != data.product[product]) {
                                const price = await fetchData.Prices.Create(
                                    product,
                                    props.connected_account.accountId,
                                    data.product[product]
                                );
                                priceId = price.id;
                                props.onPriceChange({ product: product, price });
                            } else {
                                priceId = props.connected_prices
                                    .filter((pp) => pp.product === product)
                                    .map((pp) => pp.price.id)
                                    .pop();
                            }
                        }
                    }

                    const eventData = {
                        title: videoTitle,
                        slug: {
                            type: 'slug',
                            current: `${_eventSlug}`
                        },
                        description: videoDescription,
                        startDate: new Date(startDate),
                        endDate: new Date(endDate),
                        event_type: _eType.value,
                        stream_type: streamType,
                        endpoint: _url,
                        trailer: upload?.trailer_url,
                        streamStatus: 'upcoming',
                        processingStatus: _url ? CONVERT_STATUS : UPLOADING_STATUS,
                        user_id: props.user.data.sub,
                        tags: [_eType.title],
                        thumbnail: _thumbnail,
                        correlation_id: jobId,
                        resource_creation_time: _resource_creation_time,
                        pay_per_view_price: priceId,
                        subscriber_exclusive: true,
                        sports_team: {
                            _ref: process.env.REACT_APP_COMPANY_ID,
                            _type: 'reference'
                        },
                        convert_start_dates: [],
                        convert_end_dates: [],
                        convert_mode: videoOrientation
                    };

                    props.onNewEvent(eventData);
                    // navigator.clipboard.writeText(`${window.location.origin}/events/${videoTitle}`);
                    // presentToast(
                    //     'The link to the video was copied to your clipboard.',
                    //     checkmarkCircleSharp,
                    //     'success',
                    //     3000
                    // );
                    //props.onRequestForConversionStatus(_eventSlug);
                    props.setScheduleEventOpen
                        ? props.setScheduleEventOpen(false)
                        : history.push(`create-video/success/${videoTitle}`);
                } catch (e) {
                    console.error(e.message);
                } finally {
                    setLoading(false);
                }
            }
        }
    };

    const handleThumbnailUploadClick = (e) => {
        e.stopPropagation();
        thumbnailInputRef.current.click();
    };

    const contentRef = useRef(null);
    const scrollToTop = () => {
        if (contentRef.current) {
            contentRef.current.scrollToPoint(0, 0, 500);
        }
    };

    if (props.company && props.company.primaryColor) {
        if (user && user.data) {
            if (!user.data.picture) {
                const isSimilar = isColorSimilar(props.company.primaryColor, '#DBDDE0', 50);
                backgroundColor = isSimilar ? '#444444' : '#DBDDE0';
            }
        }
    }

    return (
        <div>
            <div ref={contentRef} className={styles.customBackground}>
                <div>
                    <div className={classes.headerWrapper}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                                variant="h6"
                                textAlign={'center'}
                                sx={{ color: 'white ', flex: 1 }}>
                                {t('CreateVideo')}
                            </Typography>
                            {/* <IconButton
                                sx={{ color: 'white', marginLeft: '-40px' }}
                                onClick={handleCloseClick}>
                                <CloseIcon />
                            </IconButton> */}
                            <div style={{ marginLeft: '-46px', paddingRight: '46px' }}>
                                <UserProfile
                                    backgroundColor={backgroundColor}
                                    className={stylesProfile.userProfile}
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        className={styles.paddingBottom}
                        style={{ backgroundImage: `url(${background})` }}>
                        <div className={classes.contentContainer}>
                            <form
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                                onSubmit={handleSubmit(createEvent)}>
                                <div>
                                    <div className={classes.uploadContainer}>
                                        <div className={styles.fileUpload}>
                                            {imagePreview ||
                                            (videoFile && upload?.upload_progress === 100) ? (
                                                <div
                                                    className={styles.uploadLabel}
                                                    style={{ position: 'relative' }}>
                                                    <img
                                                        style={{
                                                            height: 'inherit',
                                                            width: '-webkit-fill-available',
                                                            borderRadius: '21px'
                                                        }}
                                                        src={imagePreview || thumbnail_placeholder}
                                                    />
                                                    <IconButton
                                                        onClick={() => deleteVideo()}
                                                        sx={{
                                                            background: 'lightgray',
                                                            padding: '3px',
                                                            position: 'absolute',
                                                            top: '-10px',
                                                            right: '-10px',
                                                            '&:active, &:focus, &:hover': {
                                                                background: 'lightgray',
                                                                padding: '3px',
                                                                position: 'absolute',
                                                                top: '-10px',
                                                                right: '-10px'
                                                            }
                                                        }}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            ) : (
                                                <>
                                                    {!upload?.upload_show && (
                                                        <input
                                                            type="file"
                                                            id="upload"
                                                            onChange={(e) =>
                                                                handleVideoUpload(e, 'video')
                                                            }
                                                            style={{ display: 'none' }}
                                                            ref={fileInputRef}
                                                        />
                                                    )}
                                                    <label
                                                        htmlFor="upload"
                                                        style={{
                                                            border:
                                                                fileUploadError.trim() !== ''
                                                                    ? '1px solid red'
                                                                    : ''
                                                        }}
                                                        className={styles.uploadLabel}>
                                                        {upload?.upload_show && videoFile ? (
                                                            <CircularProgressWithLabel
                                                                value={upload.upload_progress}
                                                                size={160}
                                                                thickness={2}
                                                            />
                                                        ) : (
                                                            <div>
                                                                <div>Upload video</div>
                                                                <Typography
                                                                    className={styles.secondLine}>
                                                                    {t('MaxFileSize')}
                                                                </Typography>
                                                            </div>
                                                        )}
                                                    </label>
                                                </>
                                            )}
                                            {fileUploadError !== '' && (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        color: 'red',
                                                        marginTop: '4%'
                                                    }}>
                                                    {fileUploadError}
                                                </div>
                                            )}
                                        </div>
                                        <div className={styles.fileUpload}>
                                            {trailerFile && upload?.trailer_progress === 100 ? (
                                                <div
                                                    className={styles.uploadLabel}
                                                    style={{ position: 'relative' }}>
                                                    <img
                                                        style={{
                                                            height: 'inherit',
                                                            width: '-webkit-fill-available',
                                                            borderRadius: '21px'
                                                        }}
                                                        src={thumbnail_placeholder}
                                                    />
                                                    <IconButton
                                                        onClick={() => deleteTrailer()}
                                                        sx={{
                                                            background: 'lightgray',
                                                            padding: '3px',
                                                            position: 'absolute',
                                                            top: '-10px',
                                                            right: '-10px',
                                                            '&:active, &:focus, &:hover': {
                                                                background: 'lightgray',
                                                                padding: '3px',
                                                                position: 'absolute',
                                                                top: '-10px',
                                                                right: '-10px'
                                                            }
                                                        }}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            ) : (
                                                <>
                                                    {!upload?.trailer_show && (
                                                        <input
                                                            type="file"
                                                            id="uploadTrailer"
                                                            onChange={(e) =>
                                                                handleVideoUpload(e, 'trailer')
                                                            }
                                                            style={{ display: 'none' }}
                                                            ref={trailerInputRef}
                                                        />
                                                    )}
                                                    <label
                                                        htmlFor="uploadTrailer"
                                                        className={styles.uploadLabel}>
                                                        {upload?.trailer_show && trailerFile ? (
                                                            <CircularProgressWithLabel
                                                                value={upload.trailer_progress}
                                                                size={160}
                                                                thickness={2}
                                                            />
                                                        ) : (
                                                            <div>
                                                                <div>Upload trailer</div>
                                                                <Typography
                                                                    className={styles.secondLine}>
                                                                    {t('MaxFileSize')}
                                                                </Typography>
                                                            </div>
                                                        )}
                                                    </label>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className={classes.videoDetailsContainer}>
                                        <Typography
                                            variant="subtitle1"
                                            style={{ marginBottom: '1rem' }}>
                                            {t('VideoDetails')}
                                        </Typography>
                                        <TextField
                                            {...register('title')}
                                            name="title"
                                            label={t('VideoTitle')}
                                            fullWidth
                                            InputLabelProps={{
                                                ref: registerRef
                                            }}
                                            InputProps={{
                                                style: {
                                                    color: 'white'
                                                }
                                            }}
                                            value={videoTitle}
                                            className={titleClassName}
                                            sx={{ marginBottom: '1rem' }}
                                            onFocus={handleFocus(0)}
                                            onBlur={handleBlur(0)}
                                            onChange={(e) => {
                                                setVideoTitle(e.target.value);
                                            }}
                                        />
                                        {videoTitleError !== '' && (
                                            <div style={{ color: 'red', marginBottom: '4%' }}>
                                                {videoTitleError}
                                            </div>
                                        )}
                                        <TextField
                                            {...register('description')}
                                            name="description"
                                            label={t('VideoDescription')}
                                            rows={4}
                                            maxRows={4}
                                            fullWidth
                                            value={videoDescription}
                                            InputLabelProps={{
                                                ref: registerRef
                                            }}
                                            InputProps={{
                                                style: {
                                                    color: 'white'
                                                }
                                            }}
                                            className={descriptionClassName}
                                            onFocus={handleFocus(1)}
                                            onBlur={handleBlur(1)}
                                            onChange={(e) => setVideoDescription(e.target.value)}
                                        />
                                        {videoDescriptionError !== '' && (
                                            <div style={{ color: 'red', marginTop: '4%' }}>
                                                {videoDescriptionError}
                                            </div>
                                        )}
                                    </div>
                                    <div style={{ margin: '1rem 0' }}>
                                        <Typography sx={{ marginBottom: '1rem' }}>
                                            {t('VideoThumbnails')}
                                        </Typography>
                                        <Thumbnails
                                            imagePreview={imagePreview}
                                            fileInputRef={thumbnailInputRef}
                                            handleFileUploadClick={handleThumbnailUploadClick}
                                            handleImageChange={handleImageChange}
                                            setImagePreview={setImagePreview}
                                        />
                                    </div>
                                    <div style={{ position: 'relative' }}>
                                        <div>
                                            <Typography style={{ marginBottom: '1rem' }}>
                                                {t('pay-per-viewPrice')}{' '}
                                                <span style={{ color: 'gray' }}>
                                                    {t('Optional')}
                                                </span>
                                            </Typography>
                                            <Autocomplete
                                                disablePortal
                                                freeSolo
                                                id="combo-box-demo"
                                                options={props.connected_prices
                                                    .filter(
                                                        (pp) => pp.product === eventProducts[0]?.id
                                                    )
                                                    .map((pp) =>
                                                        fromUnitAmount(
                                                            pp.price.unit_amount,
                                                            pp.price.currency
                                                        )
                                                    )}
                                                getOptionLabel={(option) =>
                                                    typeof option === 'string' ||
                                                    option instanceof String
                                                        ? option
                                                        : option.toString()
                                                }
                                                // value={minimumVideoPrice ?? ''}
                                                key={`price${eventProducts[0]?.id}`}
                                                renderInput={(params) => (
                                                    <TextField
                                                        type="number"
                                                        {...params}
                                                        label={t('PriceForContent')}
                                                        fullWidth
                                                        InputLabelProps={{
                                                            ref: registerRef
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            startAdornment: (
                                                                <InputAdornment
                                                                    sx={{ color: 'white' }}
                                                                    position="start">
                                                                    <Typography color={'white'}>
                                                                        €
                                                                    </Typography>
                                                                </InputAdornment>
                                                            ),
                                                            style: {
                                                                color: 'white'
                                                            }
                                                        }}
                                                        className={priceClassName}
                                                        sx={{ marginBottom: '1rem' }}
                                                        onFocus={handleFocus(3)}
                                                        onBlur={handleBlur(3)}
                                                        {...register(
                                                            `${PRODUCT_FIELDS_PREFIX}${eventProducts[0]?.id}`
                                                        )}
                                                        // value={2}
                                                    />
                                                )}
                                            />
                                            {videoPriceError !== '' && (
                                                <div
                                                    style={{
                                                        color: 'red',
                                                        marginBottom: '4%'
                                                    }}>
                                                    {videoPriceError}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {loading ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                            <CircularProgress
                                                sx={{
                                                    width: '60px !important',
                                                    height: '60px !important'
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <Button
                                            type="submit"
                                            // onClick={handleSubmit(createEvent)}
                                            className={styles.button}
                                            sx={{
                                                '&:active, &:focus, &:hover': {
                                                    backgroundColor:
                                                        videoTitle.trim() === '' ||
                                                        videoDescription.trim() === '' ||
                                                        parseFloat(videoPrice) <
                                                            minimumVideoPrice ||
                                                        videoPrice === '' ||
                                                        !fileUpload
                                                            ? '#333'
                                                            : '#32E7B2',
                                                    color:
                                                        videoTitle.trim() === '' ||
                                                        videoDescription.trim() === '' ||
                                                        parseFloat(videoPrice) <
                                                            minimumVideoPrice ||
                                                        videoPrice === '' ||
                                                        !fileUpload
                                                            ? '#666'
                                                            : 'black'
                                                },
                                                marginTop: '3%',
                                                backgroundColor:
                                                    videoTitle.trim() === '' ||
                                                    videoDescription.trim() === '' ||
                                                    parseFloat(videoPrice) < minimumVideoPrice ||
                                                    videoPrice === '' ||
                                                    !fileUpload
                                                        ? '#333'
                                                        : '#32E7B2',
                                                color:
                                                    videoTitle.trim() === '' ||
                                                    videoDescription.trim() === '' ||
                                                    parseFloat(videoPrice) < minimumVideoPrice ||
                                                    videoPrice === '' ||
                                                    !fileUpload
                                                        ? '#666'
                                                        : 'black'
                                            }}>
                                            {t('Publish')}
                                        </Button>
                                    )}
                                </div>
                            </form>
                            <PermissionsModal
                                showPermissionsModal={showPermissionsModal}
                                setShowPermissionsModal={setShowPermissionsModal}
                            />
                            <Modal open={openModal}>
                                <div className={isMobile ? styles.modalMobile : styles.modal}>
                                    <Typography
                                        marginTop={isMobile ? '3%' : '-1%'}
                                        fontSize={'17px'}
                                        textAlign={'center'}>
                                        {t('OnboardingModal1')}
                                    </Typography>
                                    <Typography fontSize={'17px'} textAlign={'center'}>
                                        {t('OnboardingModal2')}
                                    </Typography>
                                    <div className={styles.flexContainer}>
                                        {!loading ? (
                                            <>
                                                <Button
                                                    className={
                                                        isMobile
                                                            ? styles.confirmButtonMobile
                                                            : styles.confirmButton
                                                    }
                                                    onClick={() => {
                                                        setOpenModal(false);
                                                        history.push({
                                                            pathname: '/profile/account',
                                                            state: { flag: true }
                                                        });
                                                    }}>
                                                    {t('Onboard')}
                                                </Button>
                                                <Button
                                                    className={
                                                        isMobile
                                                            ? styles.cancelButtonMobile
                                                            : styles.cancelButton
                                                    }
                                                    onClick={() => {
                                                        setOpenModal(false);
                                                    }}>
                                                    {t('Close')}
                                                </Button>
                                            </>
                                        ) : (
                                            <div
                                                style={{
                                                    margin: '0 auto'
                                                }}>
                                                <CircularProgress />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Modal>
                            <Modal open={premiumModal}>
                                <div
                                    className={
                                        isMobile ? styles.premiumModalMobile : styles.premiumModal
                                    }
                                    style={{ height: '20vh !important' }}>
                                    <Typography
                                        marginTop={isMobile ? '3%' : '-1%'}
                                        fontSize={'18px'}
                                        textAlign={'center'}>
                                        {t('PremiumFeature')}
                                    </Typography>
                                    <div className={styles.flexContainer}>
                                        <Button
                                            className={
                                                isMobile
                                                    ? styles.confirmButtonMobile
                                                    : styles.confirmButton
                                            }
                                            onClick={() => {
                                                setPremiumModal(false);
                                                history.push('/profile/manage-subscriptions');
                                            }}>
                                            {t('BecomePremium')}
                                        </Button>
                                        <Button
                                            className={
                                                isMobile
                                                    ? styles.cancelButtonMobile
                                                    : styles.cancelButton
                                            }
                                            onClick={() => {
                                                setPremiumModal(false);
                                            }}>
                                            {t('Close')}
                                        </Button>
                                    </div>
                                </div>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateVideoPageV2);
