import React from 'react';
import styles from '../Customize/customize.module.scss';

const SuccessPayment = (props) => {
    return (
        <div>
            <div className={styles.profileWrapper} style={{ minHeight: '100%' }}>
                THANK YOU FOR SUBSCRIPTION!
            </div>
        </div>
    );
};

export default SuccessPayment;
