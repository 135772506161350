import React, { useEffect } from 'react';
import { Box, Container, Typography } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import ProductButton from './ProductButton';
import { useTranslation } from 'react-i18next';
import fetch from '../../fetch';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { fromUnitAmount } from '../../utils/unitAmountHelper';
import { CONNECT_PRICE_CHANGE } from '../../constants/actionTypes';
import { getSymbolForCurrency } from '../../utils';

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser,
        company: state.common.company,
        connected_account: state.common.connected_account,
        connected_products: state.common.connected_products,
        connected_prices: state.prices.connected_prices
    };
};

const mapDispatchToProps = (dispatch) => ({
    onPriceChange: (payload) => dispatch({ type: CONNECT_PRICE_CHANGE, payload })
});

const GetPremium = (props) => {
    const { event, user, connected_account, connected_products, connected_prices } = props;
    const { t } = useTranslation();

    useEffect(() => {
        async function fetchPrice() {
            const price = await fetch.Prices.Get(
                event.pay_per_view_price,
                connected_account.accountId
            );
            props.onPriceChange({ product: price.product, price });
        }

        if (event.pay_per_view_price && connected_account && connected_prices.length > 0) {
            if (!connected_prices.some((p) => p.price.id === event.pay_per_view_price)) {
                fetchPrice();
            }
        }
    }, [connected_account, connected_prices]);

    const handleProductClick = async (productId, priceId) => {
        const line_item = [
            {
                price: priceId,
                quantity: 1
            }
        ];
        const metadata = {
            companyId: connected_account.companyId,
            eventId: event.id,
            priceId,
            productId,
            customer_email: user.data.email
        };
        console.log('metadata:', metadata);
        try {
            const response = await fetch.Checkout.Create(
                line_item,
                window.location.href,
                user.data.email,
                connected_account.accountId,
                metadata
            );
            window.location.replace(response.url);
        } catch (e) {
            console.error(e.message);
        }
    };

    console.log('event.pay_per_view_price', event.pay_per_view_price);

    return (
        <Container maxWidth="xs">
            {event.pay_per_view_price && (
                <Box textAlign="center" my={1} paddingY={3}>
                    <Typography
                        variant="h4"
                        sx={{
                            fontFamily: 'InstrumentSans-Bold',
                            fontSize: '20px'
                        }}>
                        {t('GetPremium')}
                    </Typography>
                </Box>
            )}
            {event.pay_per_view_price &&
                connected_prices
                    .filter((p) =>
                        connected_products.some(
                            (pp) => pp.id === p.product && pp.metadata['setup-price'] === 'event'
                        )
                    )
                    .map((p) => (
                        <Box
                            key={p.price.id}
                            onClick={() => handleProductClick(p.price.product, p.price.id)}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            bgcolor="black"
                            borderRadius="8px"
                            height="56px"
                            p={2}
                            mb={1}>
                            <div style={{ display: 'flex', width: 'auto' }}>
                                <LockIcon sx={{ color: 'white !important' }} />
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: 'white !important',
                                        fontSize: '16px !important',
                                        paddingLeft: '14px'
                                    }}>
                                    {t('UnlockContent')}
                                </Typography>
                            </div>
                            <Typography
                                variant="body1"
                                sx={{ color: 'white !important', fontSize: '16px !important' }}>
                                {fromUnitAmount(p.price.unit_amount, p.price.currency)}{' '}
                                {getSymbolForCurrency(p.price.currency)}
                            </Typography>
                        </Box>
                    ))}

            <Box textAlign="center" my={4}>
                <Typography
                    variant="h3"
                    style={{
                        fontFamily: 'InstrumentSans-Bold',
                        fontSize: '15px',
                        lineHeight: '20px',
                        marginBottom: '4px'
                    }}>
                    {t('GetMoreFromThisCreator')}
                </Typography>
                <Typography
                    variant="subtitle1"
                    style={{
                        fontFamily: 'InstrumentSans',
                        fontSize: '15px',
                        fontStyle: 'normal',
                        lineHeight: '20px',
                        color: '#888'
                    }}>
                    {t('BuyDaypassOrMonthlySubscription')}
                </Typography>
            </Box>
            <Box my={2}>
                {connected_prices
                    .filter((p) =>
                        connected_products.some(
                            (pp) => pp.id === p.product && pp.metadata['setup-price'] === 'account'
                        )
                    )
                    .map((price) => (
                        <ProductButton
                            key={price.price.product}
                            price={`${fromUnitAmount(
                                price.price.unit_amount,
                                price.price.currency
                            )} ${getSymbolForCurrency(price.price.currency)}`}
                            duration={t(price.price.metadata['interval'])}
                            buttonTitle={t(price.price.metadata['sub-type'])}
                            //buttonTitle={`${price.price.product}_${price.price.id}`}
                            handleClick={() =>
                                handleProductClick(price.price.product, price.price.id)
                            }
                        />
                    ))}
            </Box>
        </Container>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(GetPremium);
