import { ThemeProvider } from '@mui/material/styles';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    CONNECTED_ACCOUNT_LOAD_FINISHED,
    CONNECTED_PRICES_LOAD_FINISHED,
    CONNECTED_PRODUCTS_LOAD_FINISHED,
    GET_BLOCKED_USERS,
    SET_COMPANY_DATA,
    SET_COMPANY_STATS,
    SET_USER_DATA,
    SPINNER_VISIBLE
} from '../constants/actionTypes';
import cognitoService from '../core/service/cognito.service';
import { orientationService } from '../core/service/orientation.service';
import { default as api, default as fetch } from '../fetch';
import sanity from '../sanity.js';
import '../styles/themes/theme-light.scss';
import theme from '../theme';
import {
    changeDynamicFavicon,
    transformToSanityUrl,
    updateMetaTags,
    updateMetaTagsUrl
} from '../utils.js';
import ErrorToast from './ErrorToast/ErrorToast';
import Routes from './Routes';
import Spinner from './Spinner';
import { HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser,
        refetchUser: state.user.refetchUser,
        visibleSpinner: state.common.spinner,
        company: state.common.company,
        connected_account: state.common.connected_account,
        isAdmin: state.user.currentUser.groups && state.user.currentUser.groups.includes('Admin')
    };
};

const mapDispatchToProps = (dispatch) => ({
    onLoad: (payload) => dispatch({ type: SET_USER_DATA, payload }),
    onSpinner: (payload) => dispatch({ type: SPINNER_VISIBLE, payload }),
    setCompanyData: (payload) => dispatch({ type: SET_COMPANY_DATA, payload }),
    setCompanyStats: (payload) => dispatch({ type: SET_COMPANY_STATS, payload }),
    onLoadBlockedUsers: (payload) => dispatch({ type: GET_BLOCKED_USERS, payload }),
    setConnectedAccount: (payload) => dispatch({ type: CONNECTED_ACCOUNT_LOAD_FINISHED, payload }),
    setConnectedProducts: (payload) =>
        dispatch({ type: CONNECTED_PRODUCTS_LOAD_FINISHED, payload }),
    setConnectedPrices: (payload) => dispatch({ type: CONNECTED_PRICES_LOAD_FINISHED, payload })
});

function App(props) {
    const {
        company,
        user,
        onLoad,
        refetchUser,
        onSpinner,
        visibleSpinner,
        onLoadBlockedUsers,
        setCompanyData,
        setConnectedAccount,
        setConnectedProducts,
        setConnectedPrices,
        setCompanyStats
    } = props;

    // const [present] = useIonToast();
    const { t } = useTranslation();
    // const presentToast = (message) => {
    //     present({
    //         message: message,
    //         duration: 1500,
    //         position: 'top',
    //         icon: closeCircleSharp,
    //         color: 'danger'
    //     });
    // };

    useEffect(() => {
        async function fetchData() {
            try {
                onSpinner(true);

                setCompanyData(sanity.Companies.GetCompanyById(process.env.REACT_APP_COMPANY_ID));

                const user = await cognitoService.getUserData();
                // if (!user) return;
                const account = await api.StripeAccount.getAccountByCompany(
                    process.env.REACT_APP_COMPANY_ID
                );
                if (user) {
                    onLoad({ ...user });
                    onLoadBlockedUsers(user.data.sub);
                    user.groups?.includes('Admin') &&
                        setCompanyStats(
                            fetch.Company.GetCompanyStreamingStats(process.env.REACT_APP_COMPANY_ID)
                        );
                }
                if (account) {
                    setConnectedAccount(account);
                    if (account.isOnboarded) {
                        setConnectedProducts(await api.Products.List(account.accountId));
                        setConnectedPrices(await api.Prices.Latest(account.accountId));
                    }
                    let subscriptionStatus;
                    if (user) {
                        subscriptionStatus = await api.StripeAccount.getSubscriptionStatus(
                            user.data.email,
                            process.env.REACT_APP_COMPANY_ID,
                            user.groups?.includes('Admin') ? undefined : account?.accountId
                        );
                    }
                    onLoad({ ...user, ...{ premium_services: subscriptionStatus } });
                }
                onSpinner(false);
            } catch (e) {
                // toast.error(e.message, { duration: 1500, position: 'top-center' });
                // presentToast(e.message);
            } finally {
                onLoad({ loaded: true });
                onSpinner(false);
            }
        }

        fetchData();
        return () => {
            orientationService.removeListeners();
        };
    }, [refetchUser]);

    useEffect(() => {
        if (company) {
            setCompanyBranding();
            const image = sanity.urlFor(company.photo.asset);
            const transformedImage = transformToSanityUrl(image.options);
            // updateMetaTags({
            //     title: company.name,
            //     description: t('AppDescription', { appname: `${company.name}` })
            // });
            // updateMetaTagsUrl(window.location.href);
            changeDynamicFavicon(transformedImage);
        }
    }, [company]);

    function setCompanyBranding() {
        document.title = company.name;
        const root = document.documentElement;

        const variables = {
            '--primary-color': company.primaryColor,
            '--background-company-color': company.backgroundCompanyColor,
            '--primary-color-lighter': company.secondaryColor,
            '--primary-color-darker': company.primaryColorDark,
            '--primary-color-opacity': company.secondaryColorDark
        };

        for (const [variable, value] of Object.entries(variables)) {
            root?.style.setProperty(variable, value);
        }
    }

    return (
        <div>
            <ThemeProvider theme={theme}>
                <HelmetProvider>
                    {/* <Spinner visible={visibleSpinner} /> */}
                    <Toaster />
                    <ErrorToast />
                    <Routes />
                </HelmetProvider>
            </ThemeProvider>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
