import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import colors from '../../utils/colors';
import styles from './createVideo.module.scss';
import { isMobile } from 'react-device-detect';

const SetPrice = (props) => {
    const { t } = useTranslation();

    const handlePriceChange = (e) => {
        const value = e.target.value;

        // Allow input of both comma and dot
        if (/^\d*([.,]?\d*)$/.test(value)) {
            props.setPrice(value);
            props.setPriceError(''); // Clear error on valid input
        }
    };

    return (
        <>
            <Typography fontSize={isMobile ? '32px' : '36px'} fontFamily={'InstrumentSans-Bold'}>
                {t('SetPrice')}
            </Typography>
            <Typography fontSize={isMobile ? '17px' : '20px'}>{t('PriceExplanation')}</Typography>
            <Box className={styles.uploadContent}>
                <Box className={styles.priceContent}>
                    <Typography fontSize={isMobile ? '14px' : '16px'}>
                        {t('SetYourPrice')}
                    </Typography>
                    <TextField
                        type="text"
                        value={props.price}
                        onChange={handlePriceChange}
                        onFocus={() => props.setPriceError('')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Typography
                                        fontSize={isMobile ? '14px' : '16px'}
                                        color={'#6C6C6C'}>
                                        €
                                    </Typography>
                                </InputAdornment>
                            )
                        }}
                        sx={{
                            backgroundColor: colors.primaryLightGray,
                            borderRadius: '16px',
                            border: props.priceError ? '1px solid red' : '',
                            '& .MuiInputBase-input::placeholder': {
                                color: 'black',
                                opacity: 1,
                                fontSize: isMobile ? '14px' : '16px'
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    border: 'none'
                                }
                            }
                        }}
                        fullWidth
                    />
                    {/* {props.priceError && <Typography color={'red'}>{props.priceError}</Typography>} */}
                    <Typography
                        fontSize={isMobile ? '14px' : '16px'}
                        sx={{ marginTop: '1rem' }}
                        color={'#6C6C6C'}
                        textAlign={'center'}>
                        {t('MinimumPrice', { minimumPrice: props.minimumPrice })}
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

export default SetPrice;
