import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import NavMobile from '../Nav/NavMobile';
import NavDesktop from '../Nav/NavDesktop';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useTranslation } from 'react-i18next';
import styles from './editVideo.module.scss';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import sanity from '../../sanity';
import colors from '../../utils/colors';
import EventThumbnail from './EventThumbnail';
import { getSymbolForCurrency } from '../../utils';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Player from '../Player/Player';
import { v4 as uuidv4 } from 'uuid';

const mapStateToProps = (state) => {
    return {
        company: state.common.company
    };
};

// const mapDispatchToProps = (dispatch) => ({
//     onLoad: (payload) => dispatch({ type: EVENT_LIST_PAGE_LOADED, payload }),
//     onPriceChange: (payload) => dispatch({ type: CONNECT_PRICE_CHANGE, payload })
// });

const EditVideo = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { id } = useParams();
    const [event, setEvent] = useState();
    const [loading, setLoading] = useState(false);
    const [thumbnail, setThumbnail] = useState(null);
    const uniqueHash = useMemo(() => uuidv4(), []);
    // const [viewLandscape, setViewLandscape] = useState(false);
    const [isPortrait, setIsPortrait] = useState(false);

    useEffect(() => {
        const fetchEvent = async () => {
            try {
                setLoading(true);
                const event = await sanity.Events.GetEventById(id);
                console.log('eventot:', event);
                setEvent(event);
            } catch (error) {
                console.log('ERROR:', error);
            } finally {
                setLoading(false);
            }
        };
        if (id) {
            fetchEvent();
        }
    }, []);

    useEffect(() => {
        const fetchThumbnail = async () => {
            if (event) {
                const thumbnail = await event.getThumbnail(1200, 800);
                setThumbnail(thumbnail);
                //  if (screen && screen.orientation) {
                //      setViewLandscape(screen.orientation.type.includes('landscape'));
                //  }
            }
        };
        fetchThumbnail();
    }, [event]);

    return (
        <Box>
            {isMobile ? <NavMobile /> : <NavDesktop />}

            <Box sx={{ padding: isMobile ? '1rem 1rem 0 1rem' : '1rem 3rem 0rem' }}>
                <Box className={styles.main}>
                    {loading ? (
                        <Box className={styles.flexProgress}>
                            <CircularProgress sx={{ color: colors.primary }} />
                        </Box>
                    ) : (
                        <>
                            {event && (
                                <>
                                    {!isMobile && (
                                        <Box className={styles.buttons}>
                                            <Button
                                                onClick={() => history.goBack()}
                                                sx={{
                                                    '&:active, &:focus, &:hover': {
                                                        backgroundColor: '#252525',
                                                        color: 'white'
                                                    }
                                                }}>
                                                <KeyboardBackspaceIcon />
                                                {t('Back')}
                                            </Button>
                                            <Button
                                                sx={{
                                                    '&:active, &:focus, &:hover': {
                                                        backgroundColor: '#252525',
                                                        color: 'white'
                                                    }
                                                }}>
                                                {t('Save')}
                                            </Button>
                                        </Box>
                                    )}
                                    <Box className={styles.content}>
                                        <Typography
                                            sx={{ paddingTop: isMobile ? '2rem' : '' }}
                                            textAlign={'center'}
                                            fontSize={'36px'}>
                                            {event.title}
                                        </Typography>
                                        <Box className={styles.buyButton}>
                                            <Button
                                                sx={{
                                                    '&:active, &:focus, &:hover': {
                                                        backgroundColor: '#3f3f3f',
                                                        color: 'white'
                                                    }
                                                }}>
                                                {t('Buy')}{' '}
                                                {getSymbolForCurrency(
                                                    event.pay_per_view_price_currency
                                                )}
                                                {event.pay_per_view_price_amount}
                                            </Button>
                                        </Box>
                                        <Typography
                                            sx={{ opacity: 0.6, margin: '1rem 0' }}
                                            fontSize={'12px'}
                                            textAlign={'center'}>
                                            {t('MinimumPrice', { minimumPrice: 2.99 })}
                                        </Typography>
                                        {!isMobile && (
                                            <Typography sx={{ opacity: 0.6, margin: '2rem 0' }}>
                                                {event.description}
                                            </Typography>
                                        )}
                                        <Box className={styles.thumbnailAndTrailer}>
                                            {event.trailerUrl && (
                                                <Box
                                                    className={styles.box}
                                                    sx={{ marginBottom: isMobile ? '1rem' : '' }}>
                                                    <Player
                                                        uniqueHash={uniqueHash}
                                                        streamSource={event.trailerUrl}
                                                        // pastWidgets={true}
                                                        // videoTimeCallback={videoTimeCallback}
                                                        // viewLandscape={viewLandscape}
                                                        // onPlayerReady={onPlayerReady}
                                                        muted={false}
                                                        sound={true}
                                                        autoplay={false}
                                                        // isLive={liveLabel}
                                                        event={event}
                                                        // displaySeconds={sec}
                                                        // toggled={false}
                                                        // setTechnicalError={props.setTechnicalError}
                                                        setIsPortrait={setIsPortrait}
                                                        // showTrailer={
                                                        //     !canSeeEvent(props?.user, props?.event) ||
                                                        //     props.showPremiumContent
                                                        // }
                                                        // setShowPremiumContent={
                                                        //     props.setShowPremiumContent
                                                        // }
                                                        // setTrailerEnded={setTrailerEnded}
                                                    />
                                                    <Box className={styles.boxText}>
                                                        <Typography fontSize={'14px'}>
                                                            {t('Thumbnail')}
                                                        </Typography>
                                                        <Button
                                                            sx={{
                                                                '&:active, &:focus, &:hover': {
                                                                    backgroundColor: '#505050',
                                                                    color: 'white'
                                                                }
                                                            }}>
                                                            {t('UploadNew')}
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            )}
                                            <Box className={styles.box}>
                                                <img
                                                    style={{ width: '100%', borderRadius: '9px' }}
                                                    src={thumbnail}
                                                />
                                                <Box className={styles.boxText}>
                                                    <Typography fontSize={'14px'}>
                                                        {t('Thumbnail')}
                                                    </Typography>
                                                    <Button
                                                        sx={{
                                                            '&:active, &:focus, &:hover': {
                                                                backgroundColor: '#505050',
                                                                color: 'white'
                                                            }
                                                        }}>
                                                        {t('UploadNew')}
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                        {isMobile && (
                                            <Typography
                                                textAlign={'center'}
                                                sx={{ opacity: 0.6, margin: '2rem 0' }}>
                                                {event.description}
                                            </Typography>
                                        )}
                                    </Box>
                                </>
                            )}
                            <Box className={styles.creator}>
                                <Typography color={'white'}>{t('Creator')}</Typography>
                                <Button
                                    sx={{
                                        '&:active, &:focus, &:hover': {
                                            backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                            color: 'white'
                                        }
                                    }}>
                                    {props?.company?.name}
                                </Button>
                            </Box>
                            <Box className={styles.saveButton}>
                                <Button
                                    sx={{
                                        '&:active, &:focus, &:hover': {
                                            backgroundColor: '#252525',
                                            color: 'white'
                                        }
                                    }}>
                                    {t('Save')}
                                </Button>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default connect(mapStateToProps, null)(EditVideo);
