const colors = {
    primary: '#04C900',
    secondary: '#FFC900',
    primaryLightGray: '#F2F2F2;',
    secondaryLightGray: '#EBF0EC',
    secondaryBlack: '#1A1A1A',
    primaryDarkGray: '#252525',
    secondaryDarkGray: '#3F3F3F'
};

export default colors;
