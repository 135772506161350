import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    EVENT_PAGE_LOADED,
    EVENT_PAGE_UNLOADED,
    PEOPLE_WATCHING,
    SET_ACTIVE_WIDGET,
    SET_WIDGET_RESULT,
    WATCHING_NOW
} from '../../constants/actionTypes';
import sanity from '../../sanity';
import { canSeeEvent, transformToSanityUrl, updateMetaTags } from '../../utils';
import Chat from '../Chat/Chat';
import Nav from '../Nav/TopMenu';
import Widget from '../Widget/Widget';
import EventInfo from './EventInfo';
import styles from './eventList.module.scss';
import { Helmet } from 'react-helmet-async';

const mapStateToProps = (state) => {
    return {
        currentEvent: state.event.currentEvent,
        currentlyWatching: state.event.currentlyWatching,
        currentWidget: state.widget.currentWidget,
        roomId: state.event.roomId,
        pastWidgets: state.widget.pastWidgets,
        allWidgets: state.widget.allWidgets,
        user: state.user.currentUser,
        company: state.common.company
    };
};

const mapDispatchToProps = (dispatch) => ({
    onLoad: async (payload) => {
        dispatch({ type: EVENT_PAGE_LOADED, payload });
    },
    onUnload: () => {
        dispatch({ type: EVENT_PAGE_UNLOADED });
        dispatch({ type: SET_ACTIVE_WIDGET, payload: null });
        dispatch({ type: SET_WIDGET_RESULT, payload: null });
        dispatch({ type: WATCHING_NOW, payload: 0 });
    },
    liveViewers: (payload) => {
        dispatch({ type: PEOPLE_WATCHING, payload });
    }
});

const Event = ({
    currentEvent,
    currentlyWatching,
    currentWidget,
    roomId,
    company,
    user,
    onLoad,
    onUnload,
    liveViewers
}) => {
    const [viewLandscape, setViewLandscape] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const { slug } = useParams();
    const [event, setEvent] = useState(null);
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaImage, setMetaImage] = useState('');

    useEffect(() => {
        if (company && currentEvent) {
            if (currentEvent?.thumbnail?.image_asset) {
                const image = sanity.urlFor(currentEvent.thumbnail.image_asset.asset);
                const transformedImage = transformToSanityUrl(image.options);
                setMetaImage(transformedImage);
            }
            setMetaTitle(currentEvent.title);
            setMetaDescription(currentEvent.description);
        }
    }, [company, currentEvent]);

    useEffect(() => {
        const handleOrientationChange = () => {
            let bodyClass = document.getElementsByTagName('body')[0].classList;
            if (window.screen.orientation) {
                let angle = window.screen.orientation.angle;
                if (angle === 90 || angle === 270 || angle === -90) {
                    bodyClass.add('fullscreen-body');
                } else {
                    bodyClass.remove('fullscreen-body');
                }
            } else {
                if (window.orientation === 90 || window.orientation === -90) {
                    bodyClass.add('fullscreen-body');
                } else {
                    bodyClass.remove('fullscreen-body');
                }
            }
        };

        const handleOrientationChangeAndLoad = async () => {
            handleOrientationChange();

            if (slug) {
                onLoad(sanity.Events.GetEventBySlug(slug));
                if (screen && screen.orientation) {
                    setViewLandscape(screen.orientation.type.includes('landscape'));
                }
            }
        };

        handleOrientationChangeAndLoad();

        let supportsOrientationChange = 'onorientationchange' in window;
        let orientationEvent = supportsOrientationChange ? 'orientationchange' : 'resize';
        window.addEventListener(orientationEvent, handleOrientationChange);

        return () => {
            onUnload();
            window.removeEventListener(orientationEvent, handleOrientationChange);
            if (interval) {
                clearInterval(interval);
            }
        };
    }, []);

    useEffect(() => {
        const handleLiveViewers = () => {
            liveViewers({ room_id: roomId });
            const interval = setInterval(() => {
                liveViewers({ room_id: roomId });
            }, 15000);
            return () => clearInterval(interval);
        };

        if (roomId && currentEvent && currentEvent.stream_status === 'active') {
            handleLiveViewers();
        }
        if (currentEvent) {
            setEvent(currentEvent);
        }
    }, [roomId, currentEvent]);

    let interval;
    return (
        <>
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription}></meta>
                <link rel="canonical" href={window.location.pathname}></link>
                <meta property="og:title" content={metaTitle} />
                <meta property="og:description" content={metaDescription} />
                <meta property="og:image" content={metaImage} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={metaTitle} />
                <meta name="twitter:description" content={metaDescription} />
                <meta name="twitter:image" content={metaImage} />
            </Helmet>
            <div>
                <Nav renderBack renderSearch renderProfile />
                {/* <OnBack defaultHref={'/events'} /> */}
                <div className={styles.chat}>
                    <div className={styles.eventWrapper} id={'event'}>
                        {event && (
                            <>
                                <EventInfo
                                    currentlyWatching={currentlyWatching}
                                    event={event}
                                    eventOpen={true}
                                    viewLandscape={viewLandscape}
                                    setShowOptions={() => setShowOptions((prevState) => !prevState)}
                                    isEventPage={true}
                                    // toggled={toggled}
                                    displayVideoStats
                                />
                                <div
                                    style={{
                                        maxWidth: '900px',
                                        margin: '0 auto',
                                        position: 'relative',
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'flex-start',
                                        padding: '16px'
                                    }}>
                                    {event.description}
                                </div>
                            </>
                        )}
                        {event && user.loaded && canSeeEvent(user, event) && (
                            <>
                                {currentWidget && <Widget widget={currentWidget} />}
                                {event && (
                                    <Chat
                                        streamStatus={event.stream_status}
                                        eventSlug={slug}
                                        event={event}
                                        showOptions={showOptions}
                                        setShowOptions={setShowOptions}
                                        // toggled={toggled}
                                        // setToggled={() => setToggled((prevState) => !prevState)}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Event);
