import { Button, CircularProgress, Modal, Typography } from '@mui/material';
import IVSBroadcastClient from 'amazon-ivs-web-broadcast';
import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@mui/styles';
import broadcast from '../../../assets/images/broadcast.svg';
import cameraflip from '../../../assets/images/cameraflip.svg';
import close from '../../../assets/images/close.svg';
import muteMic from '../../../assets/images/mute-mic.svg';
import stop from '../../../assets/images/stop.svg';
import unMuteMic from '../../../assets/images/unmute-mic.svg';
import {
    CLEAN_LAST_STREAM_DATA,
    CREATE_EVENT,
    SET_CAMERA_FACING_MODE,
    SET_CURRENT_LIVESTREAM,
    SET_IVS_STREAM_CONFIG,
    SET_MEDIA_DEVICES,
    SET_MIC_MUTE,
    SET_STREAMING_CLIENT
} from '../../../constants/actionTypes';
import styles from '../streaming.module.scss';
import { EventTypes } from './Helpers/eventTypes';
import { Webcam } from './Webcam';
import './goLive.scss';
import { orientationService } from '../../../core/service/orientation.service';

const API_ENDPOINTS = {
    createStream: `${process.env.REACT_APP_API_URL}/ivs/createStream`,
    prepareStreamEvent: `${process.env.REACT_APP_API_URL}/ivs/prepare-stream`,
    getStreamStatus: `${process.env.REACT_APP_API_URL}/ivs/getStream`
};

const useStyles = makeStyles(() => ({
    cameraContainer: {
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        width: '100vw',
        height: '100vh',
        overflow: 'hidden'
    }
}));

const mapStateToProps = (state) => {
    return {
        ...state.liveStreaming,
        user: state.user.currentUser,
        company: state.common.company,
        orientation: state.appState.deviceOrientation
    };
};

const mapDispatchToProps = (dispatch) => ({
    setMediaDevices: (mediaDevices) =>
        dispatch({
            type: SET_MEDIA_DEVICES,
            payload: mediaDevices
        }),
    setIvsConfig: (ivsConfig) =>
        dispatch({
            type: SET_IVS_STREAM_CONFIG,
            payload: ivsConfig
        }),
    setLiveStreamStatus: (livestreamLive) =>
        dispatch({
            type: SET_CURRENT_LIVESTREAM,
            payload: livestreamLive
        }),
    setStreamingClient: (streamingClient) =>
        dispatch({
            type: SET_STREAMING_CLIENT,
            payload: streamingClient
        }),
    cleanStreamData: () =>
        dispatch({
            type: CLEAN_LAST_STREAM_DATA
        }),
    createEventSanity: (eventPayload) => dispatch({ type: CREATE_EVENT, payload: eventPayload }),
    setCameraFacingMode: (payload) => dispatch({ type: SET_CAMERA_FACING_MODE, payload }),
    setMicMute: (payload) => dispatch({ type: SET_MIC_MUTE, payload })
});

const GoLive = ({
    config,
    selectedDevice,
    mediaDevices,
    setIvsConfig,
    setLiveStreamStatus,
    liveStreamStatus,
    user,
    createEventSanity,
    videoTitle,
    facingMode,
    setCameraFacingMode,
    setMicMute,
    microphone,
    orientation,
    setSelectedDevice,
    cleanStreamData
}) => {
    const { t } = useTranslation();
    const webcamRef = useRef(null);
    const eventTypes = EventTypes();
    const history = useHistory();
    const [countdown, setCountdown] = useState(3);
    const [isCounting, setIsCounting] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [stream, setStream] = useState(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [tags, setTags] = useState([]);
    const [thumbnail, setThumbnail] = useState();
    const [videoDeviceName, setVideoDeviceName] = useState(null);
    const [audioDeviceName, setAudioDeviceName] = useState(null);
    const [streamState, setStreamState] = useState({
        health: '',
        state: 'Loading...',
        viewerCount: 0
    });
    const broadcastClientRef = useRef(undefined);
    const [aspectRatio, setAspectRatio] = useState(16 / 9); // Default aspect ratio
    const classes = useStyles();
    const streamConfig =
        orientation === 'LANDSCAPE'
            ? IVSBroadcastClient.BASIC_LANDSCAPE
            : IVSBroadcastClient.BASIC_PORTRAIT;
    const [videoConstraints, setVideoConstrains] = React.useState();

    const [broadcastClientMounted, setBroadcastClientMounted] = useState(false);

    function isIOS() {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    }

    function isSafari() {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }

    useEffect(() => {
        // This effect runs when broadcastClientRef or stream changes

        if (broadcastClientRef.current && stream) {
            console.info('Setup new stream config');
            let id = Math.floor(Math.random() * 100);
            const videoDeviceName = `VIDEO-${id}`;
            const audioDeviceName = `AUDIO-${id}`;

            const composition = {
                index: 0 // You might want to configure this based on your needs
            };

            // Add video and audio devices to the streaming client
            broadcastClientRef.current.addVideoInputDevice(stream, videoDeviceName, composition);
            broadcastClientRef.current.addAudioInputDevice(stream, audioDeviceName, {
                index: 0
            });

            setVideoDeviceName(videoDeviceName);
            setAudioDeviceName(audioDeviceName);

            // Store broadcast client mount time (workaround for react state updates)
            setBroadcastClientMounted(new Date());
            console.log('Broadcast client mounted');
            // Clean up devices when component unmounts or dependencies change
            // return () => {
            //     console.log('Cleaning up video and audio devices');
            //     if (streamingClient && videoDeviceName && audioDeviceName) {
            //         try {
            //             console.log("Attempting to remove devices:", { videoDeviceName, audioDeviceName });
            //
            //             // Ensure devices were added before attempting to remove them
            //             if (streamingClient.getVideoInputDevice(videoDeviceName)) {
            //                 streamingClient.removeVideoInputDevice(videoDeviceName);
            //             }
            //             if (streamingClient.getAudioInputDevice(audioDeviceName)) {
            //                 streamingClient.removeAudioInputDevice(audioDeviceName);
            //             }
            //
            //             console.log("Devices removed successfully.");
            //         } catch (error) {
            //             console.error("Error removing devices:", error);
            //         }
            //     }
            // };
        }
    }, [broadcastClientRef, stream]);

    // useEffect(() => {
    //     console.info("previewRef.current" , previewRef.current);
    //     if (broadcastClientMounted)
    //         broadcastClientRef.current.attachPreview(previewRef.current);
    //     return () => {
    //         if (broadcastClientRef.current)
    //             broadcastClientRef.current.detachPreview();
    //     };
    // }, [broadcastClientMounted]);

    useEffect(() => {
        if (!selectedDevice) {
            history.push('/create-video');
        }
        const storedTitle = localStorage.getItem('videoTitle');
        const storedDescription = localStorage.getItem('videoDescription');
        const storedTags = localStorage.getItem('tagsLive');
        const storedThumbnail = localStorage.getItem('thumbnailLive');
        if (storedTitle) {
            setTitle(storedTitle);
        }
        if (storedDescription) {
            setDescription(storedDescription);
        }
        if (storedTags.length > 0) {
            setTags(JSON.parse(storedTags));
        }
        if (storedThumbnail) {
            setThumbnail(JSON.parse(storedThumbnail));
        }
        createBroadcastClient();
        return () => stopLiveStream();
    }, []);

    useEffect(() => {
        if (!config || !streamConfig || liveStreamStatus.live) return;
        restartBroadcastClient();
        setVideoConstrains({
            width:
                (isSafari() || isIOS()) && orientation === 'PORTRAIT'
                    ? streamConfig.maxResolution.height
                    : streamConfig.maxResolution.width,
            height:
                (isSafari() || isIOS()) && orientation === 'PORTRAIT'
                    ? streamConfig.maxResolution.width
                    : streamConfig.maxResolution.height,
            deviceId: selectedDevice,
            aspectRatio: orientation === 'LANDSCAPE' ? 16 / 9 : 9 / 16
        });
    }, [streamConfig, config]);

    useEffect(() => {
        const channelARN = config?.data?.channel?.arn ?? null;
        if (channelARN && liveStreamStatus.live) {
            const interval = setInterval(() => {
                getStreamStatus(channelARN);
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [config, liveStreamStatus]);

    const getStreamStatus = (channelArn) => {
        if (liveStreamStatus.live) {
            fetch(API_ENDPOINTS.getStreamStatus, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ channelArn })
            })
                .then((r) => r.json())
                .then((r) => setStreamState(r?.data?.stream))
                .catch((e) => console.error(e));
        }
    };

    useEffect(() => {
        if (isCounting) {
            const timer = setTimeout(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
            if (countdown === 0) {
                clearTimeout(timer);
                setIsCounting(false);
                startStream();
            }
            return () => clearTimeout(timer);
        }
    }, [isCounting, countdown]);

    function toggleCamera() {
        let newFacingMode = facingMode;
        if (facingMode === 'user') {
            setCameraFacingMode('environment');
            newFacingMode = 'environment';
        } else if (facingMode === 'environment') {
            setCameraFacingMode('user');
            newFacingMode = 'user';
        }

        const bestCameras = mediaDevices?.videoDevices;
        //console.info(`BEST CAMERAS GO LIVE` ,  JSON.stringify(bestCameras));

        let deviceId;
        // Select the camera based on facing mode
        if (newFacingMode === 'user' && bestCameras.front) {
            deviceId = bestCameras?.front?.deviceId;
            //setSelectedDevice(bestCameras.front.deviceId);
        } else if (newFacingMode === 'environment' && bestCameras.back) {
            deviceId = bestCameras?.back?.deviceId;
            //setSelectedDevice(bestCameras.back.deviceId);
        } else if (bestCameras.desktop) {
            deviceId = bestCameras?.desktop?.deviceId;
            //setSelectedDevice(bestCameras.desktop.deviceId);
        }
        if (deviceId) {
            setVideoConstrains({ ...videoConstraints, deviceId });
        } else {
            setVideoConstrains({ ...videoConstraints, facingMode: newFacingMode });
        }

        // if (mediaDevices?.videoDevices[0] === '9a4c2d32aaebecabfefb94c8399085e7c921d476422e1ac063fd63b3a11101db' ) {
        //     setSelectedDevice('53c95f27d7a22a4381cddde382531cc0aa714e84d8243307bcd41cf0ee5b0e09');
        //     //setVideoConstrains({ deviceId: '53c95f27d7a22a4381cddde382531cc0aa714e84d8243307bcd41cf0ee5b0e09', facingMode });
        // } else {
        //     setSelectedDevice('9a4c2d32aaebecabfefb94c8399085e7c921d476422e1ac063fd63b3a11101db');
        //     //setVideoConstrains({ deviceId: '9a4c2d32aaebecabfefb94c8399085e7c921d476422e1ac063fd63b3a11101db', facingMode });
        // }
    }

    // Calculate aspect ratio based on screen size
    useEffect(() => {
        const updateAspectRatio = () => {
            const screenWidth = window.innerWidth;
            const screenHeight = window.innerHeight;
            setAspectRatio(screenWidth / screenHeight);
        };

        updateAspectRatio();
        window.addEventListener('resize', updateAspectRatio);

        return () => {
            window.removeEventListener('resize', updateAspectRatio);
        };
    }, []);

    //Function to style camera preview component
    const getCameraStyle = () => {
        if (aspectRatio >= 1) {
            // Landscape mode
            return {
                objectFit: 'cover',
                width: '100%',
                height: 'auto',
                maxHeight: '100vh'
            };
        } else {
            // Portrait mode
            return {
                objectFit: 'cover',
                width: 'auto',
                height: '100%',
                maxWidth: '100vw',
                transform: `scale(1)`
            };
        }
    };

    const handleCloseStream = async () => {
        if (liveStreamStatus.live) {
            handleCancelStream();
        } else {
            history.push('/events');
            window.location.reload(true);
        }
    };

    const handleCancelStream = async () => {
        if (broadcastClientRef.current) {
            broadcastClientRef.current.stopBroadcast();
        }
        setOpenModal(true);
    };

    function toggleMicrophone() {
        if (microphone === 'muted') {
            unmuteMicrophone();
            setMicMute('unmuted');
        } else if (microphone === 'unmuted') {
            muteMicrophone();
            setMicMute('muted');
        }
    }

    const muteMicrophone = () => {
        if (audioDeviceName) {
            const audioStream = broadcastClientRef.current.getAudioInputDevice(audioDeviceName);
            audioStream.getAudioTracks()[0].enabled = false;
        }
    };

    const unmuteMicrophone = () => {
        if (audioDeviceName) {
            const audioStream = broadcastClientRef.current.getAudioInputDevice(audioDeviceName);
            audioStream.getAudioTracks()[0].enabled = true;
        }
    };

    const onUserMedia = async (stream) => {
        setStream(stream);
    };

    const handleConfirm = async () => {
        setLoading(true);
        try {
            await stopLiveStream();
            setOpenModal(false);
            history.push('/events');
        } catch (error) {
            console.log(error.message);
        }
    };

    const createBroadcastClient = () => {
        fetch(API_ENDPOINTS.createStream)
            .then((r) => r.json())
            .then((r) => {
                if (r.success) {
                    setIvsConfig(r);
                } else {
                    alert(r.message);
                }
            })
            .catch((e) => console.log(e));
    };

    const destroyBroadcastClient = (client) => {
        if (!client) return;
        client.delete();
        setBroadcastClientMounted(false);
    };

    const restartBroadcastClient = async () => {
        // if (liveStreamStatus) stopLiveStream();
        console.info('Restarting broadcast client');
        destroyBroadcastClient(broadcastClientRef.current);

        if (config && config.data.channel.ingestEndpoint) {
            const client = IVSBroadcastClient.create({
                streamConfig,
                ingestEndpoint: `rtmps://${config.data.channel.ingestEndpoint}:443/app/`,
                logLevel: IVSBroadcastClient.LOG_LEVEL.DEBUG
            });
            broadcastClientRef.current = client;
        }
    };

    const startStream = async () => {
        orientationService.startStream();
        broadcastClientRef.current
            .startBroadcast(config.data.streamKey.value)
            .then(() => {
                setLiveStreamStatus({ live: true });
                setTimeout(() => {
                    fetch(API_ENDPOINTS.prepareStreamEvent, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            ivs: { playbackUrl: config.data.channel.playbackUrl }
                        })
                    })
                        .then((r) => r.json())
                        .catch((e) => console.log(e));
                }, 1000);

                setTimeout(() => {
                    thumbnail
                        ? createEventSanity({
                              title: title,
                              description: description,
                              slug: {
                                  type: 'slug',
                                  current: title + Math.ceil(Math.random() * 1000000)
                              },
                              thumbnail: thumbnail,
                              startDate: new Date(),
                              event_type: 'video_event',
                              stream_type: 'webrtc_ivs',
                              endpoint: config.data.channel.playbackUrl,
                              liveStreamUrl: config.data.channel.playbackUrl,
                              stream_arn: config.data.channel.arn,
                              streamStatus: 'upcoming',
                              user_id: user.data.sub,
                              tags: tags,
                              resource_creation_time: 0,
                              sports_team: {
                                  _ref: process.env.REACT_APP_COMPANY_ID,
                                  _type: 'reference'
                              }
                          })
                        : createEventSanity({
                              title: title,
                              description: description,
                              slug: {
                                  type: 'slug',
                                  current: title + Math.ceil(Math.random() * 1000000)
                              },
                              startDate: new Date(),
                              event_type: 'video_event',
                              stream_type: 'webrtc_ivs',
                              endpoint: config.data.channel.playbackUrl,
                              liveStreamUrl: config.data.channel.playbackUrl,
                              stream_arn: config.data.channel.arn,
                              streamStatus: 'upcoming',
                              user_id: user.data.sub,
                              tags: tags,
                              resource_creation_time: 0,
                              sports_team: {
                                  _ref: process.env.REACT_APP_COMPANY_ID,
                                  _type: 'reference'
                              }
                          });
                    localStorage.removeItem('thumbnailLive');
                }, 2000);
            })
            .catch((error) => {
                console.error('Something drastically failed while broadcasting!', error);
            });
    };

    const timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const stopLiveStream = async () => {
        if (liveStreamStatus) {
            if (broadcastClientRef.current) {
                try {
                    broadcastClientRef.current.stopBroadcast();
                    await cleanStreamData();
                    orientationService.stopStream();
                } catch (e) {
                    console.error(e);
                }
            }

            if (config) {
                await timeout(1000);
                await fetch(`${process.env.REACT_APP_API_URL}/ivs/deleteStream`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        arn: config?.data?.channel?.arn,
                        userId: user?.data?.sub ?? '-1'
                    })
                })
                    .then((r) => r.json())
                    .then((r) => console.log(r))
                    .catch((e) => console.error(e));
            }
        }

        return true;
    };

    const getAllCheckedTags = () => {
        var tags = [].filter((x) => x['tagStatus'] === true);
        let tagNames = [];
        tags.forEach((element) => {
            tagNames.push(element.tagName);
        });

        return tagNames;
    };

    return (
        <div>
            <div>
                <div className={classes.cameraContainer}>
                    {videoConstraints && (
                        <Webcam
                            onUserMedia={onUserMedia}
                            ref={webcamRef}
                            mirrored={facingMode === 'user'}
                            style={getCameraStyle()}
                            videoConstraints={videoConstraints}
                            audio={microphone === 'unmuted'}
                            muted
                        />
                    )}
                    {isMobile && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => toggleCamera()}
                            style={{
                                position: 'absolute',
                                top: 10,
                                right: 10,
                                zIndex: 1,
                                width: '40px',
                                height: '40px',
                                borderRadius: '50%',
                                minWidth: 0,
                                backgroundColor: 'black',
                                color: 'black'
                            }}>
                            <img src={cameraflip} alt="cameraflip" />
                        </Button>
                    )}
                    {liveStreamStatus.live && countdown === 0 && (
                        <div
                            style={{
                                position: 'absolute',
                                color: 'white',
                                top: '8%',
                                left: '4%',
                                opacity: '0.9',
                                padding: '10px'
                            }}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    gap: '5px'
                                }}>
                                <div
                                    style={{
                                        backgroundColor: '#FF2D55',
                                        borderRadius: '6px',
                                        padding: '6px 10px'
                                    }}>
                                    {streamState?.state ?? 'OFFLINE'}
                                </div>
                                <div
                                    style={{
                                        backgroundColor: 'rgba(2, 2, 2, 0.70)',
                                        borderRadius: '6px',
                                        padding: '6px 10px'
                                    }}>
                                    {streamState?.viewerCount ?? 0}
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {isCounting && (
                    <div
                        style={{
                            position: 'absolute',
                            top: '45%',
                            left: '50%',
                            marginTop: '-50px',
                            marginLeft: '-50px',
                            width: '120px',
                            height: '120px',
                            borderRadius: '50%',
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            border: '4px solid gray',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        <span
                            style={{
                                fontSize: '36px',
                                fontWeight: 'bold',
                                color: '#FFF'
                            }}>
                            {countdown}
                        </span>
                    </div>
                )}
                <div
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center',
                        padding: '12px'
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            marginTop: '20px',
                            justifyContent: 'space-between',
                            width: '70%'
                        }}>
                        {!liveStreamStatus.live ? (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setIsCounting(true)}
                                style={{
                                    backgroundColor: '#32E7B2',
                                    width: '50px',
                                    height: '50px',
                                    borderRadius: '50%',
                                    minWidth: 0
                                }}>
                                <img src={broadcast} alt="broadcast" />
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleCancelStream}
                                style={{
                                    backgroundColor: '#FF2D55',
                                    width: '50px',
                                    height: '50px',
                                    borderRadius: '50%',
                                    minWidth: 0
                                }}>
                                <img src={stop} alt="stop" />
                            </Button>
                        )}
                        <Button
                            onClick={() => handleCloseStream()}
                            style={{
                                backgroundColor: 'rgba(34, 34, 34, 0.80)',
                                width: '50px',
                                height: '50px',
                                borderRadius: '50%',
                                minWidth: 0
                            }}>
                            <img src={close} alt="stop" />
                        </Button>
                        {/* {isMobile && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => toggleCamera()}
                                    style={{
                                        width: '50px',
                                        height: '50px',
                                        borderRadius: '50%',
                                        minWidth: 0,
                                        backgroundColor: 'white',
                                        color: 'black'
                                    }}>
                                    <img src={cameraflip} alt="cameraflip" />
                                </Button>
                            )} */}
                        <Button
                            onClick={toggleMicrophone}
                            style={{
                                backgroundColor: 'white',
                                border: '2px solid gray',
                                width: '50px',
                                height: '50px',
                                borderRadius: '50%',
                                minWidth: 0
                            }}>
                            <img
                                src={microphone === 'muted' ? unMuteMic : muteMic}
                                alt="microphone"
                            />
                        </Button>
                        <Modal open={openModal}>
                            <div className={isMobile ? styles.modalMobile : styles.modal}>
                                <Typography
                                    marginTop={isMobile ? '3%' : '-1%'}
                                    fontSize={'18px'}
                                    textAlign={'center'}>
                                    {t('StopStreamHeader')}
                                </Typography>
                                <Typography
                                    className={isMobile ? styles.tooltipMobile : styles.tooltip}
                                    textAlign={'center'}>
                                    {t('StopStreamTooltip')}
                                </Typography>
                                <div
                                    className={
                                        isMobile ? styles.flexContainerMobile : styles.flexContainer
                                    }>
                                    {!loading ? (
                                        <>
                                            <Button
                                                className={
                                                    isMobile
                                                        ? styles.confirmButtonMobile
                                                        : styles.confirmButton
                                                }
                                                onClick={handleConfirm}>
                                                {t('StopStream')}
                                            </Button>
                                            <Button
                                                className={
                                                    isMobile
                                                        ? styles.cancelButtonMobile
                                                        : styles.cancelButton
                                                }
                                                onClick={() => setOpenModal(false)}>
                                                {t('Close')}
                                            </Button>
                                        </>
                                    ) : (
                                        <div
                                            style={{
                                                margin: '0 auto'
                                            }}>
                                            <CircularProgress />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(GoLive);
