import BlockIcon from '@mui/icons-material/Block';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IosShareIcon from '@mui/icons-material/IosShare';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Drawer,
    FormControlLabel,
    Modal,
    Switch,
    Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
    ChatRoom,
    DeleteMessageRequest,
    DisconnectUserRequest,
    SendMessageRequest
} from 'amazon-ivs-chat-messaging';
import { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    ADD_MESSAGE,
    BLOCKING_USER,
    FETCH_NEW_MESSAGES,
    GET_BLOCKED_USERS,
    REACTION_RECEIVED,
    REPORT_MESSAGE,
    SET_CHAT_MESSAGES,
    SET_USER_DATA
} from '../../constants/actionTypes';
import chatService from '../../core/service/chat.service';
import fetchData from '../../fetch';
import ChatContainer from './ChatContainer';
import ChatInputForm from './ChatInputFrom';
import RequestAuthForChat from './RequestAuthForChat';
import styles from './chatContainer.module.scss';
import DeleteIcon from '@mui/icons-material/Delete';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles(() => ({
    switch: {
        '& .MuiSwitch-track': {
            backgroundColor: 'lightgray'
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: 'white'
        },
        '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
            backgroundColor: '#32E7B2'
        }
    }
}));

const mapStateToProps = (state) => {
    return {
        chatMessages: state.event.chatMessages,
        roomId: state.event.roomId,
        chatLoading: state.event.chatLoading,
        user: state.user.currentUser.data,
        blockedUsers: state.user.currentUser.blockedUsers,
        company: state.common.company,
        isAdmin: state.user.currentUser.groups && state.user.currentUser.groups.includes('Admin'),
        isModerator:
            state.user.currentUser.groups && state.user.currentUser.groups.includes('Moderator')
    };
};

const mapDispatchToProps = (dispatch) => ({
    onLoadMessages: (payload) => dispatch({ type: SET_CHAT_MESSAGES, payload }),
    onFetchNewMessages: (payload) => dispatch({ type: FETCH_NEW_MESSAGES, payload }),
    onNewMessage: (payload) => dispatch({ type: ADD_MESSAGE, payload }),
    onBlockingUser: (payload) => dispatch({ type: BLOCKING_USER, payload }),
    onLoadUserData: (payload) => dispatch({ type: SET_USER_DATA, payload }),
    onLoadBlockedUsers: (payload) => dispatch({ type: GET_BLOCKED_USERS, payload }),
    onReportMessage: (payload) => dispatch({ type: REPORT_MESSAGE, payload }),
    onReactionEvent: (payload) => dispatch({ type: REACTION_RECEIVED })
});

const Chat = (props) => {
    const MESSAGE_SCROLL_INTERVAL = 7;
    const MILLIS_PER_DAY = 24 * 60 * 60 * 1000;
    const { t } = useTranslation();
    const history = useHistory();
    const [username, setUsername] = useState();
    const [moderator, setModerator] = useState(false);
    const awsRegionExp = /arn:aws:[^:]+:([^:]+):[^:]+:[^:]+\/.*/;
    const user = props.user;
    const publicUser = props.user == null;
    const stateMessages = props.chatMessages;
    const chatLoading = props.chatLoading;
    const [viewMessages, setViewMessages] = useState([]);
    // const [present] = useIonToast();
    // const presentToast = (message) => {
    //     present({
    //         message: message,
    //         duration: 1500,
    //         position: 'top',
    //         icon: closeCircleSharp,
    //         color: 'danger'
    //     });
    // };

    const [isClicked, setIsClicked] = useState(false);

    const [isMessageBlocked, setIsMessageBlocked] = useState(false);

    const [eventOptionsProps, setEventOptionsProps] = useState({
        isMessage: true,
        eventSlug: null,
        eventId: props.event.id,
        isClicked: false,
        message: null,
        initials: null
    });

    const roomId = props.event.chat_room_arn;
    const messageEl = useRef(null);
    const [prevScroll, setPrevScroll] = useState(0);

    const [chatRoom, setChatRoom] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        async function fetchMessages() {
            const endTime = new Date();
            const startTime = new Date(
                endTime.getTime() - MESSAGE_SCROLL_INTERVAL * MILLIS_PER_DAY
            );
            const messagesData = await fetchData.Chat.List(
                roomId,
                startTime.getTime(),
                endTime.getTime()
            );
            console.info(messagesData);
            let messages = chatService.MapMessages(messagesData);
            messages = messages.filter((message) => message.messageType === 'MESSAGE');
            props.onLoadMessages(messages);
        }

        if (roomId) {
            fetchMessages();
        }
    }, [roomId]);

    useEffect(() => {
        async function isUserBlocked() {
            if (roomId && user) {
                const block = await fetchData.Chat.BlockedUser(user.sub);
                if (block.items.length > 0) {
                    // presentToast('You have active block you cannot connect to chat room!');
                }
                handleSignIn(roomId, block.items.length === 0);
            }
        }
        isUserBlocked();
    }, [roomId, user]);

    useEffect(() => {
        const newMessages = stateMessages.length - viewMessages.length;
        setViewMessages(stateMessages);
        setTimeout(() => scrollDown(40 * newMessages), 300);
    }, [stateMessages]);

    // useEffect(() => {
    //     handleOverflow();
    // }, [viewMessages]);

    useEffect(() => {
        props.onReportMessage(eventOptionsProps.message);
    }, [eventOptionsProps]);

    const tokenProvider = async (roomArn, selectedUsername, userId, isModerator, avatarUrl) => {
        const permissions = isModerator
            ? ['SEND_MESSAGE', 'DELETE_MESSAGE', 'DISCONNECT_USER']
            : ['SEND_MESSAGE'];

        const data = {
            arn: roomArn,
            userId,
            attributes: {
                username: `${selectedUsername}`,
                avatar: avatarUrl ? `${avatarUrl}` : null
            },
            capabilities: permissions
        };

        let token;
        try {
            const response = await fetchData.Chat.Auth(data);
            token = {
                token: response.token,
                sessionExpirationTime: new Date(response.sessionExpirationTime),
                tokenExpirationTime: new Date(response.tokenExpirationTime)
            };
        } catch (error) {
            console.error('Error:', error);
        }

        return token;
    };

    const handleSignIn = (roomArn, connect) => {
        const match = roomArn.match(awsRegionExp);
        let nickname;
        const moderator = props.isAdmin || props.isModerator;
        const avatarUrl = user.picture;
        const userId = user.sub;
        if (user && user.nickname) {
            nickname = user.nickname;
        }

        if (user && !nickname && user.given_name && user.family_name) {
            nickname = `${user.given_name} ${user.family_name}`;
        }
        // Set application state
        setUsername(nickname);
        setModerator(moderator);
        if (match) {
            const region = match[1];
            // Instantiate a chat room
            const room = new ChatRoom({
                regionOrUrl: region,
                tokenProvider: () => tokenProvider(roomArn, nickname, userId, moderator, avatarUrl)
            });
            setChatRoom(room);

            // Connect to the chat room
            if (connect) room.connect();
        } else {
            // presentToast('Cannot connect to chat room!');
        }
    };

    const deleteMessageByUserId = async (userId) => {
        // Send a delete event
        try {
            const response = await sendEvent({
                eventName: 'app:DELETE_BY_USER',
                eventAttributes: {
                    userId: userId
                }
            });
            return response;
        } catch (error) {
            return error;
        }
    };

    const handleMessageDelete = async (messageId) => {
        const request = new DeleteMessageRequest(messageId, 'Reason for deletion');
        try {
            await chatRoom.deleteMessage(request);
        } catch (error) {
            console.error(error);
        }
    };

    const handleUserKick = async (message) => {
        const request = new DisconnectUserRequest(message.userId, 'Kicked by moderator');
        try {
            await chatRoom.disconnectUser(request);
            await deleteMessageByUserId(message.userId);
            blockUser(message);
        } catch (error) {
            console.error(error);
        }
    };

    const handleSticker = (data) => {
        const username = data.sender.attributes?.username;
        const userId = data.sender.userId;
        const avatar = data.sender.attributes.avatar;
        const message = data.content;
        const sticker = data.attributes.sticker_src;
        const messageId = data.id;
        const timestamp = data.sendTime;

        const newMessage = {
            type: 'STICKER',
            timestamp,
            username,
            userId,
            avatar,
            message,
            messageId,
            sticker
        };

        setViewMessages((prevState) => {
            return [...prevState, newMessage];
        });
    };

    // const handleRaiseHand = async (data) => {
    //     const username = data.sender.attributes?.username;
    //     setUsernameRaisedHand(username);
    //
    //     if (previousRaiseHandUsername.current !== username) {
    //         setShowRaiseHandPopup(true);
    //     } else {
    //         setShowRaiseHandPopup((showRaiseHandPopup) => !showRaiseHandPopup);
    //     }
    // };

    const handleStickerSend = async (sticker) => {
        const content = `Sticker: ${sticker.name}`;
        const attributes = {
            message_type: 'STICKER',
            sticker_src: `${sticker.src}`
        };
        const request = new SendMessageRequest(content, attributes);
        try {
            await chatRoom.sendMessage(request);
        } catch (error) {
            handleError(error);
        }
    };

    const handleReactionSend = async (content) => {
        const attributes = {
            message_type: 'REACTION'
        };
        const request = new SendMessageRequest(content, attributes);
        try {
            await chatRoom.sendMessage(request);
        } catch (error) {
            handleError(error);
        }
    };

    // const handleRaiseHandSend = async () => {
    //     const attributes = {
    //         message_type: 'RAISE_HAND',
    //     };
    //
    //     const request = new SendMessageRequest(`[raise hand event]`, attributes);
    //     try {
    //         await chatRoom.sendMessage(request);
    //         setHandRaised((prevState) => !prevState);
    //     } catch (error) {
    //         handleError(error);
    //     }
    // };

    const sendMessage = async (message) => {
        const content = `${message.replace(/\\/g, '\\\\').replace(/"/g, '\\"')}`;
        const request = new SendMessageRequest(content);
        // console.log("chatRoom: ",chatRoom)
        try {
            await chatRoom.sendMessage(request);
        } catch (error) {
            handleError(error);
        }
    };

    const sendEvent = async (data) => {
        const formattedData = {
            arn: roomId,
            eventName: `${data.eventName}`,
            eventAttributes: data.eventAttributes
        };

        try {
            const response = await fetchData.Chat.Event(formattedData);
            console.info('SendEvent Success:', response);
            return response;
        } catch (error) {
            console.error('SendEvent Error:', error);
            return error;
        }
    };

    const handleError = (data) => {
        const username = '';
        const userId = '';
        const avatar = '';
        const message = `Error ${data.message}`;
        const messageId = '';
        const timestamp = `${Date.now()}`;

        const newMessage = {
            type: 'ERROR',
            timestamp,
            username,
            userId,
            avatar,
            message,
            messageId
        };

        setViewMessages((prevState) => {
            return [...prevState, newMessage];
        });
    };

    const handleEvent = (event) => {
        const eventName = event.eventName;
        switch (eventName) {
            case 'aws:DELETE_MESSAGE':
                // Ignore system delete message events, as they are handled
                // by the messageDelete listener on the room.
                break;
            case 'app:DELETE_BY_USER':
                // eslint-disable-next-line no-case-declarations
                const userIdToDelete = event.attributes.userId;
                setViewMessages((prevState) => {
                    // Remove message that matches the MessageID to delete
                    const newState = prevState.filter((item) => item.userId !== userIdToDelete);
                    return newState;
                });
                break;
            default:
                console.info('Unhandled event received:', event);
        }
    };

    const handleMessage = (data) => {
        const username = data.sender.attributes.username;
        const userId = data.sender.userId;
        const avatar = data.sender.attributes.avatar;
        const message = data.content;
        const messageId = data.id;
        const timestamp = data.sendTime;

        const newMessage = {
            type: 'MESSAGE',
            timestamp,
            username,
            userId,
            avatar,
            message,
            messageId
        };

        setViewMessages((prevState) => {
            return [...prevState, newMessage];
        });
    };

    const renderConnect = () => {
        const status = {
            type: 'SUCCESS',
            timestamp: `${Date.now()}`,
            username: '',
            userId: '',
            avatar: '',
            message: `Connected to the chat room.`
        };
        setViewMessages((prevState) => {
            return [...prevState, status];
        });
    };

    const renderDisconnect = (reason) => {
        const error = {
            type: 'ERROR',
            timestamp: `${Date.now()}`,
            username: null,
            userId: '',
            avatar: null,
            message: `Connection closed. Reason: ${reason}`
        };
        setViewMessages((prevState) => {
            return [...prevState, error];
        });
    };

    const renderChatLineActions = (message) => {
        return (
            <>
                <button
                    className="chat-line-btn"
                    onClick={(e) => {
                        e.preventDefault();
                        handleMessageDelete(message.messageId);
                    }}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 0 24 24"
                        width="24px">
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
                    </svg>
                </button>
                <button
                    className="chat-line-btn"
                    onClick={(e) => {
                        e.preventDefault();
                        handleUserKick(message);
                    }}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        enableBackground="new 0 0 24 24"
                        height="24px"
                        viewBox="0 0 24 24"
                        width="24px">
                        <rect fill="none" height="24" width="24" />
                        <g>
                            <path d="M8.65,5.82C9.36,4.72,10.6,4,12,4c2.21,0,4,1.79,4,4c0,1.4-0.72,2.64-1.82,3.35L8.65,5.82z M20,17.17 c-0.02-1.1-0.63-2.11-1.61-2.62c-0.54-0.28-1.13-0.54-1.77-0.76L20,17.17z M21.19,21.19L2.81,2.81L1.39,4.22l8.89,8.89 c-1.81,0.23-3.39,0.79-4.67,1.45C4.61,15.07,4,16.1,4,17.22V20h13.17l2.61,2.61L21.19,21.19z" />
                        </g>
                    </svg>
                </button>
            </>
        );
    };

    useEffect(() => {
        // If chat room listeners are not available, do not continue
        if (!chatRoom.addListener) {
            return;
        }

        // Hide the sign in modal
        // setShowSignIn(false);

        const unsubscribeOnConnected = chatRoom.addListener('connect', () => {
            // Connected to the chat room.
            // renderConnect();
            console.info('Connected to chat room!');
        });

        const unsubscribeOnDisconnected = chatRoom.addListener('disconnect', (reason) => {
            // Disconnected from the chat room.
        });

        const unsubscribeOnUserDisconnect = chatRoom.addListener(
            'userDisconnect',
            (disconnectUserEvent) => {
                /* Example event payload:
                 * {
                 *   id: "AYk6xKitV4On",
                 *   userId": "R1BLTDN84zEO",
                 *   reason": "Spam",
                 *   sendTime": new Date("2022-10-11T12:56:41.113Z"),
                 *   requestId": "b379050a-2324-497b-9604-575cb5a9c5cd",
                 *   attributes": { UserId: "R1BLTDN84zEO", Reason: "Spam" }
                 * }
                 */
                renderDisconnect(disconnectUserEvent.reason);
            }
        );

        const unsubscribeOnConnecting = chatRoom.addListener('connecting', () => {
            // Connecting to the chat room.
        });

        const unsubscribeOnMessageReceived = chatRoom.addListener('message', (message) => {
            // Received a message
            const messageType = message.attributes?.message_type || 'MESSAGE';
            switch (messageType) {
                case 'REACTION':
                    props.onReactionEvent(message);
                    break;
                // case 'RAISE_HAND':
                //     handleRaiseHand(message);
                //     break;
                case 'STICKER':
                    handleSticker(message);
                    break;
                default:
                    handleMessage(message);
                    break;
            }
        });

        const unsubscribeOnEventReceived = chatRoom.addListener('event', (event) => {
            // Received an event
            handleEvent(event);
        });

        const unsubscribeOnMessageDeleted = chatRoom.addListener('messageDelete', (deleteEvent) => {
            // Received message delete event
            const messageIdToDelete = deleteEvent.messageId;
            setViewMessages((prevState) => {
                // Remove message that matches the MessageID to delete
                const newState = prevState.filter((item) => item.messageId !== messageIdToDelete);
                return newState;
            });
        });

        return () => {
            unsubscribeOnConnected();
            unsubscribeOnDisconnected();
            unsubscribeOnUserDisconnect();
            unsubscribeOnConnecting();
            unsubscribeOnMessageReceived();
            unsubscribeOnEventReceived();
            unsubscribeOnMessageDeleted();
        };
    }, [chatRoom]);

    function scrollToBottom() {
        if (publicUser) return;

        // handleOverflow();
        if (messageEl && messageEl.current) {
            messageEl.current.firstChild.scroll({
                top: messageEl.current.firstChild.scrollHeight + 100
            });
        }
    }

    // function handleOverflow() {
    //     let scrollable = document.getElementById('chat-scrollable');
    //     if (scrollable) {
    //         if (scrollable.scrollHeight > scrollable.clientHeight) {
    //             scrollable.classList.add('scrollOverflow');
    //         }
    //     }
    // }

    useEffect(() => {
        setTimeout(scrollToBottom, 500);
    }, [chatLoading]);

    //
    function scrollDown(pixels) {
        if (publicUser) return;

        if (pixels > 0) {
            if (messageEl && messageEl.current) {
                messageEl.current.firstChild.scroll({
                    top: messageEl.current.firstChild.scrollTop + pixels + 400
                });
            }
        }
    }

    const handleScroll = (e) => {
        if (publicUser) return;

        let element = e.target;
        // if (element.scrollTop <= 30 && prevScroll > 30) {
        //     let firstMessage = stateMessages[0];
        //     if (firstMessage) {
        //         props.onFetchNewMessages({
        //             timestamp: firstMessage.dateSent.getTime(),
        //             roomId: firstMessage.roomId
        //         });
        //     }
        // }
        setPrevScroll(element.scrollTop);
    };

    const shareContent = () => {
        if (navigator.share) {
            navigator
                .share({
                    url: window.location.href
                })
                .then(() => {
                    console.log('Content shared successfully');
                })
                .catch((error) => {
                    console.error('Error sharing content:', error);
                });
        } else {
            console.warn('Web Share API is not supported in this browser');
        }
    };

    const blockUser = (message) => {
        setIsMessageBlocked(false);
        props.onBlockingUser({
            user_id: message.userId,
            blocked_by: user.sub,
            userName: message.username,
            avatar: message.avatar
            //TODO there is option to set TTL , one day decide the length of the block TTL should be unix time in future
        });
        setViewMessages(viewMessages.filter((m) => m.userId !== message.userId));
    };

    const message = t('GetMostFromYourProfile', {
        company_name: `${props.company && props.company.name}`
    });

    return (
        <div className={styles.chatContainerWrapper} id={'chat-container'}>
            <div className={styles.chatContainer} onScroll={handleScroll} ref={messageEl}>
                {/* {isClicked && (
                    <EventOptions
                        {...eventOptionsProps}
                        setIsMessageBlocked={setIsMessageBlocked}
                    />
                )}

                {isMessageBlocked && (
                    <Confirm
                        successFunction={() => blockUser(eventOptionsProps.message)}
                        cancelFunction={() => setIsMessageBlocked(false)}
                        title={t('BlockUserQuestion')}
                        subtitle={t('PostFromUserWillNotShow')}
                        cancelButtonText={t('Cancel')}
                        successButtonText={t('Confirm')}
                    />
                )} */}
                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography>Comments ({viewMessages.length})</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ChatContainer
                            messages={viewMessages}
                            loading={chatLoading}
                            eventSlug={props.eventSlug}
                            eventId={props.event.id}
                            setIsClicked={setIsClicked}
                            setEventOptionsProps={setEventOptionsProps}
                            user={user}
                            publicUser={publicUser}
                            moderator={moderator}
                            renderChatLineActions={renderChatLineActions}
                        />
                    </AccordionDetails>
                </Accordion>

                {/*{!autoScroll && (*/}
                {/*    <div*/}
                {/*        style={{*/}
                {/*            position: 'absolute',*/}
                {/*            bottom: '10px',*/}
                {/*            right: '10px',*/}
                {/*            background: '#fff',*/}
                {/*            padding: '10px',*/}
                {/*            border: '1px solid #ccc',*/}
                {/*            cursor: 'pointer',*/}
                {/*        }}*/}
                {/*        onClick={handleReturnToLatest}*/}
                {/*    >*/}
                {/*        Return to Latest*/}
                {/*    </div>*/}
                {/*)}*/}
                {publicUser ? (
                    <RequestAuthForChat />
                ) : (
                    <ChatInputForm
                        sendMessage={sendMessage}
                        handleReactionSend={handleReactionSend}
                        streamStatus={props.streamStatus}
                    />
                )}
            </div>
            <Drawer anchor="bottom" open={isClicked} onClose={() => setIsClicked(false)}>
                <div>
                    <div
                        style={{
                            backgroundColor: 'white',
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            zIndex: 999,
                            borderRadius: '8px 8px 0px 0px'
                        }}>
                        <div>
                            <div
                                className={styles.button}
                                onClick={() => {
                                    setIsClicked(false);
                                    history.push(`/report/${eventOptionsProps.eventId}`);
                                }}
                                style={{
                                    display: 'flex',
                                    padding: isMobile ? '5% 2%' : '2% 1%',
                                    alignItems: 'center'
                                }}>
                                <ReportGmailerrorredIcon
                                    sx={{ width: 26, height: 26, marginRight: '1%' }}
                                />
                                <Typography sx={{ fontFamily: 'InstrumentSans-Bold' }}>
                                    {t('ReportComment')}
                                </Typography>
                            </div>
                            <div
                                style={{
                                    margin: '0 auto',
                                    width: isMobile ? '92%' : '97%',
                                    borderBottom: '1px solid #0000001A'
                                }}></div>
                            <div
                                className={styles.button}
                                onClick={() => setOpenModal(true)}
                                style={{
                                    display: 'flex',
                                    padding: isMobile ? '5% 2%' : '2% 1%',
                                    alignItems: 'center'
                                }}>
                                <BlockIcon sx={{ color: 'red', marginRight: '1%' }} />
                                <Typography
                                    sx={{ fontFamily: 'InstrumentSans-Bold' }}
                                    color={'red'}
                                    fontWeight={'bold'}>
                                    {t('BlockUser')}
                                </Typography>
                            </div>
                            <div
                                style={{
                                    margin: '0 auto',
                                    width: isMobile ? '92%' : '97%',
                                    borderBottom: '1px solid #0000001A'
                                }}></div>
                            <div
                                className={styles.button}
                                onClick={() => {
                                    handleMessageDelete(eventOptionsProps.message.messageId);
                                    setIsClicked(false);
                                }}
                                style={{
                                    display: 'flex',
                                    padding: isMobile ? '5% 2%' : '2% 1%',
                                    alignItems: 'center'
                                }}>
                                <DeleteIcon sx={{ color: 'red', marginRight: '1%' }} />
                                <Typography
                                    sx={{ fontFamily: 'InstrumentSans-Bold' }}
                                    color={'red'}
                                    fontWeight={'bold'}>
                                    {t('DeleteComment')}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </Drawer>
            <Modal open={openModal}>
                <div className={isMobile ? styles.modalMobile : styles.modal}>
                    <Typography
                        marginTop={isMobile ? '2%' : '-1%'}
                        fontSize={'18px'}
                        textAlign={'center'}>
                        {t('StopStreamHeader')}
                    </Typography>
                    <Typography className={styles.tooltip} textAlign={'center'}>
                        {t('BlockUserTooltip')}
                    </Typography>
                    <div className={styles.flexContainer}>
                        <Button
                            className={isMobile ? styles.confirmButtonMobile : styles.confirmButton}
                            onClick={() => {
                                blockUser(eventOptionsProps.message);
                                setOpenModal(false);
                                setIsClicked(false);
                            }}>
                            {t('Confirm')}
                        </Button>
                        <Button
                            className={isMobile ? styles.cancelButtonMobile : styles.cancelButton}
                            onClick={() => {
                                setOpenModal(false);
                                setIsClicked(false);
                            }}>
                            {t('Cancel')}
                        </Button>
                    </div>
                </div>
            </Modal>
            {/* <Drawer
                anchor="bottom"
                open={props.showOptions}
                onClose={() => props.setShowOptions(false)}>
                <div>
                    <div
                        style={{
                            backgroundColor: 'white',
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            zIndex: 999,
                            borderRadius: '8px 8px 0px 0px'
                        }}>
                        <div>
                            <div
                                className={styles.button}
                                onClick={() => shareContent()}
                                style={{
                                    display: 'flex',
                                    padding: isMobile ? '5% 2%' : '2% 1%',
                                    alignItems: 'center'
                                }}>
                                <IosShareIcon sx={{ width: 26, height: 26, marginRight: '1%' }} />
                                <Typography sx={{ fontFamily: 'InstrumentSans-Bold' }}>
                                    {t('ShareVideo')}
                                </Typography>
                            </div>
                            <div
                                style={{
                                    margin: '0 auto',
                                    width: isMobile ? '92%' : '97%',
                                    borderBottom: '1px solid #0000001A'
                                }}></div>
                            <div
                                className={styles.button}
                                onClick={() => {
                                    props.setShowOptions(false);
                                    history.push(`/report/${eventOptionsProps.eventId}`);
                                }}
                                style={{
                                    display: 'flex',
                                    padding: isMobile ? '5% 2%' : '2% 1%',
                                    alignItems: 'center'
                                }}>
                                <ReportGmailerrorredIcon
                                    sx={{ width: 26, height: 26, marginRight: '1%' }}
                                />
                                <Typography sx={{ fontFamily: 'InstrumentSans-Bold' }}>
                                    {t('ReportVideo')}
                                </Typography>
                            </div>
                            <div
                                style={{
                                    margin: '0 auto',
                                    width: isMobile ? '92%' : '97%',
                                    borderBottom: '1px solid #0000001A'
                                }}></div>
                            <div
                                style={{
                                    padding: isMobile ? '5% 2%' : '1.5% 1%'
                                }}>
                                <FormControlLabel
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        margin: '0'
                                    }}
                                    control={
                                        <Switch
                                            onClick={() => {
                                                props.setToggled();
                                            }}
                                            checked={props.toggled}
                                            color="primary"
                                            className={classes.switch}
                                        />
                                    }
                                    label={
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: '50vw'
                                            }}>
                                            <VolumeUpOutlinedIcon
                                                sx={{ width: 26, height: 26, marginRight: '1%' }}
                                            />
                                            <Typography sx={{ fontFamily: 'InstrumentSans-Bold' }}>
                                                {t('Sound')}
                                            </Typography>
                                        </div>
                                    }
                                    labelPlacement="start"
                                    value="schedule"
                                />
                            </div>
                            <div
                                style={{
                                    margin: '0 auto',
                                    width: isMobile ? '92%' : '97%',
                                    borderBottom: '1px solid #0000001A'
                                }}></div>
                            <div
                                style={{
                                    display: 'flex',
                                    padding: isMobile ? '5% 2%' : '2% 1%',
                                    alignItems: 'center'
                                }}>
                                <PersonalVideoIcon sx={{ marginRight: '1%' }} />
                                <Typography
                                    sx={{ fontFamily: 'InstrumentSans-Bold' }}
                                    fontWeight={'bold'}>
                                    {t('VideoQuality')}
                                </Typography>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    gap: isMobile ? '4px' : '20px',
                                    marginLeft: '1%',
                                    paddingBottom: isMobile ? '5%' : '2%'
                                }}>
                                <Button
                                    sx={{
                                        borderRadius: '40px',
                                        backgroundColor: 'black',
                                        color: 'white',
                                        textTransform: 'none',
                                        fontSize: '12px',
                                        height: '5vh',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '8px',
                                        '&:active, &:focus, &:hover': {
                                            backgroundColor: 'black',
                                            color: 'white'
                                        }
                                    }}>
                                    Automatic (720p)
                                </Button>
                                <Button
                                    sx={{
                                        borderRadius: '40px',
                                        backgroundColor: 'rgba(129, 129, 129, 0.10)',
                                        color: 'black',
                                        textTransform: 'none',
                                        fontSize: '12px',
                                        height: '5vh',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '8px',
                                        '&:active, &:focus, &:hover': {
                                            backgroundColor: 'rgba(129, 129, 129, 0.10)',
                                            color: 'black'
                                        }
                                    }}>
                                    High (1080p)
                                </Button>
                                <Button
                                    sx={{
                                        borderRadius: '40px',
                                        backgroundColor: 'rgba(129, 129, 129, 0.10)',
                                        color: 'black',
                                        textTransform: 'none',
                                        fontSize: '12px',
                                        height: '5vh',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '8px',
                                        '&:active, &:focus, &:hover': {
                                            backgroundColor: 'rgba(129, 129, 129, 0.10)',
                                            color: 'black'
                                        }
                                    }}>
                                    Medium (720p)
                                </Button>
                                <Button
                                    sx={{
                                        borderRadius: '40px',
                                        backgroundColor: 'rgba(129, 129, 129, 0.10)',
                                        color: 'black',
                                        textTransform: 'none',
                                        fontSize: '12px',
                                        height: '5vh',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '8px',
                                        '&:active, &:focus, &:hover': {
                                            backgroundColor: 'rgba(129, 129, 129, 0.10)',
                                            color: 'black'
                                        }
                                    }}>
                                    Low (360p)
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Drawer> */}
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
