import { goBack } from 'connected-react-router';
import { connect } from 'react-redux';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import exampleImage from '../../assets/images/splash.png';
import OnBack from '../Auth/OnBack';
import styles from './notifications.module.scss';
// import { INVITE_BY_SMS, SET_USER_DATA } from '../../constants/actionTypes';
// import { MAX_INVITES } from '../../constants';

// import cognitoService from '../../core/service/cognito.service';

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGoBack: () => dispatch(goBack())
});

const InviteView = (props) => {
    const { t } = useTranslation();
    console.log(props);
    // const { data: user } = props.user;
    const [notifications, setNotifications] = useState([]);
    // const [type, setType] = useState('user-group');
    useEffect(() => {
        let notification = {
            profileImage: '/images/logo.png',
            thumbnnail:
                'https://cdn.sanity.io/images/5cgn31fn/dev/c0fe3d028bcf426f0992c1f64f5ffd4272dd8c1f-224x126.png',
            text: 'BajenIdag just went live'
        };
        setNotifications([notification, notification, notification, notification, notification]);
    }, []);
    return (
        <div>
            <OnBack defaultHref={'/events'} title={t('Activity')} />
            <div className={styles.notificationsWrapper}>
                {notifications &&
                    notifications.map((notification, index) => {
                        return (
                            <div className={styles.notification} key={index}>
                                <div className={styles.profilePicture}>
                                    <img src={notification.profileImage} alt="Notification Icon" />
                                </div>
                                <div className={styles.notificationText}>
                                    <h1>
                                        {notification.text}
                                        <span>1d</span>
                                    </h1>
                                    <p>{t('LiveEventInfo')}</p>
                                </div>
                                <img
                                    src={exampleImage}
                                    alt="Example image"
                                    className={styles.notificationImage}
                                />
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteView);
