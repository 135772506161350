import {
    APP_LOAD,
    CONNECTED_ACCOUNT_LOAD_FINISHED,
    CONNECTED_PRODUCTS_LOAD_FINISHED, REMOVE_EVENT_FROM_QUEUE, SCHEDULE_CREATE_EVENT,
    SET_COMPANY_DATA,
    SET_COMPANY_STATS,
    SET_CONTROL_BAR,
    SHOW_PWA_PROMPT,
    SPINNER_VISIBLE,
    UPLOAD_PROGRESS,
    UPLOAD_REMOVE,
    UPLOAD_VIDEO_FINISHED
} from '../constants/actionTypes';
import {CONVERT_STATUS, UPLOADING_STATUS} from "../constants";

const defaultState = {
    appName: 'Fantisy',
    spinner: false,
    company: null,
    upload: [],
    connected_account: null,
    connected_products: [],
    pwaPromptFlag: false,
    controlBar: null,
    createEventQueue: []
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case APP_LOAD:
            return {
                ...state,
                appLoaded: true
            };
        case SPINNER_VISIBLE:
            return {
                ...state,
                spinner: action.payload
            };
        case SET_COMPANY_DATA:
            return {
                ...state,
                company: action.payload
            };
        case SET_COMPANY_STATS:
            return {
                ...state,
                company: { ...state.company, ...{ streamingStats: { ...action.payload } } }
            };
        case CONNECTED_ACCOUNT_LOAD_FINISHED:
            return {
                ...state,
                connected_account: action.payload
            };
        case CONNECTED_PRODUCTS_LOAD_FINISHED:
            return {
                ...state,
                connected_products: action.payload
            };
        case UPLOAD_PROGRESS:
        case UPLOAD_VIDEO_FINISHED: {
            const { url, progress, trailer, show , jobId , ...rest } = action.payload;
            let _upload = state.upload;
            if (_upload.find((x) => x.jobId == jobId)) {
                _upload = _upload.map((x) => {
                    if (x.jobId == jobId) {
                        if (trailer) {
                            return {
                                ...x,
                                trailer_url: url,
                                trailer_progress: progress,
                                trailer_show: show,
                                ...rest
                            };
                        }
                        return {
                            ...x,
                            upload_url: url,
                            upload_progress: progress,
                            upload_show: show,
                            ...rest
                        };
                    }
                    return x;
                });
            } else {
                _upload = [..._upload, action.payload];
            }

            return {
                ...state,
                upload: _upload,
                createEventQueue: state.createEventQueue.map((el) => {
                    let updateEl;
                    if (trailer) {
                        updateEl = {trailer: url}
                    } else {
                        updateEl = {processingStatus:  url ? CONVERT_STATUS : UPLOADING_STATUS, endpoint: url}
                    }
                    return el.correlation_id === jobId ? { ...el,  ...updateEl } : el
                }),
            };
        }
        case SCHEDULE_CREATE_EVENT: {
            const { payload } = action;
            return {
                ...state,
                createEventQueue: state.createEventQueue.map((el) =>
                    el.correlation_id === payload.correlation_id ? { ...el, ...payload } : el
                ).concat(state.createEventQueue.some((el) => el.correlation_id === payload.correlation_id) ? [] : [payload]),
            };
        }
        case REMOVE_EVENT_FROM_QUEUE: {
            return {
                ...state,
                createEventQueue: state.createEventQueue.filter(event => event.correlation_id !== action.payload.correlation_id),
            };
        }
        case UPLOAD_REMOVE: {
            return {
                ...state,
                upload: [
                    ...state.upload.filter((e) => {
                        action.payload.some((u) => e.jobId !== u.correlation_id);
                    })
                ],
            };
        }
        case SHOW_PWA_PROMPT:
            return {
                ...state,
                pwaPromptFlag: action.payload
            };
        case SET_CONTROL_BAR:
            return {
                ...state,
                controlBar: action.payload
            };

        default:
            return state;
    }
};
