import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { SPINNER_VISIBLE } from '../../constants/actionTypes';

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser
    };
};

const mapDispatchToProps = (dispatch) => ({
    onSpinner: (payload) => dispatch({ type: SPINNER_VISIBLE, payload })
});

const AuthRoute = ({ component: Component, step, user, onSpinner, ...rest }) => {
    const premiumOnly = process.env.REACT_APP_PREMIUM_ONLY === 'true';
    if (!user.loaded) {
        return null;
    }

    let redirect = '';
    if (user?.data?.email) {
        if (user?.groups && user?.groups.includes('Admin')) {
            if (premiumOnly) {
                redirect = '/home';
            } else {
                redirect = '/events';
            }
        } else {
            redirect = '/my-library';
        }
    }
    if (redirect) {
        // onSpinner(false);
    }

    return (
        <Route
            render={(props) =>
                redirect ? (
                    <Redirect to={{ pathname: redirect }} />
                ) : (
                    <Component {...props} {...rest} />
                )
            }
        />
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthRoute);
