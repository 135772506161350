import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    CircularProgress,
    IconButton,
    LinearProgress,
    TextField,
    Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
    CONVERT_FINISHED_STATUS,
    CONVERT_STATUS,
    CONVERTING_STATUS,
    UPLOADING_STATUS
} from '../../constants';
import { UPLOAD_REMOVE } from '../../constants/actionTypes';
import Event from '../../core/entitites/event.entity';
import sanity from '../../sanity';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TelegramIcon from '@mui/icons-material/Telegram';
import fetch from '../../fetch';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(() => ({
    root: {
        height: '100vh',
        width: '100%',
        display: 'block',
        background: 'linear-gradient(#291d42, black, black)',
        color: 'white'
    },
    headerWrapper: {
        display: 'block',
        textAlign: 'center'
    },
    textField: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'gray'
        },
        '& .MuiOutlinedInput-input::placeholder': {
            color: 'gray'
        },
        color: 'white',
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white'
        }
    },
    label: {
        '& .MuiInputLabel-root': {
            color: 'gray'
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: 'white'
        }
    }
}));

const mapStateToProps = (state) => ({
    events: state.event.events,
    isAdmin: state.user.currentUser.groups && state.user.currentUser.groups.includes('Admin'),
    user: state.user.currentUser,
    company: state.common.company,
    upload: state.common.upload
});

const mapDispatchToProps = (dispatch) => ({
    onRemoveUpload: (payload) => dispatch({ type: UPLOAD_REMOVE, payload })
});

const Success = (props) => {
    const history = useHistory();
    const { t } = useTranslation();
    const classes = useStyles();
    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            let events = await sanity.Events.GetEvents(props.isAdmin);
            const finishedEvents = events.filter(
                (event) =>
                    event.processingStatus === CONVERT_FINISHED_STATUS &&
                    props.upload.some((u) => u.jobId === event.correlation_id)
            );

            const publishingEvents = events
                .map((e) => {
                    const runtimeProps = props.upload.find(
                        (uploadedEvent) => uploadedEvent.jobId === e.correlation_id
                    );
                    return runtimeProps ? Event.new({ ...e, ...runtimeProps }) : null;
                })
                .filter((e) => e !== null);
            if (publishingEvents[0]) {
                setEvent(publishingEvents[0]);
            }
            if (finishedEvents.length > 0) {
                props.onRemoveUpload(finishedEvents);
            }
        };
        fetchData();
    }, [props.upload, props.events]);

    const handleCloseClick = () => {
        history.goBack();
    };

    const handleCancel = (eventToRemove) => {
        props.onRemoveUpload([eventToRemove]);
        history.goBack();
    };

    const sendMail = async () => {
        try {
            setLoading(true);
            await fetch.Event.EventShareMail(event.id);
        } catch (error) {
            console.log('ERROR: ', error);
        } finally {
            setLoading(false);
            history.goBack();
        }
    };

    return (
        <div>
            <div className={classes.root}>
                <div className={classes.headerWrapper}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                            variant="h6"
                            textAlign={'center'}
                            sx={{ color: 'white ', flex: 1 }}>
                            Upload Progress
                        </Typography>
                        <IconButton
                            sx={{ color: 'white', marginLeft: '-40px' }}
                            onClick={handleCloseClick}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                <div
                    style={{
                        width: isMobile ? '' : '900px',
                        padding: '0 2vw',
                        margin: '0 auto'
                    }}>
                    {event ? (
                        <>
                            <div>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                    <div
                                        style={{
                                            flex: 1,
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}>
                                        <div>
                                            <Typography color={'#888 !important'}>
                                                {event.processingStatus === UPLOADING_STATUS
                                                    ? t('Uploading')
                                                    : event.processingStatus === CONVERT_STATUS
                                                    ? t('Queued')
                                                    : event.processingStatus === CONVERTING_STATUS
                                                    ? t('Publishing')
                                                    : event.processingStatus === CONVERT_STATUS
                                                    ? `${t('Done')}!`
                                                    : event.processingStatus ===
                                                      CONVERT_FINISHED_STATUS
                                                    ? `${t('Done')}!`
                                                    : t('Unknown')}
                                            </Typography>
                                            <Typography color={'white !important'}>
                                                {event.title}
                                            </Typography>
                                        </div>
                                        {event.processingStatus === UPLOADING_STATUS && (
                                            <Button
                                                onClick={() => handleCancel(event)}
                                                sx={{
                                                    border: 'none',
                                                    color: 'white !important',
                                                    textTransform: 'none !important'
                                                }}>
                                                {t('Cancel')}
                                            </Button>
                                        )}
                                    </div>
                                </div>
                                <LinearProgress
                                    // size={160}
                                    variant="determinate"
                                    value={event.processing_progress}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '2vh',
                                    flexDirection: 'column'
                                }}>
                                {event.processingStatus === CONVERT_FINISHED_STATUS ? (
                                    <>
                                        <TextField
                                            className={`${classes.textField} ${classes.label}`}
                                            InputProps={{
                                                style: {
                                                    color: 'white'
                                                },
                                                readOnly: true
                                            }}
                                            value={`${window.location.origin}/events/${event.slug}`}
                                            fullWidth></TextField>
                                        <div style={{ marginTop: '1vh' }}>
                                            <Typography textAlign={'center'}>
                                                Here are some of the social media sites where you
                                                can share your new video!
                                            </Typography>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    marginTop: '1vh',
                                                    gap: '1rem'
                                                }}>
                                                <FacebookIcon />
                                                <InstagramIcon />
                                                <XIcon />
                                                <LinkedInIcon />
                                                <TelegramIcon />
                                            </div>
                                        </div>
                                    </>
                                ) : loading ? (
                                    <div>
                                        <CircularProgress />
                                    </div>
                                ) : (
                                    <Button
                                        sx={{
                                            textTransform: 'none !important',
                                            width: isMobile ? '40vw' : '20vw'
                                        }}
                                        variant="contained"
                                        onClick={() => sendMail()}>
                                        Mail me
                                    </Button>
                                )}
                            </div>
                        </>
                    ) : (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '2vh',
                                flexDirection: 'column'
                            }}>
                            <CircularProgress />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Success);
