import { Route } from 'react-router';
import { Switch } from 'react-router-dom/cjs/react-router-dom.min';
import Auth from './Auth/Auth';
import AuthRoute from './Auth/AuthRoute';
import EnterCodeStep from './Auth/EnterCodeStep';
import EnterNameStep from './Auth/EnterNameStep';
import EnterPhotoStep from './Auth/EnterPhotoStep';
import LoginPage from './Auth/LoginPage';
import PermissionsStep from './Auth/PermissionsStep';
import RegisterPage from './Auth/RegisterPage';
import Welcome from './Auth/Welcome';
import BlogPost from './BlogPost/BlogPost';
import BlogPosts from './BlogPost/BlogPostItem';
import CreateVideoPage from './CreateVideo/CreateVideoPage';
import CreateVideoPageV2 from './CreateVideo/CreateVideoPageV2';
import Home from './CreateVideo/Home';
import StreamDetails from './CreateVideo/StreamDetails';
import Success from './CreateVideo/Success';
import EditVideo from './Event/EditVideo';
import Event from './Event/Event';
import EventList from './Event/EventList';
import EventV2 from './Event/EventV2';
import ModifyEvent from './Event/Modify/ModifyEvent';
import ModifyEventV2 from './Event/Modify/ModifyEventV2';
import ReportList from './Event/ReportList';
import CookiePolicy from './General/cookiePolicy';
import Privacy from './General/privacy';
import TermsAndConditions from './General/termsAndConditions';
import InvoicesPage from './Invoices/InvoicesPage';
import AdminRoute from './Private/AdminRoute';
import PrivateRoute from './Private/PrivateRoute';
import Account from './Profile/Account';
import DeleteAccount from './Profile/Account/DeleteAccount';
import BlockedUsers from './Profile/BlockedUsers';
import AppIconPage from './Profile/Customize/AppIconPage';
import ColorsPage from './Profile/Customize/ColorsPage';
import CustomizePage from './Profile/Customize/CustomizePage';
import CustomUrlPage from './Profile/Customize/CustomUrlPage';
import LogotypePage from './Profile/Customize/LogotypePage';
import EventPlan from './Profile/EventPlan';
import Followers from './Profile/Followers';
import GoLive from './Profile/GoLive/GoLive';
import Invite from './Profile/Invite';
import MyLibrary from './Profile/MyLibrary';
import MyVideos from './Profile/MyVideos';
import Notifications from './Profile/Notifications';
import Preferences from './Profile/Preferences';
import Products from './Profile/Products';
import Balance from './Profile/ProfileDesign/Balance';
import CancelPayment from './Profile/ProfileDesign/CancelPayment';
import ManageSubscriptions from './Profile/ProfileDesign/ManageSubscriptions';
import Profile from './Profile/ProfileDesign/Profile';
import SuccessPayment from './Profile/ProfileDesign/SuccessPayment';
import Transactions from './Profile/ProfileDesign/Transactions';
import UserSubscriptions from './Profile/ProfileDesign/UserSubscriptions';
import Secrecy from './Profile/Secrecy';
import ProfileSettings from './Profile/Settings';
import Streaming from './Profile/Streaming';
import LiveStreaming from './Profile/Streaming/LiveStreaming';
import Search from './Search/Search';

const premiumOnly = process.env.REACT_APP_PREMIUM_ONLY === 'true';

const Routes = () => {
    return (
        <Switch>
            <AuthRoute exact path="/login" component={LoginPage} />
            <PrivateRoute path="/home" component={Home} />

            <PrivateRoute path="/my-library" component={MyLibrary} />
            <Route path="/edit/:id" component={EditVideo} />
            <Route path="/privacyPolicy" component={Privacy} />
            <Route path="/termsAndConditions" component={TermsAndConditions} />
            <Route path="/cookiePolicy" component={CookiePolicy} />
            <PrivateRoute
                exact
                path="/events/:slug/modify"
                premiumOnly={premiumOnly}
                premiumComponent={ModifyEventV2}
                component={ModifyEvent}
            />
            <Route
                exact
                path="/events/:slug"
                render={(props) => (premiumOnly ? <EventV2 {...props} /> : <Event {...props} />)}
            />
            <Route exact path="/events" component={EventList} />
            <Route exact path="/search" component={Search} />

            <Route path="/welcome/:token" component={Welcome} />
            {/* <AuthRoute path="/login" component={Login} />
            <AuthRoute path="/register" component={Register} /> */}
            <AuthRoute path="/register" component={RegisterPage} />
            {/* <AuthRoute path="/login" component={LoginPage} /> */}
            <AuthRoute path="/confirmation" step={true} component={EnterCodeStep} />
            <AuthRoute path="/user-info" step={true} component={EnterNameStep} />
            <AuthRoute path="/upload-image" step={true} component={EnterPhotoStep} />
            <AuthRoute path="/permissions" step={true} component={PermissionsStep} />

            <AdminRoute path="/profile/streaming/live" component={LiveStreaming} />
            <AdminRoute path="/profile/streaming" component={Streaming} />

            <PrivateRoute path="/profile/settings/secrecy/blocked-users" component={BlockedUsers} />
            <Route exact path="/create-video/success/:slug" component={Success} />
            <PrivateRoute exact path="/create-video/stream-details" component={StreamDetails} />
            <PrivateRoute
                exact
                path="/create-video"
                premiumOnly={premiumOnly}
                premiumComponent={CreateVideoPageV2}
                component={CreateVideoPage}
            />

            <PrivateRoute path="/go-live" component={GoLive} />
            <PrivateRoute path="/profile/invoices" component={InvoicesPage} />
            <PrivateRoute path="/profile/settings/preferences" component={Preferences} />
            <PrivateRoute path="/profile/settings/account/deletion" component={DeleteAccount} />
            <PrivateRoute path="/profile/settings/products" component={Products} />
            <PrivateRoute exact path="/profile/account" component={Account} />
            <PrivateRoute exact path="/profile/settings/secrecy" component={Secrecy} />
            <PrivateRoute path="/profile/eventplan" component={EventPlan} />
            <PrivateRoute exact path="/profile/settings" component={ProfileSettings} />
            <PrivateRoute
                path={premiumOnly ? '/profile/branding/customurl' : '/profile/customize/customurl'}
                component={CustomUrlPage}
            />
            <PrivateRoute
                path={premiumOnly ? '/profile/branding/colors' : '/profile/customize/colors'}
                component={ColorsPage}
            />
            <PrivateRoute
                path={premiumOnly ? '/profile/branding/logotype' : '/profile/customize/logotype'}
                component={LogotypePage}
            />
            <PrivateRoute
                path={premiumOnly ? '/profile/branding/appicon' : '/profile/customize/appicon'}
                component={AppIconPage}
            />
            <PrivateRoute
                exact
                path={premiumOnly ? '/profile/branding' : '/profile/customize'}
                component={CustomizePage}
            />
            <PrivateRoute path="/profile/myvideos/followers" component={Followers} />
            <PrivateRoute exact path="/profile/myvideos" component={MyVideos} />
            <PrivateRoute
                exact
                path="/profile/manage-subscriptions"
                component={ManageSubscriptions}
            />
            <PrivateRoute exact path="/profile/balance" component={Balance} />
            <PrivateRoute exact path="/profile/transactions" component={Transactions} />
            <PrivateRoute exact path="/profile/user-subscriptions" component={UserSubscriptions} />
            <PrivateRoute path="/profile/success-payment" component={SuccessPayment} />
            <PrivateRoute path="/profile/cancel-payment" component={CancelPayment} />
            {/* <PrivateRoute exact path="/profile" component={ProfilePage} /> */}
            <Route path="/profile" component={Profile} />
            <PrivateRoute path="/invite" component={Invite} />
            <PrivateRoute path="/notifications" component={Notifications} />
            <PrivateRoute path="/report/:slug" component={ReportList} />
            <PrivateRoute path="/blog/:slug" component={BlogPost} />
            <PrivateRoute path="/blog/" component={BlogPosts} />
            <AuthRoute component={LoginPage} />
        </Switch>
    );
};

export default Routes;
