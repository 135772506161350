import React, { useEffect, useRef, useState } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import OnBack from '../Auth/OnBack';
import Flashphoner from '@flashphoner/websdk';
import styles from './streaming.module.scss';
import cameraWhite from '../../assets/images/camera-white.svg';
import actions from '../../assets/images/actions-white.svg';
import camera from '../../assets/images/camera.svg';
import eye from '../../assets/images/eye.svg';
import rotate from '../../assets/images/rotate-icon.svg';
// import share from '../../assets/images/share.svg';
import mic from '../../assets/images/unmute-mic.svg';
import lock from '../../assets/images/lock.svg';
import chaticon from '../../assets/images/chaticon.svg';
import StreamingChat from './StreamingChat';
import { CLEAR_EVENT_STREAMING, CREATE_EVENT, STOP_CHANNEL } from '../../constants/actionTypes';
import { Browser } from '@flashphoner/websdk/src/util';
import preloader from '../../assets/media/preloader.mp4';
import backArrow from '../../assets/images/arrow-minimize.svg';
import { useTranslation } from 'react-i18next';
import { EventTypes } from './Streaming/Helpers/eventTypes';

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser,
        eventStreamed: state.event.eventStreamed,
        company: state.common.company
    };
};

const mapDispatchToProps = (dispatch) => ({
    onNewEvent: (payload) => dispatch({ type: CREATE_EVENT, payload }),
    onStopEvent: (payload) => dispatch({ type: STOP_CHANNEL, payload }),
    onClearEvent: (payload) => dispatch({ type: CLEAR_EVENT_STREAMING, payload })
});

const Streaming = (props) => {
    const { t } = useTranslation();
    const eventTypes = EventTypes();
    const SESSION_STATUS = Flashphoner.constants.SESSION_STATUS;
    const STREAM_STATUS = Flashphoner.constants.STREAM_STATUS;
    const MEDIA_DEVICE_KIND = Flashphoner.constants.MEDIA_DEVICE_KIND;

    const [streamStatus, setStreamStatus] = useState('');
    const [stream, setStream] = useState(null);
    const [deviceId, setDeviceId] = useState(null);
    // const [audioId, setAudioId] = useState(null);
    const [title, setTitle] = useState('');
    const [eventType, setEventType] = useState('video_event');
    // TODO: StreamState and device orientation need to be connected, it is used for CSS conditionals:
    // start, not-ready, started
    const [streamState, setStreamState] = useState('start');
    const [audioStatus, setAudioStatus] = useState('');

    const [streamingEvent, setStreamingEvent] = useState(null);
    const [streamingEventNotReady, setStreamingEventNotReady] = useState(null);

    const [deviceOrientation, setDeviceOrientation] = useState('portrait-primary');
    const [optionsOpen, setOptionsOpen] = useState(false);
    const [actionActive, setActionsActive] = useState(true);
    const [chatOpen, setChatOpen] = useState(false);

    const videoRef = useRef();
    const [websocketURL, setWebsocketURL] = useState(null);
    const [streamName, setStreamName] = useState(null);
    const [rtmpUrl, setRtmpUrl] = useState(null);

    useEffect(() => {
        if (streamingEvent && streamState === 'start') {
            setStreamState('not-ready');
        }
        if (streamingEvent) {
            let { web_socket_connection_field, mediaLivePushEndpoint } = streamingEvent;

            if (web_socket_connection_field) {
                setWebsocketURL(web_socket_connection_field);
            }

            if (mediaLivePushEndpoint) {
                const rtmpArr = mediaLivePushEndpoint.split('/');
                setStreamName(rtmpArr[rtmpArr.length - 1]);
                setRtmpUrl(rtmpArr.filter((_, i) => i != rtmpArr.length - 1).join('/'));
            }
        }
    }, [streamingEvent]);

    useEffect(() => {
        const event = props.eventStreamed;
        if (event) {
            console.log('we here', event);
            console.log('!!event', !!event);
            console.log('web_socket_connection_field', !!event.web_socket_connection_field);
            console.log('!!event.mediaLivePushEndpoint', !!event.mediaLivePushEndpoint);
            if (
                !!event.web_socket_connection_field &&
                !!event.mediaLivePushEndpoint &&
                event.streamStatus === 'active' &&
                event.channel_status !== 'STOPPING' &&
                event.channel_status !== 'STOPPED' &&
                event.channel_status !== 'DELETED'
            ) {
                setStreamingEvent(event);
            }
            if (
                event.streamStatus === 'upcoming' &&
                event.channel_status !== 'STOPPED' &&
                event.channel_status !== 'DELETED'
            ) {
                setStreamingEventNotReady(event);
            }
        }
    }, [props.eventStreamed]);
    useEffect(() => {
        if (streamingEventNotReady !== null) {
            setStreamState('not-ready');
        }
    }, [streamingEventNotReady]);

    useEffect(() => {
        let screen = window.screen;
        if (screen.orientation) {
            if (deviceOrientation !== screen.orientation.type) {
                setDeviceOrientation(screen.orientation.type);
            }
        } else {
            if (window.orientation === -90 || window.orientation === 90) {
                setDeviceOrientation('landscape-primary');
            }
        }
        window.addEventListener('orientationchange', function () {
            if (screen.orientation) {
                setDeviceOrientation(screen.orientation.type);
            } else {
                if (window.orientation === -90 || window.orientation === 90) {
                    setDeviceOrientation('landscape-primary');
                } else {
                    setDeviceOrientation('portrait-primary');
                }
            }
        });
        return () => {};
    }, []);

    useEffect(() => {
        Flashphoner.init({});
        if (Flashphoner) {
            console.log('flashphoner ', Flashphoner);
            console.log('flashphoner media providers', Flashphoner.getMediaProviders());
        }
        cameraSwitch();
        if (streamState !== 'start') {
            document.getElementsByTagName('body')[0].style.background = 'white';
            document.getElementsByTagName('html')[0].style.background = 'white';
        } else {
            document.getElementsByTagName('body')[0].style.background = 'transparent';
            document.getElementsByTagName('html')[0].style.background = 'transparent';
        }
        return () => {
            if (screen && screen.orientation) {
                screen.orientation.unlock();
            }
        };
    }, []);

    useEffect(() => {
        const current = videoRef.current;
        return () => {
            const relesead = Flashphoner.releaseLocalMedia(current);
            console.log('MediaRelesead::', relesead);
        };
    }, [videoRef]);

    useEffect(() => {
        if (streamState !== 'start') {
            document.getElementsByTagName('body')[0].style.background = 'transparent';
            document.getElementsByTagName('html')[0].style.background = 'transparent';
        } else {
            document.getElementsByTagName('body')[0].style.background = 'white';
            document.getElementsByTagName('html')[0].style.background = 'white';
        }
    }, [streamState]);

    const cameraSwitch = async () => {
        try {
            if (stream) {
                await stream.switchCam();
                return;
            }

            const devices = await Flashphoner.getMediaDevices(null, true, MEDIA_DEVICE_KIND.INPUT, {
                video: true,
                audio: true
            });

            // console.log('devices::', devices);

            if (!devices || !devices.video || !devices.video.length) {
                return;
            }

            const video = devices.video;

            // I guess this is the back camera for all
            // android phones
            // TODO: need to be tested
            let dId;
            if (!deviceId) {
                dId = video[0].id;
                if (
                    Browser.isSafariWebRTC() &&
                    Browser.isiOS() &&
                    Flashphoner.getMediaProviders()[0] === 'WebRTC'
                ) {
                    dId = { exact: video[0].id };
                }
            } else {
                const currentIndex = video.findIndex(
                    (v) => v.id === deviceId || v.id === deviceId.exact
                );

                if (currentIndex === video.length - 1) {
                    dId = video[0].id;
                    if (
                        Browser.isSafariWebRTC() &&
                        Browser.isiOS() &&
                        Flashphoner.getMediaProviders()[0] === 'WebRTC'
                    ) {
                        dId = { exact: video[0].id };
                    }
                } else {
                    dId = video[video.length - 1].id;
                    if (
                        Browser.isSafariWebRTC() &&
                        Browser.isiOS() &&
                        Flashphoner.getMediaProviders()[0] === 'WebRTC'
                    ) {
                        dId = { exact: video[video.length - 1].id };
                    }
                }
            }

            setDeviceId(dId);

            // const audio = devices.audio;
            // const aId = audio[1].id;
            // setAudioId(aId);

            // console.log(aId);

            // start camera and audio
            await Flashphoner.getMediaAccess(
                {
                    audio: true,
                    video: {
                        deviceId: dId,
                        width: 1280,
                        height: 720
                    }
                },
                videoRef.current
            );
            const localVideoDivElement = document.querySelector(`#localVideo`);
            const videoElement = localVideoDivElement.children[0];
            videoElement.setAttribute('playsinline', '');
        } catch (error) {
            console.log(error, 'Failed to get media devices');
        }
    };

    const publishBtnClick = () => {
        start();
    };

    const toggleOverlay = () => {
        setOptionsOpen(!optionsOpen);
    };

    const toggleActions = () => {
        setActionsActive(!actionActive);
    };
    const toggleChat = () => {
        setChatOpen(!chatOpen);
    };

    const start = () => {
        if (!websocketURL || deviceOrientation === 'portrait-primary') {
            return;
        }
        if (screen.orientation) screen.orientation.lock('landscape');
        //check if we already have session
        if (Flashphoner.getSessions().length > 0) {
            if (Browser.isSafariWebRTC()) {
                Flashphoner.playFirstVideo(videoRef.current, false, preloader).then(() => {
                    startStreaming(Flashphoner.getSessions()[0]);
                    setStreamState('started');
                });
                return;
            }
            startStreaming(Flashphoner.getSessions()[0]);
            setStreamState('started');
        } else {
            //create session
            Flashphoner.createSession({ urlServer: websocketURL })
                .on(SESSION_STATUS.ESTABLISHED, (session) => {
                    setStreamStatus(SESSION_STATUS.ESTABLISHED);

                    if (Browser.isSafariWebRTC()) {
                        Flashphoner.playFirstVideo(videoRef.current, false, preloader).then(() => {
                            startStreaming(session);
                            setStreamState('started');
                        });
                        return;
                    }
                    startStreaming(session);
                    setStreamState('started');
                })
                .on(SESSION_STATUS.DISCONNECTED, () => {
                    setStreamStatus(SESSION_STATUS.DISCONNECTED);
                    setStreamState('not-ready');
                })
                .on(SESSION_STATUS.FAILED, () => {
                    setStreamStatus(SESSION_STATUS.FAILED);
                    setStreamState('not-ready');
                });
        }
    };

    const startStreaming = (session) => {
        if (!streamName || !rtmpUrl) {
            return;
        }
        const s = session
            .createStream({
                name: streamName,
                display: videoRef.current,
                cacheLocalResources: true,
                receiveVideo: false,
                receiveAudio: false,
                rtmpUrl: rtmpUrl,
                disableConstraintsNormalization: true,
                constraints: {
                    audio: {
                        echoCancellation: false,
                        googEchoCancellation: false,
                        // deviceId: audioId,
                        // bitrate: 64000,
                        // stereo: true
                        noiseSuppression: false,
                        // autoGainControl: false
                        googNoiseSuppression: false
                        // latency: 0
                        // googAutoGainControl: false,
                    },
                    video: {
                        width: 1280,
                        height: 720,
                        deviceId
                    }
                },
                transport: 'TCP'
            })
            .on(STREAM_STATUS.PUBLISHING, () => {
                setStreamStatus(STREAM_STATUS.PUBLISHING);
                setStreamState('started');
                // this shit should be tested
                // it has good sound on mobile phone mic with 10000
                // but there is a distorsion with external mics
                // GOOD LUCK!!!!
                // s.setMicrophoneGain(10000);
                s.setMicrophoneGain(2000);
            })
            .on(STREAM_STATUS.UNPUBLISHED, () => {
                setStreamStatus(STREAM_STATUS.UNPUBLISHED);
                setStreamState('not-ready');
            })
            .on(STREAM_STATUS.FAILED, () => {
                setStreamStatus(STREAM_STATUS.FAILED);
                setStreamState('not-ready');
            });

        s.publish();
        // s.setMicrophoneGain(100);
        setStream(s);
    };

    const stop = () => {
        Flashphoner.releaseLocalMedia(videoRef.current);
        if (screen && screen.orientation) {
            screen.orientation.unlock();
        }
        setStreamState('start');
        if (stream) {
            stream.stop();
        }

        if (streamingEvent && streamingEvent.channel_id) {
            // console.log('STOPPING::', streamingEvent.channel_id);
            props.onStopEvent({ ChannelId: streamingEvent.channel_id });
        }
        props.onClearEvent(props.eventStreamed);
        // onBack();
    };

    const muteAudio = () => {
        if (stream) {
            if (!audioStatus) {
                stream.muteAudio();
                setAudioStatus('muted');
            } else {
                stream.unmuteAudio();
                setAudioStatus('');
            }
        }
    };

    // const onBack = () => {
    //     Flashphoner.releaseLocalMedia(videoRef.current);
    //     setTimeout(() => {
    //         props.onClearEvent(props.eventStreamed);
    //         props.onRedirect('/profile');
    //     }, 10);
    // };

    const createEvent = async () => {
        let eventTitle = title;
        const eType = eventTypes.find((et) => et.value === eventType);
        if (!title) {
            eventTitle = `${props.user.data.given_name} ${props.user.data.family_name} - ${eType.title}`;
        }

        if (!eventType) {
            return;
        }
        props.onNewEvent({
            title: eventTitle,
            slug: {
                type: 'slug',
                current: `${Date.now()}`
            },
            startDate: new Date(),
            event_type: eventType,
            stream_type: 'webrtc',
            stream_status: 'upcoming',
            user_id: props.user.data.sub,
            tags: [eType.title, ...getAllCheckedTags()],
            sports_team: {
                _ref: process.env.REACT_APP_COMPANY_ID,
                _type: 'reference'
            }
        });
        setStreamState('not-ready');

        if (Browser.isSafariWebRTC()) {
            Flashphoner.releaseLocalMedia(videoRef.current);
            await Flashphoner.getMediaAccess(
                {
                    audio: true,
                    video: {
                        deviceId,
                        width: 1280,
                        height: 720
                    }
                },
                videoRef.current
            );
        }
    };

    const [initialTags, setInitialTags] = useState([]);

    const removeTag = (e, index) => {
        e.preventDefault();
        if (index != undefined) {
            let newList = [...initialTags];
            newList[index].tagName = e.target.value;
            setInitialTags(newList);
        }

        var key = e.keyCode || e.charCode;
        if (key == 8 || key == 46) {
            if (e.target.value.length === 0) {
                const updatedList = [...initialTags];
                setInitialTags([]);
                setTimeout(() => {
                    updatedList.splice(index, 1);
                    setInitialTags(updatedList);
                });
            }
        }
    };

    const addNewTag = (name, status) => {
        if (name != '') {
            setInitialTags([...initialTags, { tagName: name, tagStatus: status }]);
        }
        let tmp = document.getElementById('temporaryInput');
        tmp.remove();
    };

    const checkTagStatus = (e, index) => {
        e.preventDefault();

        let element = e.target;
        if (index === undefined) {
            addNewTag(e.target.value, true);
            index = initialTags.length - 1;
        } else {
            initialTags[index].tagStatus = !initialTags[index].tagStatus;
        }
        if (initialTags[index]) {
            if (initialTags[index].tagStatus) {
                element.style.backgroundColor = `${
                    props.company ? props.company.primaryColor : `gray`
                }`;
                element.style.color = 'white';
            } else {
                element.style.backgroundColor = '#e5e5e5';
                element.style.color = 'gray';
            }
        }
    };

    const getAllCheckedTags = () => {
        var tags = initialTags.filter((x) => x['tagStatus'] === true);
        let tagNames = [];
        tags.forEach((element) => {
            tagNames.push(element.tagName);
        });

        return tagNames;
    };

    const goBack = () => {
        setStreamState('start');
    };

    return (
        <div>
            {(streamState === 'start' || streamState === 'not-ready') && (
                <>
                    <OnBack defaultHref={'/profile'} title={t('StartStream')} />
                    <img
                        src={camera}
                        className={styles.cameraIcon}
                        onClick={cameraSwitch}
                        alt="Camera"
                    />
                </>
            )}
            <div
                className={`${styles.streamingWrapper}
            ${streamState === 'not-ready' ? styles.streamSet : ''}
            ${streamState === 'started' ? styles.streamStarted : ''}
            ${streamState === 'start' ? styles.streamStart : ''}
        `}>
                <div
                    ref={videoRef}
                    id="localVideo"
                    className={styles.cameraHolder}
                    style={{ overflow: 'hidden' }}
                />

                {streamState === 'start' && (
                    <React.Fragment>
                        <div className={styles.userInputs}>
                            <input
                                type="text"
                                placeholder={t('DescribeStream')}
                                onChange={(e) => setTitle(e.target.value)}
                            />

                            <select onChange={(e) => setEventType(e.target.value)}>
                                {eventTypes.map((t) => {
                                    return (
                                        <option key={t.value} value={t.value}>
                                            {t.title}
                                        </option>
                                    );
                                })}
                            </select>

                            <h3>{t('EventTags')}</h3>
                            <div className={styles.tagsContainer} id="tags">
                                {initialTags.map((element, index) => (
                                    <input
                                        key={index}
                                        type="text"
                                        defaultValue={element.tagName}
                                        name={element.tagName}
                                        style={
                                            element.tagStatus
                                                ? {
                                                      background: props.company
                                                          ? props.company.primaryColor
                                                          : 'gray',
                                                      color: 'white',
                                                      borderRadius: '20px',
                                                      border: 'none',
                                                      textAlign: 'center'
                                                  }
                                                : {
                                                      background: '#e5e5e5',
                                                      color: 'gray',
                                                      borderRadius: '20px',
                                                      border: 'none',
                                                      textAlign: 'center'
                                                  }
                                        }
                                        // onKeyUp={(e) => handleChangeTag(e, element.id)}
                                        onKeyUp={(e) => removeTag(e, index)}
                                        onClick={(e) => checkTagStatus(e, index)}
                                    />
                                ))}
                            </div>
                            <div>
                                <input
                                    type="button"
                                    value={`+ ${t('AddTag')}`}
                                    style={{
                                        background: '#e5e5e5',
                                        color: 'gray',
                                        borderRadius: '20px',
                                        border: 'none',
                                        textAlign: 'center'
                                    }}
                                    onClick={() => {
                                        var element = document.createElement('input');
                                        element.setAttribute('id', 'temporaryInput');

                                        element.addEventListener('keyup', (e) => removeTag(e));
                                        element.addEventListener('blur', (e) => checkTagStatus(e));

                                        element.style.background = `${
                                            props.company ? props.company.primaryColor : `gray`
                                        }`;
                                        element.style.color = 'white';
                                        element.style.borderRadius = '20px';
                                        element.style.border = 'none';
                                        element.style.textAlign = 'center';

                                        element.setAttribute('autofocus', '');

                                        var divTags = document.getElementById('tags');
                                        divTags.appendChild(element);
                                    }}
                                />
                            </div>
                        </div>
                        {/* <button onClick={getAllCheckedTags}>test tags</button> */}
                        <div className={styles.streamButtonWrapper}>
                            <button className={'btn-primary'} onClick={createEvent}>
                                {t('StartStream')}
                            </button>
                        </div>
                    </React.Fragment>
                )}
                {streamState === 'not-ready' && deviceOrientation === 'portrait-primary' && (
                    <React.Fragment>
                        <img
                            src={backArrow}
                            className={styles.backStreamOption}
                            onClick={goBack}
                            alt="Back Arrow"
                        />
                        <img
                            src={cameraWhite}
                            className={styles.cameraIconWhite}
                            onClick={cameraSwitch}
                            alt="Camera White Icon"
                        />
                        <div className={styles.streamRotate}>
                            <img src={rotate} alt={t('RotatePhone')} />
                            <p>{t('RotatePhoneToStartStream')}</p>
                        </div>
                    </React.Fragment>
                )}
                {streamState === 'not-ready' && deviceOrientation !== 'portrait-primary' && (
                    <React.Fragment>
                        <img
                            src={backArrow}
                            className={styles.backStreamOption}
                            onClick={goBack}
                            alt="Back Arrow"
                        />
                        <img
                            src={cameraWhite}
                            className={styles.cameraIconWhite}
                            onClick={cameraSwitch}
                            alt="Camera Switch"
                        />
                        <input
                            type="button"
                            onClick={publishBtnClick}
                            value={t('GoLiveNow')}
                            className={`${styles.streamHelper}
                        ${websocketURL && rtmpUrl ? styles.streamReady : ''}`}
                            disabled={!websocketURL || !rtmpUrl}
                        />
                    </React.Fragment>
                )}
                {streamState === 'started' && (
                    <div
                        className={`${styles.streamDetails}
                    ${!actionActive ? styles.streamDisabled : styles.streamEnabled}`}>
                        <header>
                            <input type="button" onClick={stop} value="Stop" />
                            {/*TODO: Set conditions based on 3 types of statuses: good, warning, bad*/}
                            {/*Classes have the same names: good, warning, bad*/}
                            <p
                                className={
                                    streamStatus === SESSION_STATUS.ESTABLISHED
                                        ? styles.warning
                                        : streamStatus === STREAM_STATUS.PUBLISHING
                                        ? styles.good
                                        : styles.bad
                                }>
                                <span></span>
                                {streamStatus}
                            </p>

                            <p>{audioStatus}</p>

                            {!Browser.isSafariWebRTC() && !chatOpen && (
                                <img
                                    src={cameraWhite}
                                    className={styles.cameraIconWhite}
                                    onClick={cameraSwitch}
                                    alt={t('CameraSwitch')}
                                />
                            )}
                        </header>
                        <footer>
                            <div className={styles.liveDetails}>
                                <span>{t('Live')}</span>
                                <span className={styles.liveCounter}>
                                    <img src={eye} alt={t('ViewCount')} />
                                    12.4k
                                </span>
                            </div>
                            {!chatOpen && (
                                <button onClick={toggleChat} className={styles.chatIcon}>
                                    <img src={chaticon} alt={t('Chat')} />
                                </button>
                            )}
                            {!chatOpen && (
                                <button onClick={toggleOverlay}>
                                    <img src={actions} alt={t('Actions')} />
                                </button>
                            )}
                        </footer>
                        {chatOpen && (
                            <StreamingChat
                                streamingEvent={streamingEvent}
                                setChatOpen={setChatOpen}
                            />
                        )}
                    </div>
                )}
                {optionsOpen && streamState === 'started' && (
                    <div className={styles.optionsOverlay} onClick={toggleOverlay}>
                        <div className={styles.optionsGroup}>
                            {/*<button className={styles.option}>
                            <img src={share} alt="Share" />
                            <span>Dela sändning</span>
                        </button>*/}
                            <button className={styles.option} onClick={muteAudio}>
                                <img src={mic} alt={t('Mic')} />
                                <span>{t('TurnOffMic')}</span>
                            </button>
                            <button className={styles.option} onClick={toggleActions}>
                                <img src={lock} alt={t('Lock')} />
                                {actionActive ? (
                                    <span>{t('LockScreen')}</span>
                                ) : (
                                    <span>{t('UnlockScreen')}</span>
                                )}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Streaming);
