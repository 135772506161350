import AddIcon from '@mui/icons-material/Add';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import CancelIcon from '@mui/icons-material/Cancel';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Resizer from 'react-image-file-resizer';
import { connect } from 'react-redux';
import photo from '../../assets/images/homeImage.png';
import { SET_USER_DATA, UPLOAD_VIDEO } from '../../constants/actionTypes';
import colors from '../../utils/colors';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import styles from './createVideo.module.scss';
import toast from 'react-hot-toast';
import { useDropzone } from 'react-dropzone';
import { isMobile } from 'react-device-detect';

const mapStateToProps = (state) => {
    return {
        ...state.liveStreaming,
        user: state.user.currentUser,
        upload: state.common.upload
    };
};
const mapDispatchToProps = (dispatch) => ({
    onUploadVideo: (payload) => dispatch({ type: UPLOAD_VIDEO, payload }),
    onSetUserData: (payload) => dispatch({ type: SET_USER_DATA, payload })
});

const UploadContent = (props) => {
    const { t } = useTranslation();
    const fileInputRef = useRef(null);
    const [trailerFile, setTrailerFile] = useState(null);
    const [videoFileType, setVideoFileType] = useState(null);
    const [trailerFileType, setTrailerFileType] = useState(null);
    const [endDateFieldEnabled, setEndDateFieldEnabled] = useState(false);
    const upload = props.upload.find((x) => x.jobId === props.jobId);
    const [imagePreview, setImagePreview] = useState(null);
    const trailerInputRef = useRef(null);
    const [titlePlaceholder, setTitlePlaceholder] = useState(t('TitlePlaceholder'));
    const [descriptionPlaceholder, setDescriptionPlaceholder] = useState(
        t('DescriptionPlaceholder')
    );

    const onDrop = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            handleVideoUpload({ target: { files: [file] } }, 'video');
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: { 'video/*': [] }, // Updated format
        multiple: false
    });

    const calculateMinimumPrice = (video) => {
        const size = video.size / (1024 * 1024);
        if (size <= 100) {
            return 2;
        } else if (size <= 500) {
            return 8;
        } else if (size <= 1000) {
            return 15;
        } else {
            return 25;
        }
    };

    const getVideoDuration = async (video) => {
        props.setVideoDuration(null);
        if (!video?.type?.includes('video/')) {
            setEndDateFieldEnabled(true);
        } else if (endDateFieldEnabled) {
            setEndDateFieldEnabled(false);
        }
        try {
            let _fileReader = new FileReader();
            _fileReader.onload = function (e) {
                let blob = new Blob([e.target.result], {
                        type: video.type
                    }),
                    // eslint-disable-next-line no-undef
                    url = (URL || webkitURL).createObjectURL(blob),
                    videoDom = document.createElement('video');
                videoDom.preload = 'metadata';
                videoDom.addEventListener('loadedmetadata', function () {
                    props.setVideoDuration(videoDom.duration);
                    const isPortrait = videoDom.videoHeight > videoDom.videoWidth;
                    props.setVideoOrientation(isPortrait ? 'portrait' : 'landscape');
                    // eslint-disable-next-line no-undef
                    (URL || webkitURL).revokeObjectURL(url);
                });

                videoDom.src = url;
            };
            _fileReader.readAsArrayBuffer(video);
        } catch (e) {
            console.warn({ e });
            setEndDateFieldEnabled(true);
            return false;
        }
    };

    const handleVideoUpload = async (event, type) => {
        props.setFileUploadError('');
        const allowedVideoExtensions = ['mp4', 'mov', 'avi', 'mkv', 'flv', 'wmv', 'webm'];

        const file = event?.target?.files[0];
        const fileExtension = file?.name.split('.').pop().toLowerCase();
        if (!allowedVideoExtensions.includes(fileExtension)) {
            toast.error(t('PleaseUploadVideo'));
            return;
        }
        if (type === 'trailer') {
            setTrailerFile(event.target.files[0]);
            props.setHasTrailer(true);
        }
        if (type === 'video') {
            props.setFileUploadError('');
            props.setFileUpload(true);
            props.setVideoFile(event.target.files[0]);
            const minPrice = calculateMinimumPrice(event.target.files[0]);
            props.setMinimumPrice(minPrice);
            if (event.target.files[0]) {
                await getVideoDuration(event.target.files[0]);
            }
        }
    };

    const resizeFile = (file) => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                140,
                90,
                'PNG',
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                'blob'
            );
        });
    };

    const handleImageChange = async (e) => {
        e.preventDefault();
        if (!e.target.files[0]) {
            return;
        }
        let reader = new FileReader();
        let currentFile = e.target.files[0];
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        const fileExtension = currentFile.name.split('.').pop().toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
            // presentToast(
            //     'Please select a valid image file (jpg, jpeg, png).',
            //     closeCircleSharp,
            //     'danger',
            //     1500
            // );
            toast.error(t('PleaseSelectValidImage'));
            return;
        }

        reader.onloadend = () => {
            setImagePreview(reader.result);
        };

        try {
            if (currentFile) {
                const image = await resizeFile(currentFile);
                props.setPhoto(image);
                reader.readAsDataURL(image);
            }
        } catch (err) {
            console.error('ERROR with thumbnail: ', err);
        }
    };

    const deleteVideo = () => {
        props.setFileUpload(false);
        props.setVideoFile(null);
        props.setVideoDuration(null);
        setVideoFileType(null);
        setImagePreview(null);
    };

    const deleteTrailer = () => {
        setTrailerFile(null);
        setTrailerFileType(null);
    };

    useEffect(() => {
        if (props.videoFile && props.videoFile?.name) {
            setVideoFileType(props.videoFile.name.toLowerCase().split('.').pop());
        }
    }, [props.videoFile]);

    useEffect(() => {
        if (videoFileType) {
            props.onUploadVideo({ file: props.videoFile, type: videoFileType, jobId: props.jobId });
        }
    }, [videoFileType]);

    useEffect(() => {
        if (trailerFile && trailerFile?.name) {
            setTrailerFileType(trailerFile.name.toLowerCase().split('.').pop());
        }
    }, [trailerFile]);

    useEffect(() => {
        if (trailerFileType) {
            props.onUploadVideo({
                file: trailerFile,
                type: trailerFileType,
                jobId: props.jobId,
                trailer: true
            });
        }
    }, [trailerFileType]);

    return (
        <>
            <Typography fontSize={isMobile ? '32px' : '36px'} fontFamily={'InstrumentSans-Bold'}>
                {t('Upload')}
            </Typography>
            <Typography fontSize={isMobile ? '17px' : '20px'}>{t('UploadExplanation')}</Typography>
            <Box className={styles.uploadContent}>
                <Box {...getRootProps()} className={styles.uploadVideo}>
                    <Box
                        style={{
                            width: '35px',
                            height: '35px',
                            border: props.fileUploadError ? '1px solid red' : '',
                            backgroundColor: colors.primary,
                            borderRadius: '14px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer'
                        }}>
                        <AddIcon />
                        {/* <input
                            type="file"
                            id="upload"
                            onChange={(e) => handleVideoUpload(e, 'video')}
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                        /> */}
                    </Box>
                    <input {...getInputProps()} />
                    <Box>
                        <Typography fontSize={isMobile ? '14px' : '16px'}>
                            {t('UploadContent')}
                        </Typography>
                        <Typography fontSize={isMobile ? '11px' : '13px'} color={'#6C6C6C'}>
                            {t('UploadLimit')}
                        </Typography>
                    </Box>
                </Box>
                {props.fileUploadError && (
                    <Typography
                        fontSize={isMobile ? '14px' : '16px'}
                        color={'red'}
                        sx={{ marginTop: '-15px', padding: '0 1rem' }}>
                        {props.fileUploadError}
                    </Typography>
                )}
                {props.videoFile && (
                    <Box className={styles.uploadInputs}>
                        {/* VideoUpload */}
                        <label
                            // htmlFor="upload"
                            className={styles.uploadInput}
                            style={{ position: 'relative' }}>
                            <img
                                style={{
                                    borderRadius: '14px',
                                    width: '80px',
                                    height: '60px',
                                    objectFit: 'cover'
                                }}
                                src={photo}
                                alt="photo"
                            />
                            {upload?.upload_show && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        borderRadius: '14px',
                                        width: '80px',
                                        height: '60px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        zIndex: 999
                                    }}>
                                    <CircularProgressWithLabel
                                        size={25}
                                        color={colors.primary}
                                        showValue={false}
                                        value={upload.upload_progress}
                                    />
                                </Box>
                            )}
                            <IconButton
                                onClick={() => deleteVideo()}
                                sx={{
                                    background: 'white',
                                    padding: '0px',
                                    width: '20px',
                                    height: '20px',
                                    position: 'absolute',
                                    top: '5px',
                                    right: '5px',
                                    '&:active, &:focus, &:hover': {
                                        background: 'white'
                                    }
                                }}>
                                <CancelIcon
                                    sx={{ width: '20px', height: '20px' }}
                                    color={colors.primaryDarkGray}
                                />
                            </IconButton>
                        </label>
                        {/* Thumbnail */}
                        <Box
                            // htmlFor="thumbnailUpload"
                            style={{ position: 'relative' }}
                            className={styles.uploadInput}>
                            {imagePreview ? (
                                <>
                                    <img
                                        src={imagePreview}
                                        style={{
                                            borderRadius: '14px',
                                            width: '80px',
                                            height: '60px',
                                            objectFit: 'cover'
                                        }}
                                    />
                                    <IconButton
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setImagePreview(null);
                                        }}
                                        sx={{
                                            background: 'white',
                                            padding: '0px',
                                            width: '20px',
                                            height: '20px',
                                            position: 'absolute',
                                            top: '5px',
                                            right: '5px',
                                            '&:active, &:focus, &:hover': {
                                                background: 'white'
                                            }
                                        }}>
                                        <CancelIcon
                                            sx={{ width: '20px', height: '20px' }}
                                            color={colors.primaryDarkGray}
                                        />
                                    </IconButton>
                                </>
                            ) : (
                                <Box
                                    onClick={() => {
                                        if (fileInputRef.current) {
                                            fileInputRef.current.click();
                                        }
                                    }}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '80px',
                                        height: '60px',
                                        cursor: 'pointer'
                                    }}>
                                    <AddPhotoAlternateIcon />
                                    <Typography fontSize={'10px'}>{t('AddThumbnail')}</Typography>
                                </Box>
                            )}
                            <Box>
                                <input
                                    type="file"
                                    id="thumbnailUpload"
                                    key={imagePreview || 'default'} // Reset input key when image preview is cleared
                                    style={{ display: 'none' }}
                                    ref={fileInputRef}
                                    onChange={handleImageChange}
                                />
                            </Box>
                        </Box>
                        {/* TrailerUpload */}
                        <Box
                            // htmlFor="trailerUpload"
                            style={{ position: 'relative' }}
                            className={styles.uploadInput}>
                            {trailerFile ? (
                                <>
                                    <img
                                        style={{
                                            borderRadius: '14px',
                                            width: '80px',
                                            height: '60px',
                                            objectFit: 'cover'
                                        }}
                                        src={photo}
                                        alt="photo"
                                    />
                                    {upload?.trailer_show && (
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: 0,
                                                borderRadius: '14px',
                                                width: '80px',
                                                height: '60px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                zIndex: 999
                                            }}>
                                            <CircularProgressWithLabel
                                                size={25}
                                                color={colors.primary}
                                                showValue={false}
                                                value={upload.trailer_progress}
                                            />
                                        </Box>
                                    )}
                                    <IconButton
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            deleteTrailer();
                                        }}
                                        sx={{
                                            background: 'white',
                                            padding: '0px',
                                            width: '20px',
                                            height: '20px',
                                            position: 'absolute',
                                            top: '5px',
                                            right: '5px',
                                            '&:active, &:focus, &:hover': {
                                                background: 'white'
                                            }
                                        }}>
                                        <CancelIcon
                                            sx={{ width: '20px', height: '20px' }}
                                            color={colors.primaryDarkGray}
                                        />
                                    </IconButton>
                                </>
                            ) : (
                                <Box
                                    onClick={() =>
                                        trailerInputRef.current && trailerInputRef.current.click()
                                    }
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '80px',
                                        height: '60px',
                                        cursor: 'pointer'
                                    }}>
                                    <VideoCallIcon />
                                    <Typography fontSize={'10px'}>{t('AddTrailer')}</Typography>
                                </Box>
                            )}
                            {!upload?.trailer_show && (
                                <Box>
                                    <input
                                        type="file"
                                        id="uploadTrailer"
                                        accept="video/*"
                                        onChange={(e) => handleVideoUpload(e, 'trailer')}
                                        style={{ display: 'none' }}
                                        ref={trailerInputRef}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Box>
                )}
                <Box sx={{ border: '0.5px solid gray', margin: '1rem 0' }}></Box>
                <Box className={styles.uploadFields}>
                    <Typography fontSize={isMobile ? '14px' : '16px'}>{t('AddTitle')}</Typography>
                    <TextField
                        value={props.title}
                        onChange={(e) => {
                            props.setTitle(e.target.value);
                        }}
                        onFocus={() => {
                            props.setTitleError('');
                            setTitlePlaceholder('');
                        }}
                        onBlur={(e) => {
                            if (e.target.value === '') {
                                setTitlePlaceholder(t('TitlePlaceholder'));
                            }
                        }}
                        placeholder={titlePlaceholder}
                        sx={{
                            border: props.titleError ? '1px solid red' : '',
                            borderRadius: '16px',
                            '& .MuiInputBase-input::placeholder': {
                                color: 'black',
                                opacity: 1,
                                fontSize: isMobile ? '14px' : '16px'
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    border: 'none'
                                }
                            }
                        }}
                        fullWidth
                    />
                    {props.titleError && (
                        <Typography fontSize={isMobile ? '14px' : '16px'} color={'red'}>
                            {props.titleError}
                        </Typography>
                    )}
                    <Typography fontSize={isMobile ? '14px' : '16px'} sx={{ marginTop: '1rem' }}>
                        {t('AddDescription')}
                    </Typography>
                    <TextField
                        value={props.description}
                        onChange={(e) => props.setDescription(e.target.value)}
                        onFocus={() => {
                            props.setDescriptionError('');
                            setDescriptionPlaceholder('');
                        }}
                        onBlur={(e) => {
                            if (e.target.value === '') {
                                setDescriptionPlaceholder(t('DescriptionPlaceholder'));
                            }
                        }}
                        placeholder={descriptionPlaceholder}
                        sx={{
                            border: props.descriptionError ? '1px solid red' : '',
                            borderRadius: '16px',
                            '& .MuiInputBase-input::placeholder': {
                                color: 'black',
                                opacity: 1,
                                fontSize: isMobile ? '14px' : '16px'
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    border: 'none'
                                }
                            }
                        }}
                        fullWidth
                    />
                    {props.descriptionError && (
                        <Typography fontSize={isMobile ? '14px' : '16px'} color={'red'}>
                            {props.descriptionError}
                        </Typography>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadContent);
