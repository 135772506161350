import CheckIcon from '@mui/icons-material/Check';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CircularProgress,
    IconButton,
    InputAdornment,
    TextField,
    Typography
} from '@mui/material';
import { goBack } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { SET_COMPANY_DATA } from '../../../constants/actionTypes';
import fetch from '../../../fetch';
import CopyToClipboard from '../../../helpers/CopyToClipboard';
import sanity from '../../../sanity';
import { updateMetaTagsUrl } from '../../../utils';
import OnBack from '../../Auth/OnBack';
import styles from './customize.module.scss';

const EMPTY_VALIDATION_DNS = {
    resourceRecord: {
        Name: '',
        Value: '',
        Type: ''
    },
    status: ''
};
const BASE_ACCORDION_STATE = {
    domainValidationShown: false,
    domainRedirectShown: false
};

const BASE_CUSTOM_DOMAIN_REQUEST = {
    isLoading: false
};

const API_DOMAIN = process.env.REACT_APP_API_URL;

const mapDispatchToProps = (dispatch) => ({
    onGoBack: () => dispatch(goBack()),
    setCompanyData: (payload) => dispatch({ type: SET_COMPANY_DATA, payload })
});

const CustomUrlPage = (props) => {
    const company = useSelector((state) => state.common.company);
    const {
        register,
        handleSubmit,
        control,
        watch,
        formState: { errors }
    } = useForm();
    const { t } = useTranslation();

    const [companyDomain, setCompanyDomain] = useState();
    const [validationDns, setValidationDns] = useState(EMPTY_VALIDATION_DNS);
    const [accordionState, setAccordionState] = useState(BASE_ACCORDION_STATE);
    const [dnsData, setDnsData] = useState({});
    const baseDomain = API_DOMAIN.split('api')[1];
    // const [present] = useIonToast();
    // const presentToast = (message) => {
    //     present({
    //         message: message,
    //         duration: 2000,
    //         position: 'top',
    //         icon: closeCircleSharp,
    //         color: 'danger'
    //     });
    // };
    const premiumOnly = process.env.REACT_APP_PREMIUM_ONLY === 'true';

    const [customDomainRequest, setCustomDomainRequest] = useState(BASE_CUSTOM_DOMAIN_REQUEST);

    const isValidUrl = (url) => {
        const regex = new RegExp('^(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})([/\\w .-]*)*/?$');
        return regex.test(url);
    };

    useEffect(() => {
        if (company) {
            const interval = setInterval(() => {
                refreshPreview();
            }, 5000);

            return () => clearInterval(interval);
        }
    }, [company]);

    const refreshPreview = () => {
        if (company.domain) {
            // setValidationDns(EMPTY_VALIDATION_DNS);

            fetch.Company.GetCustomDomainStatus({
                email: company.creator,
                companyName: company.name
            })
                .then((r) => {
                    if (r?.DomainValidationOptions) {
                        const data = r.DomainValidationOptions.find(
                            (r) => r.DomainName === company.domain
                        );
                        if (!data) {
                            console.error('Unable to find data coresponding to custom domain');
                            return;
                        }
                        if (data.ResourceRecord) {
                            setDnsData(data.ValidationStatus);
                            setValidationDns({
                                status: data.ValidationStatus,
                                resourceRecord: {
                                    Name: data.ResourceRecord.Name,
                                    Value: data.ResourceRecord.Value,
                                    Type: data.ResourceRecord.Type
                                }
                            });
                        } else {
                            setValidationDns(EMPTY_VALIDATION_DNS);
                        }

                        setAccordionState({
                            domainValidationShown: data?.ValidationStatus !== 'SUCCESS',
                            domainRedirectShown: data?.ValidationStatus === 'SUCCESS'
                        });
                    }
                })
                .catch((e) => console.warn(e.message));
        }
    };

    const submitValidationDnsRequest = async (values) => {
        if (!isValidUrl(values.requested_domain)) {
            // presentToast('Invalid url format');
            return;
        } else {
            setCustomDomainRequest({ isLoading: true });

            if (!company?._id || !company?.creator || !company?.name || !values.requested_domain) {
                console.error(
                    'One or more required properties are not found. Unable to send request.'
                );
                setCustomDomainRequest({ isLoading: false });
                return;
            }

            const data = {
                companyId: company._id,
                email: company.creator,
                subDomain: `${company?.name}${baseDomain}`,
                requestedDomain: values.requested_domain
            };
            try {
                const customDomainRequest = await fetch.Company.CreateCustomDomainRequest(data);
                if (customDomainRequest?.OK) {
                    const editedCompany = await sanity.Companies.EditCompany(
                        company,
                        { ...company, domain: values.requested_domain },
                        company.creator
                    );
                    props.setCompanyData(editedCompany);
                }
            } catch (e) {
                console.error(e.message);
            } finally {
                setCustomDomainRequest({ isLoading: false });
                // updateMetaTagsUrl(values.requested_domain);
            }
        }
    };

    return (
        <div>
            <OnBack
                defaultHref={premiumOnly ? '/profile/branding' : '/profile/customize'}
                title={t('CustomURL')}
            />
            <div className={styles.profileWrapper} style={{ height: '100%' }}>
                <div className={styles.header}></div>
                <form onSubmit={handleSubmit(submitValidationDnsRequest)}>
                    <div style={{ padding: '6vh 2vw 0vh 2vw' }}>
                        <Typography className={styles.itemSubtitle} textAlign={'center'}>
                            {t('YourCurrentUrl')}
                        </Typography>
                        <Typography textAlign={'center'}>
                            {company?.domain || `${company?.name}${baseDomain}`}
                        </Typography>
                        <div style={{ padding: '8vh 0 3vh 0' }}>
                            <Typography style={{ marginBottom: '1vh' }}>
                                {t('CustomURL')}
                            </Typography>
                            <TextField
                                {...register('requested_domain', { required: true })}
                                sx={{ marginBottom: '1vh' }}
                                fullWidth
                                placeholder={'Enter your url'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                type="submit"
                                                onClick={(e) => {
                                                    setAccordionState({
                                                        ...accordionState,
                                                        domainValidationShown: false
                                                    });
                                                }}>
                                                {customDomainRequest.isLoading ? (
                                                    <CircularProgress />
                                                ) : (
                                                    <CheckIcon
                                                        style={{
                                                            fontSize: '30px',
                                                            color: 'white',
                                                            backgroundColor: 'black',
                                                            borderRadius: '4px'
                                                        }}
                                                    />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}></TextField>
                            <Typography className={styles.itemSubtitle}>
                                {t('VerifyNewUrl')}
                            </Typography>
                            <div
                                style={{
                                    marginTop: '3vh',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                <IconButton onClick={() => refreshPreview()}>
                                    <RefreshIcon
                                        style={{
                                            fontSize: '40px',
                                            color: 'white',
                                            backgroundColor: 'black',
                                            borderRadius: '4px'
                                        }}
                                    />
                                </IconButton>
                            </div>
                            <div style={{ marginTop: '2vh' }}>
                                <Accordion expanded={accordionState.domainValidationShown}>
                                    <AccordionSummary
                                        onClick={() =>
                                            setAccordionState({
                                                ...accordionState,
                                                domainValidationShown:
                                                    !accordionState.domainValidationShown
                                            })
                                        }>
                                        <Typography>{t('ValidationDNS')}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}>
                                                <TextField
                                                    label="Name"
                                                    sx={{ marginBottom: '1vh' }}
                                                    fullWidth
                                                    InputProps={{ readOnly: true }}
                                                    value={validationDns.resourceRecord.Name}
                                                />
                                                <CopyToClipboard
                                                    text={validationDns.resourceRecord.Name}
                                                />
                                            </div>
                                            <Typography sx={{ margin: '1vh 0' }}>
                                                {t('Status')} {validationDns.status}
                                            </Typography>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}>
                                                <TextField
                                                    label="Value"
                                                    sx={{ marginBottom: '1vh' }}
                                                    fullWidth
                                                    InputProps={{ readOnly: true }}
                                                    value={validationDns.resourceRecord.Value}
                                                />
                                                <CopyToClipboard
                                                    text={validationDns.resourceRecord.Value}
                                                />
                                            </div>
                                            <Typography sx={{ margin: '1vh 0' }}>
                                                {t('Status')} {validationDns.status}
                                            </Typography>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}>
                                                <TextField
                                                    label="Type"
                                                    sx={{ marginBottom: '1vh' }}
                                                    fullWidth
                                                    InputProps={{ readOnly: true }}
                                                    value={validationDns.resourceRecord.Type}
                                                />
                                                <CopyToClipboard
                                                    text={validationDns.resourceRecord.Type}
                                                />
                                            </div>
                                            <Typography sx={{ margin: '1vh 0' }}>
                                                {t('Status')} {validationDns.status}
                                            </Typography>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div style={{ marginTop: '2vh' }}>
                                <Accordion expanded={accordionState.domainRedirectShown}>
                                    <AccordionSummary
                                        onClick={() =>
                                            setAccordionState({
                                                ...accordionState,
                                                domainRedirectShown:
                                                    accordionState.domainRedirectShown
                                            })
                                        }>
                                        <Typography>{t('DNSRecords')}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}>
                                                <TextField
                                                    label="Name"
                                                    sx={{ marginBottom: '1vh' }}
                                                    fullWidth
                                                    InputProps={{ readOnly: true }}
                                                    value={company?.domain}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                                <CopyToClipboard text={company?.domain} />
                                            </div>
                                            {/* <Typography sx={{ margin: '1vh 0' }}>{t('Status')} {validationDns.status}</Typography> */}
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}>
                                                <TextField
                                                    label="Value"
                                                    sx={{ marginBottom: '1vh' }}
                                                    fullWidth
                                                    InputProps={{ readOnly: true }}
                                                    value={`${company?.name}${baseDomain}`}
                                                />
                                                <CopyToClipboard
                                                    text={`${company?.name}${baseDomain}`}
                                                />
                                            </div>
                                            {/* <Typography sx={{ margin: '1vh 0' }}>{t('Status')} {validationDns.status}</Typography> */}
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}>
                                                <TextField
                                                    label="Type"
                                                    sx={{ marginBottom: '1vh' }}
                                                    fullWidth
                                                    InputProps={{ readOnly: true }}
                                                    defaultValue="CNAME"
                                                />
                                                <CopyToClipboard text={'CNAME'} />
                                            </div>
                                            {/* <Typography sx={{ margin: '1vh 0' }}>{t('Status')} {validationDns.status}</Typography> */}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default connect(null, mapDispatchToProps)(CustomUrlPage);
