import React, { useEffect, useState } from 'react';
import fetch from '../../../fetch';
import { connect } from 'react-redux';
import OnBack from '../../Auth/OnBack';
import styles from '../settings.module.scss';
import { useHistory } from 'react-router-dom';
import { SET_USER_DATA } from '../../../constants/actionTypes';
import { Confirm } from '../../Confirm/confirm';
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser,
        company: state.common.company
    };
};

const mapDispatchToProps = (dispatch) => ({
    userUpdate: () => dispatch({ type: SET_USER_DATA })
});

function DeleteAccount(props) {
    const { t } = useTranslation();
    const history = useHistory();
    const uid = props.user.data.sub;
    const userPoolId = process.env.REACT_APP_COGNITO_USER_POOL_ID;

    const [user, setUser] = useState(props.user.data);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [userRequestedDeletion, setUserRequestedDeletion] = useState(
        props.user.data['custom:DELETE_USER_AFTER']
    );
    const [confirmUserDeletion, setConfirmUserDeletion] = useState(false);

    useEffect(() => setUser({ ...props.user.data }), [props.user]);

    useEffect(() => setUserRequestedDeletion(user['custom:DELETE_USER_AFTER']), [user]);

    const requestAccountDeletion = async () => {
        setLoading(true);
        setConfirmUserDeletion(false);

        await fetch.Account.Delete(uid, userPoolId, props.company._id)
            .then((response) => {
                if (response.success) {
                    //do magic
                    if (response.data?.deleteAt) {
                        props.user.data['custom:DELETE_USER_AFTER'] = response.data.deleteAt;
                        props.userUpdate({ ...props.user });
                    }
                } else {
                    setErrorMessage(response.error ?? 'Error contacting api');
                }
            })
            .catch((error) => {
                setErrorMessage(error.error);
            });

        setLoading(false);
    };

    const cancelAccountDeletion = async () => {
        setLoading(true);
        await fetch.Account.CancelDeletion(uid, userPoolId)
            .then((response) => {
                if (response.success) {
                    delete props.user.data['custom:DELETE_USER_AFTER'];
                    props.userUpdate({ ...props.user });
                } else {
                    console.error(response);
                    setErrorMessage(response.error);
                }
            })
            .catch((error) => {
                console.error(error);
                setErrorMessage(error.error);
            });
        setLoading(false);
    };

    return (
        <div>
            <OnBack defaultHref={'/profile/settings/account'} title={t('DeleteAccount')} />
            <div className={styles.profileWrapper}>
                <main>
                    <div>
                        {userRequestedDeletion != undefined && (
                            <>
                                <p>
                                    {t('AccountDeletionOnDate')}{' '}
                                    {`${new Date(
                                        parseInt(props.user.data['custom:DELETE_USER_AFTER'])
                                    ).toLocaleDateString()} vid ${new Date(
                                        parseInt(props.user.data['custom:DELETE_USER_AFTER'])
                                    ).toLocaleTimeString()}`}
                                </p>
                                <button
                                    onClick={cancelAccountDeletion}
                                    disabled={loading}
                                    style={{
                                        width: '100%',
                                        cursor: loading ? 'wait' : 'pointer'
                                    }}>
                                    {t('UndoAccountDeletion')}
                                </button>
                            </>
                        )}
                        {userRequestedDeletion == undefined && (
                            <>
                                <button
                                    onClick={() => setConfirmUserDeletion(true)}
                                    disabled={loading}
                                    style={{
                                        width: '100%',
                                        cursor: loading ? 'wait' : 'pointer'
                                    }}>
                                    {t('RequestAccountDeletion')}
                                </button>
                            </>
                        )}
                        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                    </div>

                    {confirmUserDeletion && (
                        <Confirm
                            successFunction={() => requestAccountDeletion()}
                            cancelFunction={() => setConfirmUserDeletion(false)}
                            title={t('AreYouSureYouWantAccountDelete')}
                            subtitle={''}
                            cancelButtonText={t('No')}
                            successButtonText={t('Yes')}
                        />
                    )}
                </main>
            </div>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccount);
