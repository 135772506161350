import React, { useEffect, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import ProductButton from './ProductButton';
import { useTranslation } from 'react-i18next';
// import fetch from '../../fetch';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { fromUnitAmount } from '../../utils/unitAmountHelper';
import {
    CONNECT_PRICE_CHANGE,
    CONNECTED_PRODUCTS_LOAD_FINISHED,
    SPINNER_VISIBLE,
    CONNECTED_ACCOUNT_LOAD_FINISHED,
    SET_USER_DATA
} from '../../constants/actionTypes';
import { getSymbolForCurrency } from '../../utils';
import { Link } from 'react-router-dom';
import fetch from '../../fetch';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser,
        company: state.common.company,
        connected_account: state.common.connected_account,
        connected_products: state.common.connected_products,
        connected_prices: state.prices.connected_prices
    };
};

const mapDispatchToProps = (dispatch) => ({
    onPriceChange: (payload) => dispatch({ type: CONNECT_PRICE_CHANGE, payload }),
    setConnectedPrices: (payload) => dispatch({ type: CONNECTED_PRODUCTS_LOAD_FINISHED, payload }),
    setConnectedProducts: (payload) =>
        dispatch({ type: CONNECTED_PRODUCTS_LOAD_FINISHED, payload }),
    onSpinner: (payload) => dispatch({ type: SPINNER_VISIBLE, payload }),
    setConnectedAccount: (payload) => dispatch({ type: CONNECTED_ACCOUNT_LOAD_FINISHED, payload }),
    setUserData: (payload) => dispatch({ type: SET_USER_DATA, payload })
});

const GetPremiumV2 = (props) => {
    const { event, user, connected_account, connected_products, connected_prices } = props;
    const { t } = useTranslation();
    const [connectedPrices, setConnectedPrices] = useState([]);
    const [connectedProducts, setConnectedProducts] = useState([]);
    const [price, setPrice] = useState(null);

    useEffect(() => {
        if (user?.premium_services?.events.includes(event.id)) {
            return;
        }
    }, []);

    useEffect(() => {
        async function fetchPrice() {
            try {
                props.onSpinner(true);
                const price = await fetch.Prices.Get(
                    event.pay_per_view_price,
                    connected_account.accountId
                );

                props.onPriceChange({ product: price.product, price });
                const match = props.connected_products.some(
                    (pp) => pp.id === price.product && pp.metadata['setup-price'] === 'event'
                );
                if (match) {
                    setPrice(price);
                }
                props.onSpinner(false);
            } catch (error) {
                console.log('ERROR: ', error);
            }
        }

        if (event.pay_per_view_price && connected_account && connected_prices.length > 0) {
            if (!connected_prices.some((p) => p.price.id === event.pay_per_view_price)) {
                fetchPrice();
            } else {
                const price = connected_prices.filter(
                    (p) => p.price.id === event.pay_per_view_price
                );
                // setPrice(price.price);
                setPrice(price[0].price);
            }
        }
    }, [connected_account, connected_prices, connectedProducts]);

    const handleProductClick = async (productId, priceId) => {
        const line_item = [
            {
                price: priceId,
                quantity: 1
            }
        ];
        const metadata = {
            companyId: connected_account.companyId,
            eventId: event.id,
            priceId,
            productId,
            customer_email: user?.data?.email
        };
        console.log('metadata:', metadata);
        try {
            const response = await fetch.Checkout.Create(
                line_item,
                window.location.href,
                user?.data?.email,
                connected_account.accountId,
                metadata
            );
            window.location.replace(response.url);
        } catch (e) {
            console.error(e.message);
        }
    };

    return (
        <Container maxWidth="xs">
            {price && (
                <>
                    <Box textAlign="center" sx={{ marginBottom: '30px' }}>
                        <Typography
                            sx={{
                                fontFamily: 'InstrumentSans-Bold',
                                color: 'black !important',
                                fontSize: '20px !important'
                            }}>
                            {t('UnlockContentPremium')}
                        </Typography>
                    </Box>
                    <Box
                        key={price.id}
                        onClick={() => handleProductClick(price.product, price.id)}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        bgcolor="black"
                        borderRadius="8px"
                        height="56px"
                        p={2}
                        mb={1}>
                        <div style={{ display: 'flex', width: 'auto' }}>
                            <LockIcon sx={{ color: 'white !important' }} />
                            <Typography
                                variant="body1"
                                sx={{
                                    color: 'white !important',
                                    fontSize: '16px !important',
                                    paddingLeft: '14px'
                                }}>
                                {t('UnlockContent')}
                            </Typography>
                        </div>
                        <Typography
                            variant="body1"
                            sx={{ color: 'white !important', fontSize: '16px !important' }}>
                            {fromUnitAmount(price.unit_amount, price.currency)}{' '}
                            {getSymbolForCurrency(price.currency)}
                        </Typography>
                    </Box>
                    <Box textAlign={'center'} paddingY={3}>
                        <Typography sx={{ color: 'black !important', fontSize: '16px !important' }}>
                            {t('AlreadyPurchased')}
                        </Typography>
                        <Typography sx={{ color: 'black !important', fontSize: '16px !important' }}>
                            {t('WatchMore')}
                        </Typography>
                        <Box>
                            <Link to={'/register'}>
                                <span style={{ color: 'rgba(0,0,0,0.5)' }}>Register</span>
                            </Link>
                            <span> or </span>
                            <Link to={'/login'}>
                                <span style={{ color: 'rgba(0,0,0,0.5)' }}>Login</span>
                            </Link>
                        </Box>
                    </Box>
                </>
            )}
            {/* <Box textAlign="center" my={4}>
                <Typography
                    variant="h3"
                    style={{
                        fontFamily: 'InstrumentSans-Bold',
                        fontSize: '15px',
                        lineHeight: '20px',
                        marginBottom: '4px'
                    }}>
                    {t('GetMoreFromThisCreator')}
                </Typography>
                <Typography
                    variant="subtitle1"
                    style={{
                        fontFamily: 'InstrumentSans',
                        fontSize: '15px',
                        fontStyle: 'normal',
                        lineHeight: '20px',
                        color: '#888'
                    }}>
                    {t('BuyDaypassOrMonthlySubscription')}
                </Typography>
            </Box> */}
            {/* <Box my={2}>
                {connectedPrices
                    .filter((p) =>
                        connectedProducts.some(
                            (pp) => pp.id === p.product && pp.metadata['setup-price'] === 'account'
                        )
                    )
                    .map((price) =>
                        price.price.metadata['sub-type'] === 'day-access' ? null : (
                            <ProductButton
                                key={price.price.product}
                                price={`${fromUnitAmount(
                                    price.price.unit_amount,
                                    price.price.currency
                                )} ${getSymbolForCurrency(price.price.currency)}`}
                                duration={t(price.price.metadata['interval'])}
                                buttonTitle={t(price.price.metadata['sub-type'])}
                                //buttonTitle={`${price.price.product}_${price.price.id}`}
                                handleClick={() =>
                                    handleProductClick(price.price.product, price.price.id)
                                }
                            />
                        )
                    )}
            </Box> */}
        </Container>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(GetPremiumV2);
