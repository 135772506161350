import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import sanity from '../../sanity';
import styles from './blogPost.module.scss';
import Nav from '../Nav/TopMenu';
import headphoto from '../../assets/images/hammphoto.jpeg';
import BlockContent from '@sanity/block-content-to-react';
import { BLOG_PAGE_LOADED } from '../../constants/actionTypes';
import { parseMatchTime } from '../../utils';

export default function BlogPost(props) {
    const dispatch = useDispatch();
    const blogPost = useSelector((state) => state.blog.currentBlogPost);
    useEffect(async () => {
        if (props.match) {
            const blogPost = await sanity.BlogPosts.GetBlogPostBySlug(
                props.computedMatch.params.slug
            );
            dispatch({ type: BLOG_PAGE_LOADED, payload: blogPost });
        }
    }, []);
    useEffect(() => {
        console.log('blog post changed', blogPost);
    }, [blogPost]);
    return (
        <div>
            <div>
                <Nav />
                {console.log(blogPost)}
                {blogPost && (
                    <div className={styles.wrapper}>
                        <img src={headphoto} className={styles.imgHead} alt="Blog Head Icon" />
                        <div className={styles.blogInfo}>
                            <h4>{blogPost.title}</h4>
                            <p>{parseMatchTime(blogPost.blogDate)}</p>
                            {blogPost.tags.map((tag) => {
                                return (
                                    <span className={styles.blogTag} key={tag}>
                                        {tag}
                                    </span>
                                );
                            })}

                            <p>
                                <BlockContent blocks={blogPost.blogText} />
                                {/*{blogPost.blogText.map((content) =>*/}
                                {/*    renderText(content.children[0].text)*/}
                                {/*)}*/}
                            </p>
                        </div>
                        {/*<div className={styles.author}>*/}
                        {/*    <p>Author</p>*/}
                        {/*    <p>Mattias Jansson</p>*/}
                        {/*</div>*/}
                        {/*<div className={styles.readMore}>*/}
                        {/*    <h4>Read more</h4>*/}
                        {/*    <BlogPostItem />*/}
                        {/*</div>*/}
                    </div>
                )}
            </div>
        </div>
    );
}
