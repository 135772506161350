import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from '../profile.module.scss';

const Support = () => {
    const { t } = useTranslation();
    return (
        <Box>
            <Typography
                sx={{ marginBottom: '1rem', borderBottom: '1px solid rgba(0, 0, 0, 0.2)' }}
                fontSize={'36px'}>
                {t('Support')}
            </Typography>
            <Box className={styles.upgradeSection}>
                <Typography fontSize={'24px'}>{t('NeedSupport')}</Typography>
                <Typography sx={{ marginTop: '1rem' }}>{t('SupportText')}</Typography>
                <Button>{t('Contact')}</Button>
            </Box>
        </Box>
    );
};

export default Support;
