import React from 'react';
import { Box, Button, Typography } from '@mui/material';

const ProductButton = ({ price, duration, handleClick, buttonTitle }) => {
    return (
        <Box
            display="flex"
            justifyContent="space-between"
            sx={{
                borderRadius: '8px',
                border: '1px solid #E4E4E4',
                padding: '8px',
                margin: '8px 0px'
            }}>
            <Typography variant="body1">
                <span style={{ fontFamily: 'InstrumentSans-Bold', color: 'black' }}>{price}</span> /{' '}
                {duration}
            </Typography>

            <Button
                onClick={handleClick}
                variant="contained"
                color="primary"
                sx={{ boxShadow: 'none', borderRadius: '8px', width: '120px' }}>
                <Typography
                    variant="p"
                    sx={{
                        fontFamily: 'InstrumentSans-Bold',
                        fontSize: 13,
                        textTransform: 'capitalize'
                    }}>
                    {buttonTitle}
                </Typography>
            </Button>
        </Box>
    );
};

export default ProductButton;
