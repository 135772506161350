import ExpandMore from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    CircularProgress,
    Drawer,
    Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import fetch from '../../../fetch';
import OnBack from '../../Auth/OnBack';
import styles from '../../Profile/Customize/customize.module.scss';
import { getSymbolForCurrency } from '../../../utils';
import { SPINNER_VISIBLE } from '../../../constants/actionTypes';

const mapStateToProps = (state) => {
    return {
        connected_account: state.common.connected_account
    };
};

const mapDispatchToProps = (dispatch) => ({
    onSpinner: (payload) => dispatch({ type: SPINNER_VISIBLE, payload })
});

const Balance = (props) => {
    const { t } = useTranslation();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [availableBalance, setAvailableBalance] = useState([]);
    const [pendingBalance, setPendingBalance] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(false);

    const fetchTransactions = async (limit = 10) => {
        const balanceTransactions = await fetch.StripeAccount.getBalanceTransactions(
            props.connected_account.accountId,
            limit
        );
        // console.log('TRANSACTIONS: ', balanceTransactions);
        return balanceTransactions;
    };

    const fetchMoreTransactions = async (limit) => {
        try {
            setLoading(true);
            const balanceTransactions = await fetchTransactions(limit);
            setTransactions(balanceTransactions.data);
            setHasMore(balanceTransactions.has_more);
        } catch (error) {
            console.log('ERROR: ', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        const fetchBalance = async () => {
            try {
                props.onSpinner(true);
                const balance = await fetch.StripeAccount.getAccountBalance(
                    props.connected_account.accountId
                );
                // console.log('BALANCEL :', balance);
                setAvailableBalance(balance.available);
                setPendingBalance(balance.pending);
                const balanceTransactions = await fetchTransactions();
                setTransactions(balanceTransactions.data);
                setHasMore(balanceTransactions.has_more);
                props.onSpinner(false);
            } catch (error) {
                console.log('ERROR: ', error);
            }
        };
        if (props.connected_account) {
            fetchBalance();
        }
    }, [props.connected_account]);

    const formatDateFromTimestamp = (timestamp) => {
        const date = new Date(timestamp * 1000);

        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();

        return `${day} ${month} ${year}`;
    };

    return (
        <div>
            <OnBack defaultHref={'/profile'} title={t('BalanceAndTransactions')} />
            <div className={styles.myVideosWrapper}>
                <div className={styles.header} style={{ padding: '5% 12px 0%' }}></div>
                <div style={{ padding: '2vh 2vw 0vh' }}>
                    <>
                        <div>
                            <div
                                className={styles.flexBetween}
                                style={{
                                    marginBottom: '2vh'
                                }}>
                                <div
                                    style={{
                                        flex: 1,
                                        textAlign: 'center',
                                        borderRight: '1px solid #888'
                                    }}>
                                    {availableBalance.map((data, index) => {
                                        return (
                                            <div key={index}>
                                                <Typography
                                                    textAlign={'center'}
                                                    fontSize={'30px'}
                                                    fontFamily="InstrumentSans-Bold">
                                                    {data.amount}
                                                    {getSymbolForCurrency(data.currency)}
                                                </Typography>
                                                <Typography
                                                    color={'#888 !important'}
                                                    textAlign={'center'}
                                                    fontSize={'14px'}>
                                                    Available
                                                </Typography>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div
                                    style={{
                                        flex: 1,
                                        textAlign: 'center',
                                        borderLeft: '1px solid #888'
                                    }}>
                                    {pendingBalance.map((data, index) => {
                                        return (
                                            <div key={index}>
                                                <Typography
                                                    textAlign={'center'}
                                                    fontSize={'30px'}
                                                    fontFamily="InstrumentSans-Bold">
                                                    {data.amount}
                                                    {getSymbolForCurrency(data.currency)}
                                                </Typography>
                                                <Typography
                                                    color={'#888 !important'}
                                                    textAlign={'center'}
                                                    fontSize={'14px'}>
                                                    Pending
                                                </Typography>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <Drawer
                            anchor="bottom"
                            open={openDrawer}
                            onClose={() => setOpenDrawer(false)}>
                            <div>
                                <div
                                    style={{
                                        backgroundColor: 'white',
                                        position: 'fixed',
                                        bottom: 0,
                                        left: 0,
                                        right: 0,
                                        zIndex: 999,
                                        borderRadius: '8px 8px 0px 0px'
                                    }}>
                                    <div>
                                        <div
                                            style={{
                                                margin: '0 auto',
                                                width: isMobile ? '92%' : '97%',
                                                borderBottom: '1px solid #0000001A'
                                            }}></div>
                                    </div>
                                </div>
                            </div>
                        </Drawer>
                    </>
                </div>
                {transactions.length > 0 && (
                    <Accordion defaultExpanded sx={{ marginTop: '8%' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <Typography>Transactions</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ padding: '0 !important' }}>
                            {transactions.map((transaction) => {
                                return (
                                    <div
                                        key={transaction.id}
                                        style={{ padding: '0px 16px', margin: '1vh 0' }}>
                                        <Typography>
                                            {transaction.net}
                                            {getSymbolForCurrency(transaction.currency)}
                                        </Typography>
                                        <Typography>
                                            {formatDateFromTimestamp(transaction.created)}
                                        </Typography>
                                        <Typography>
                                            Type:{' '}
                                            <span style={{ textTransform: 'capitalize' }}>
                                                {transaction.reporting_category}
                                            </span>
                                        </Typography>
                                        <div style={{ borderTop: '1px solid #888' }}></div>
                                    </div>
                                );
                            })}
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    paddingBottom: '5vh'
                                }}>
                                {loading ? (
                                    <CircularProgress />
                                ) : hasMore && !loading ? (
                                    <Button
                                        onClick={() => fetchMoreTransactions(50)}
                                        variant="contained">
                                        Load More
                                    </Button>
                                ) : null}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                )}
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Balance);
