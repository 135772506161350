import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useSwipeable } from 'react-swipeable';
import arrowDown from '../../assets/images/arrow-down.svg';
import { REPORT_MESSAGE_CLEAR } from '../../constants/actionTypes';
import ReportInfo from './ReportInfo';
import ReportListItem from './ReportListItem';
import styles from './reportList.module.scss';

const mapDispatchToProps = (dispatch) => ({
    onReportMessageClear: (payload) => dispatch({ type: REPORT_MESSAGE_CLEAR, payload })
});

function ReportList({ onReportMessageClear }) {
    const { t } = useTranslation();
    const history = useHistory();
    useEffect(() => {
        return () => onReportMessageClear(); // clear last message data.
    }, []);

    var dispatch = useDispatch();

    const reportList = [
        t('InappropriateLanguage'),
        t('HatefulBehaviour'),
        t('Harassment'),
        t('FraudOrOtherContent'),
        t('OffensiveUsername'),
        t('MiscategorizedContent'),
        t('AlcoholAndTobacco'),
        t('IrrelevantContent'),
        t('PoorImageQuality'),
        t('Other')
    ];

    const closeReportScreen = () => {
        history.goBack();
    };

    const [writeReport, setWriteReport] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const addReport = (option) => {
        setWriteReport(true);
        setSelectedOption(option);
    };

    const goBack = () => {
        setWriteReport(false);
    };

    return (
        <div>
            {!writeReport && (
                <div className={styles.profileWrapper}>
                    <header>
                        <h1>{t('ReportContents')}</h1>
                        <button onClick={() => closeReportScreen()} style={{ background: 'white' }}>
                            <img src={arrowDown} alt="Arrow Icon" />
                        </button>
                    </header>
                    <p className={styles.reportQuestion}>{t('WhatToReport')}</p>
                    {reportList.map((reportOption, key) => {
                        return (
                            <ReportListItem
                                key={key}
                                reportTitle={reportOption}
                                addReport={addReport}
                            />
                        );
                    })}
                </div>
            )}

            {writeReport && <ReportInfo reportTitle={selectedOption} back={goBack} />}
        </div>
    );
}

export default connect(null, mapDispatchToProps)(ReportList);
