import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    TextField,
    Typography
} from '@mui/material';
import { goBack } from 'connected-react-router';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Resizer from 'react-image-file-resizer';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js';
import { SET_COMPANY_DATA } from '../../../constants/actionTypes';
import sanity from '../../../sanity';
import { getTruncatedFileName } from '../../../utils';
import OnBack from '../../Auth/OnBack';
import { uploadCompanyLogo } from '../../core-components/uploadImageToSanity.js';
import PreviewScreen from '../Customize/PreviewScreen';
import styles from './customize.module.scss';

const mapStateToProps = (state) => {
    return {
        company: state.common.company
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGoBack: () => dispatch(goBack()),
    setCompanyData: (payload) => dispatch({ type: SET_COMPANY_DATA, payload })
});

const LogotypePage = (props) => {
    const [companyLogoAndTextPreview, setCompanyLogoAndTextPreview] = useState(
        props.company ? sanity.urlFor(props.company.company_logo_text.asset) : null
    );
    const [companyLogoAndText, setCompanyLogoAndText] = useState('');
    const { t } = useTranslation();
    const fileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [showPreview, setShowPreview] = useState(false);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    // const [present] = useIonToast();
    // const presentToast = (message, icon, color) => {
    //     present({
    //         message: message,
    //         duration: 1500,
    //         position: 'top',
    //         icon: icon,
    //         color: color
    //     });
    // };
    const premiumOnly = process.env.REACT_APP_PREMIUM_ONLY === 'true';

    const resizeFile = (file) => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                200,
                200,
                'PNG',
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                'blob'
            );
        });
    };

    useEffect(() => {
        async function setPredefinedMessages() {
            await sanity.GlobalParams.GetGlobalParams();
        }
        setPredefinedMessages();
    });
    let editedCompany = props.company;

    const handleFileUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleLogoAndTextChange = async (e) => {
        e.preventDefault();
        let reader = new FileReader();
        if (e) {
            let currentFile = e.target.files[0];
            const allowedExtensions = ['jpg', 'jpeg', 'png'];
            const fileExtension = currentFile.name.split('.').pop().toLowerCase();

            if (!allowedExtensions.includes(fileExtension)) {
                // presentToast(
                //     'Please select a valid image file (jpg, jpeg, png).',
                //     closeCircleSharp,
                //     'danger'
                // );
                return;
            }
            reader.onloadend = () => {
                setCompanyLogoAndTextPreview(reader.result);
                setImagePreview(reader.result);
                console.log(reader.result);
                // currentFile = reader.result;
            };
            try {
                if (currentFile) {
                    const image = await resizeFile(currentFile);
                    setCompanyLogoAndText(image);
                    setSelectedFile(image);
                    reader.readAsDataURL(image);
                }
            } catch (err) {
                console.error('ERROR with thumbnail: ', err);
            }
        }
    };

    const handleRemoveImage = () => {
        setSelectedFile(null);
        setImagePreview(null);
        setCompanyLogoAndTextPreview(null);
    };

    const handlePreviewOpen = () => {
        setShowPreview(true);
    };

    const handlePreviewClose = () => {
        setShowPreview(false);
    };

    const updateCompany = async () => {
        const logoAndTextAsset = {
            _ref: companyLogoAndText ? companyLogoAndText : '',
            _type: 'reference'
        };
        setLoading(true);
        try {
            let res = await uploadCompanyLogo(companyLogoAndText);
            logoAndTextAsset._ref = res._id;
            const cmp = {
                company_logo_text: { _type: 'image', asset: logoAndTextAsset }
            };
            await sanity.Companies.EditCompany(props.company, cmp, props.company.creator);
            const editedCompany = await sanity.Companies.GetCompanyById(
                process.env.REACT_APP_COMPANY_ID
            );
            props.setCompanyData(editedCompany);
            setTimeout(() => {
                history.goBack();
            }, 1600);
        } catch (err) {
            // presentToast('Error uploading logo', closeCircleSharp, 'danger');
        } finally {
            setLoading(false);
            // presentToast('Company logotype changed.', checkmarkCircleSharp, 'success');
        }
    };

    return (
        <div>
            <OnBack
                defaultHref={premiumOnly ? '/profile/branding' : '/profile/customize'}
                title="Logotype"
                showButtonOrLoader={loading ? 'loader' : 'button'}
                buttonDisabled={selectedFile === null}
                buttonText={t('Save')}
                onClick={() => updateCompany()}
            />
            <div className={styles.profileWrapper} style={{ minHeight: '100%' }}>
                <div className={styles.header}></div>
                <div style={{ padding: '6vh 2vw 0vh 2vw' }}>
                    <Typography
                        className={styles.itemSubtitle}
                        style={{
                            textAlign: 'center',
                            marginBottom: imagePreview ? '3vh' : ''
                        }}>
                        {t('LogotypeTip')}
                    </Typography>
                    {(imagePreview || companyLogoAndTextPreview) && (
                        <div
                            style={{
                                margin: '0 auto',
                                position: 'relative',
                                width: '200px',
                                height: '200px'
                            }}>
                            <img
                                src={imagePreview || companyLogoAndTextPreview}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                    border: '1px solid black',
                                    borderRadius: '30px'
                                }}
                            />
                            <IconButton
                                onClick={handleRemoveImage}
                                sx={{
                                    background: 'lightgray',
                                    padding: '5px',
                                    position: 'absolute',
                                    top: '-10px',
                                    right: '-10px'
                                }}>
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    )}
                    <div
                        style={{
                            padding: '8vh 0 3vh 0'
                        }}>
                        <Typography style={{ marginBottom: '1vh' }}>
                            {t('UploadLogotype')}
                        </Typography>
                        <TextField
                            fullWidth
                            placeholder={t('UploadFile')}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{
                                style: {
                                    height: '5vh'
                                }
                            }}
                            InputProps={{
                                value: selectedFile ? getTruncatedFileName(selectedFile.name) : '',
                                onClick: handleFileUploadClick,
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment
                                        sx={{
                                            ' &:hover': {
                                                cursor: 'pointer'
                                            }
                                        }}
                                        position="end">
                                        <FileUploadOutlinedIcon
                                            style={{
                                                fontSize: '40px',
                                                color: 'white',
                                                backgroundColor: 'black',
                                                borderRadius: '4px'
                                            }}
                                        />
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            onChange={handleLogoAndTextChange}
                                        />
                                    </InputAdornment>
                                )
                            }}></TextField>
                    </div>
                    <Button
                        onClick={handlePreviewOpen}
                        sx={{
                            '&:active, &:focus, &:hover': {
                                backgroundColor: 'white'
                            }
                        }}
                        className={styles.previewButton}>
                        {t('Preview')}
                    </Button>
                </div>
                <Dialog
                    fullScreen
                    PaperProps={{
                        style: {
                            background: 'black'
                        }
                    }}
                    open={showPreview}>
                    <DialogTitle>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography textAlign={'center'} sx={{ color: 'white ', flex: 1 }}>
                                {t('Preview')}
                            </Typography>
                            <IconButton
                                sx={{ color: 'white', marginLeft: '-40px' }}
                                onClick={handlePreviewClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent
                        sx={{
                            backgroundColor: 'black'
                        }}>
                        <div>
                            <PreviewScreen
                                company={editedCompany}
                                companyLogoAndTextPreview={companyLogoAndTextPreview}
                            />
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(LogotypePage);
