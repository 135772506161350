import MenuIcon from '@mui/icons-material/Menu';
import { Box, Collapse, IconButton, List } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import logo from '../../assets/images/logo.svg';
import styles from '../CreateVideo/createVideo.module.scss';
import { SET_USER_DATA, SOCKET_DISCONNNECT } from '../../constants/actionTypes';
import cognitoService from '../../core/service/cognito.service';

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser,
        isAdmin: state.user.currentUser.groups && state.user.currentUser.groups.includes('Admin')
    };
};

const mapDispatchToProps = (dispatch) => ({
    onLogout: () => {
        dispatch({
            type: SET_USER_DATA,
            payload: {
                data: null,
                token: null,
                loaded: true,
                imagePreview: null,
                name: null,
                nickname: null,
                step: false,
                surname: null,
                username: null
            }
        });
        dispatch({ type: SOCKET_DISCONNNECT });
    }
});

const NavMobile = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const path = window.location.pathname;

    const handleToggle = () => {
        setOpen((prev) => !prev);
    };

    const deleteCookie = (name) => {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    };

    const onLogout = () => {
        cognitoService.logoutUser();
        props.onLogout();
        deleteCookie('PremiumStatus');
        history.push('/');
    };
    return (
        <Box className={styles.flex}>
            <Box>
                <IconButton onClick={handleToggle} aria-label="menu">
                    <MenuIcon fontSize="large" />
                </IconButton>
                <Collapse in={open} timeout={0}>
                    <List
                        component="nav"
                        sx={{
                            bgcolor: 'background.paper',
                            width: '100%',
                            position: 'absolute',
                            zIndex: 999,
                            boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.25)',
                            borderRadius: '10px',
                            padding: '10px',
                            color: path === '/profile' ? 'black !important' : ''
                        }}>
                        {props?.user?.data && (
                            <>
                                <Box onClick={() => history.push('/my-library')}>
                                    {t('MyLibrary')}
                                </Box>
                                {props.isAdmin && (
                                    <Box
                                        sx={{ marginTop: '1rem' }}
                                        onClick={() => history.push('/profile')}>
                                        {t('Profile')}
                                    </Box>
                                )}
                            </>
                        )}
                        <Box sx={{ margin: props?.user?.data ? '1rem 0' : '0 0 1rem 0' }}>
                            {t('About')}
                        </Box>
                        <Box sx={{ margin: props?.user?.data ? '0 0 1rem 0' : '0' }}>
                            {t('TermsOfConditions')}
                        </Box>
                        {props?.user?.data && <Box onClick={onLogout}>{t('SignOut')}</Box>}
                    </List>
                </Collapse>
            </Box>
            <img
                style={{ cursor: 'pointer' }}
                onClick={() => history.push(props.isAdmin ? '/home' : 'my-library')}
                src={logo}
                alt="logo"
            />
            {/* <Box onClick={() => history.push('/register')}>{t('SignUp')}</Box> */}
        </Box>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(NavMobile);
