import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import sanity from '../../sanity';
import {
    CONVERTING_STATUS,
    UPLOADING_STATUS,
    CONVERT_STATUS,
    NOT_PUBLISHED
} from '../../constants';
import { isMobile } from 'react-device-detect';
import NavMobile from '../Nav/NavMobile';
import NavDesktop from '../Nav/NavDesktop';
import { connect } from 'react-redux';
import Event from '../../core/entitites/event.entity';
import CancelIcon from '@mui/icons-material/Cancel';
import searchIcon from '../../assets/images/search-icon.svg';
import colors from '../../utils/colors';
import styles from './ProfileDesign/profile.module.scss';
import EventThumbnail from '../Event/EventThumbnail';
import { getSymbolForCurrency } from '../../utils';
import { fromUnitAmount } from '../../utils/unitAmountHelper';
import fetch from '../../fetch';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { CONNECTED_ACCOUNT_LOAD_FINISHED } from '../../constants/actionTypes';

const mapStateToProps = (state) => ({
    events: state.event.events,
    user: state.user.currentUser,
    company: state.common.company,
    upload: state.common.upload,
    isAdmin: state.user.currentUser.groups && state.user.currentUser.groups.includes('Admin')
});

const mapDispatchToProps = (dispatch) => ({
    setConnectedAccount: (payload) => dispatch({ type: CONNECTED_ACCOUNT_LOAD_FINISHED, payload })
});

function useDebounce(value, delay, initialValue) {
    const [state, setState] = useState(initialValue);

    useEffect(() => {
        const timer = setTimeout(() => setState(value), delay);

        return () => {
            clearTimeout(timer);
        };
    }, [value, delay]);
    return state;
}

const MyLibrary = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [videos, setVideos] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [events, setEvents] = useState([]);
    const debouncedValue = useDebounce(searchQuery, 100) || '';
    const [subscriptionStatus, setSubscriptionStatus] = useState(null);

    const getQueryParams = (search) => {
        return new URLSearchParams(search);
    };

    const fetchSubscription = async () => {
        const queryParams = getQueryParams(location.search);
        const session_id = queryParams.get('session_id');
        const account = await fetch.StripeAccount.getAccountByCompany(
            process.env.REACT_APP_COMPANY_ID
        );
        props.setConnectedAccount(account);
        const subscriptionStatus = await fetch.StripeAccount.getSubscriptionStatus(
            props.user?.data?.email ?? '',
            process.env.REACT_APP_COMPANY_ID,
            props.user?.groups?.includes('Admin') ? undefined : account?.accountId,
            session_id ?? ''
        );
        setSubscriptionStatus(subscriptionStatus);
    };

    useEffect(() => {
        if (!props.isAdmin) {
            fetchSubscription();
        }
    }, []);

    const fetchVideos = async () => {
        try {
            let events = await sanity.Events.GetEvents(true);

            const publishingEvents = events
                .filter(
                    (event) =>
                        event.processingStatus === CONVERTING_STATUS ||
                        event.processingStatus === UPLOADING_STATUS ||
                        event.processingStatus === CONVERT_STATUS
                )
                .map((e) => {
                    const runtimeProps = props.upload.find(
                        (uploadedEvent) => uploadedEvent.jobId === e.correlation_id
                    );
                    return runtimeProps ||
                        e.processingStatus === CONVERTING_STATUS ||
                        e.processingStatus === CONVERT_STATUS
                        ? Event.new({ ...e, ...runtimeProps })
                        : null;
                })
                .filter((e) => e !== null);
            const eventList = events.filter(
                (e) =>
                    !publishingEvents.some((p) => p.id === e.id) &&
                    e.stream_status !== NOT_PUBLISHED
            );
            if (props.isAdmin) {
                setEvents(eventList);
            } else {
                const filteredEvents = eventList
                    .filter((event) => subscriptionStatus.events.includes(event.id))
                    .map((event) => {
                        return event;
                    });
                setEvents(filteredEvents);
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        fetchVideos();
    }, [props.events, props.upload, props.company, subscriptionStatus]);

    useEffect(() => {
        if (debouncedValue.trim() === '') {
            fetchVideos();
            return;
        }
        async function fetchData() {
            const events = await sanity.Events.Search(debouncedValue);
            const filtered = events.filter((event) => event.stream_status !== 'unpublished');

            setEvents(filtered);
        }
        if (debouncedValue) {
            fetchData();
        }
    }, [debouncedValue]);

    useEffect(() => {
        const fetchEarnings = async () => {
            try {
                if (props.company && events) {
                    setLoading(true);
                    const result = await fetch.StripeAccount.getEarnings(props.company._id);
                    const earnings = result.earnings;
                    const enrichedEvents = events.map((event) => {
                        const eventEarnings = earnings[event.id];

                        if (eventEarnings) {
                            const currencyKey = Object.keys(eventEarnings)[0];
                            const currencyData = eventEarnings[currencyKey];
                            return Object.assign(event, currencyData, {
                                streams: currencyData.count
                            });
                        }
                        return Object.assign(event, { streams: 0 });
                    });
                    console.log('enrichedEvents', enrichedEvents);
                    setVideos(enrichedEvents);
                }
            } catch (error) {
                console.log('ERROR: ', error);
            } finally {
                setLoading(false);
            }
        };
        fetchEarnings();
    }, [props.company, events]);

    const deleteEvent = async (id) => {
        try {
            setDeleteLoading(true);
            await fetch.Event.DeleteEvent(id);
            setEvents((prevEvents) => prevEvents.filter((event) => event.id !== id));
        } catch (error) {
            console.log('ERROR: ', error);
        } finally {
            setDeleteLoading(false);
        }
    };
    return (
        <Box>
            {isMobile && <NavMobile />}
            <Box>
                {!isMobile && <NavDesktop />}
                <Box className={styles.libraryContent}>
                    <Typography fontSize={'30px'}>{t('MyLibrary')}</Typography>
                    <Typography fontSize={'30px'} sx={{ opacity: 0.6 }}>
                        {videos.length} links
                    </Typography>
                </Box>
                <Box className={styles.libraryMain}>
                    <Box className={styles.links}>
                        {!isMobile && <Typography>{t('AllLinks')}</Typography>}
                        <Box className={styles.searchAndButton}>
                            <FormControl>
                                <OutlinedInput
                                    sx={{
                                        paddingRight: '0',
                                        borderColor: 'transparent',
                                        backgroundColor: '#E4E4E4',
                                        '& fieldset': { border: 'none' }
                                    }}
                                    id="outlined-adornment-amount"
                                    placeholder="Search"
                                    size="small"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <img src={searchIcon} />
                                        </InputAdornment>
                                    }
                                    endAdornment={
                                        searchQuery && (
                                            <IconButton
                                                sx={{ color: '#ccc' }}
                                                onClick={() => {
                                                    setSearchQuery('');
                                                }}>
                                                <CancelIcon />
                                            </IconButton>
                                        )
                                    }
                                />
                            </FormControl>
                            {props.isAdmin && (
                                <Button
                                    onClick={() => history.push('/home')}
                                    className={styles.linkButton}>
                                    <span style={{ fontSize: '20px', marginRight: '8px' }}>+</span>
                                    {t('CreateLink')}
                                </Button>
                            )}
                        </Box>
                    </Box>
                    {loading || deleteLoading ? (
                        <Box
                            sx={{
                                marginTop: '2rem',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                            <CircularProgress sx={{ color: colors.primary }} />
                        </Box>
                    ) : (
                        <>
                            {isMobile ? (
                                <Box sx={{ marginTop: '2rem' }}>
                                    {videos.map((video) => (
                                        <Box
                                            className={styles.videos}
                                            sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.2)' }}
                                            key={video.id}>
                                            <Box className={styles.flexBetween2}>
                                                <Box
                                                    className={styles.flexBetween}
                                                    sx={{ gap: '1rem' }}>
                                                    <EventThumbnail
                                                        borderRadius={'12px'}
                                                        event={video}
                                                        width={70}
                                                        height={70}
                                                    />
                                                    <Box>
                                                        <Typography fontSize={'18px'}>
                                                            {video.title}
                                                        </Typography>
                                                        <Typography
                                                            sx={{ opacity: 0.5 }}
                                                            fontSize={'14px'}>
                                                            {video.description}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Box
                                                    className={styles.searchAndButton}
                                                    sx={{ gap: '1%' }}>
                                                    <Button
                                                        // onClick={() =>
                                                        //     history.push(`/edit/${video.id}`)
                                                        // }
                                                        className={styles.editButton}>
                                                        {t('Edit')}
                                                    </Button>
                                                    <Button
                                                        onClick={() => deleteEvent(video.id)}
                                                        className={styles.deleteButton}>
                                                        {t('Delete')}
                                                    </Button>
                                                </Box>
                                            </Box>
                                            <Box className={styles.libraryGrid}>
                                                <Box>
                                                    <Typography fontSize={'18px'}>
                                                        {getSymbolForCurrency(
                                                            video.pay_per_view_price_currency
                                                        )}
                                                        {video.pay_per_view_price_amount}
                                                    </Typography>
                                                    <Typography
                                                        sx={{ opacity: 0.5 }}
                                                        fontSize={'14px'}>
                                                        {t('Price')}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography fontSize={'18px'}>
                                                        {video.streams}
                                                    </Typography>
                                                    <Typography
                                                        sx={{ opacity: 0.5 }}
                                                        fontSize={'14px'}>
                                                        {t('Streams')}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography fontSize={'18px'}>
                                                        {
                                                            new Date(video.start_date)
                                                                .toISOString()
                                                                .split('T')[0]
                                                        }
                                                    </Typography>
                                                    <Typography
                                                        sx={{ opacity: 0.5 }}
                                                        fontSize={'14px'}>
                                                        {t('Published')}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                            ) : (
                                <Box sx={{ marginTop: '4rem' }}>
                                    {videos.map((video) => (
                                        <Box
                                            key={video.id}
                                            className={styles.flexBetween}
                                            sx={{ gap: '1rem' }}>
                                            <Box className={styles.videos}>
                                                <Box
                                                    className={styles.flexGap}
                                                    sx={{ gap: '1rem' }}>
                                                    <EventThumbnail
                                                        borderRadius={'12px'}
                                                        event={video}
                                                        width={200}
                                                        height={100}
                                                    />
                                                    <Box>
                                                        <Typography fontSize={'18px'}>
                                                            {video.title}
                                                        </Typography>
                                                        <Typography
                                                            sx={{ opacity: 0.5 }}
                                                            fontSize={'14px'}>
                                                            {video.description}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Box
                                                    sx={{ minWidth: '100px', textAlign: 'center' }}>
                                                    <Typography fontSize={'18px'}>
                                                        {getSymbolForCurrency(
                                                            video.pay_per_view_price_currency
                                                        )}
                                                        {video.pay_per_view_price_amount}
                                                    </Typography>
                                                    <Typography
                                                        sx={{ opacity: 0.5 }}
                                                        fontSize={'14px'}>
                                                        {t('Price')}
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    sx={{ minWidth: '100px', textAlign: 'center' }}>
                                                    <Typography fontSize={'18px'}>
                                                        {video.streams}
                                                    </Typography>
                                                    <Typography
                                                        sx={{ opacity: 0.5 }}
                                                        fontSize={'14px'}>
                                                        {t('Streams')}
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    sx={{ minWidth: '100px', textAlign: 'center' }}>
                                                    <Typography fontSize={'18px'}>
                                                        {
                                                            new Date(video.start_date)
                                                                .toISOString()
                                                                .split('T')[0]
                                                        }
                                                    </Typography>
                                                    <Typography
                                                        sx={{ opacity: 0.5 }}
                                                        fontSize={'14px'}>
                                                        {t('Published')}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box className={styles.searchAndButton}>
                                                <Button
                                                    // onClick={() =>
                                                    //     history.push(`/edit/${video.id}`)
                                                    // }
                                                    className={styles.editButton}>
                                                    {t('Edit')}
                                                </Button>
                                                <Button
                                                    onClick={() => deleteEvent(video.id)}
                                                    className={styles.deleteButton}>
                                                    {t('Delete')}
                                                </Button>
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                            )}
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(MyLibrary);
