import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getSymbolForCurrency } from '../../../utils';
import colors from '../../../utils/colors';
import styles from './profile.module.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const StatsMobile = (props) => {
    const { t } = useTranslation();

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        swipe: true,
        touchMove: true
    };

    return (
        <Box className={styles.stats}>
            <Slider {...settings}>
                <Box className={styles.statsBox}>
                    <PlayArrowIcon
                        sx={{
                            backgroundColor: colors.primary,
                            width: '35px',
                            height: '35px',
                            borderRadius: '50%'
                        }}
                    />
                    <Typography fontSize={'30px'}>
                        {getSymbolForCurrency(props.currency)} {props.totalEarned}
                    </Typography>
                    <Typography sx={{ opacity: 0.5 }}>{t('TotalEarned')}</Typography>
                </Box>
                <Box className={styles.statsBox}>
                    <PlayArrowIcon
                        sx={{
                            backgroundColor: colors.secondary,
                            width: '35px',
                            height: '35px',
                            borderRadius: '50%'
                        }}
                    />
                    <Typography fontSize={'30px'}>{props.totalStreams}</Typography>
                    <Typography sx={{ opacity: 0.5 }}>{t('TotalStreams')}</Typography>
                </Box>
                <Box className={styles.statsBox}>
                    <PlayArrowIcon
                        sx={{
                            backgroundColor: colors.primary,
                            width: '35px',
                            height: '35px',
                            borderRadius: '50%'
                        }}
                    />
                    <Typography fontSize={'30px'}>{props.totalStorage}/100GB</Typography>
                    <Typography sx={{ opacity: 0.5 }}>{t('TotalStorage')}</Typography>
                </Box>
            </Slider>
        </Box>
    );
};

export default StatsMobile;
