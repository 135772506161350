import { Button, Modal, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import CheckIcon from '@mui/icons-material/Check';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
    PLATFORM_PRICES_LOAD_FINISHED,
    SET_USER_DATA,
    SPINNER_VISIBLE
} from '../../../constants/actionTypes';
import { default as api, default as fetch } from '../../../fetch';
import OnBack from '../../Auth/OnBack';
import stylesModal from '../../Profile/Customize/customize.module.scss';
import styles from '../Customize/customize.module.scss';

const MONTH = 'month';
const YEAR = 'year';

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser
    };
};

const mapDispatchToProps = (dispatch) => ({
    onLoad: (payload) => dispatch({ type: SET_USER_DATA, payload }),
    onSpinner: (payload) => dispatch({ type: SPINNER_VISIBLE, payload })
});

const ManageSubscriptions = (props) => {
    const { onLoad } = props;
    const [planSelected, setPlanSelected] = useState(false);
    const { t } = useTranslation();
    const history = useHistory();
    const [activeTab, setActiveTab] = useState(0);
    const company = useSelector((state) => state.common.company);
    const user = useSelector((state) => state.user.currentUser);
    const platform_prices = useSelector((state) => state.prices.platform_prices);

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [prices, setPrices] = useState([]);
    const [refetch, setRefetch] = useState(false);
    const ACTIVE_FILTER = [MONTH, YEAR];
    const [openModal, setOpenModal] = useState(false);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const fetchPrices = async () => {
        return await fetch.Prices.List();
    };

    console.log('user.premium_services?.premium: ', user?.premium_services);

    useEffect(() => {
        if (user.premium_services?.premium) {
            setActiveTab(ACTIVE_FILTER.findIndex((e) => e === user.premium_services.interval));
        }
    }, [props.user]);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const prices = await fetchPrices();
            dispatch({ type: PLATFORM_PRICES_LOAD_FINISHED, payload: prices });
            setLoading(false);
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (platform_prices) {
            const interval = ACTIVE_FILTER[activeTab];
            const filtered = platform_prices
                .filter((p) => p.metadata.interval === interval)
                .map((p) => ({ price: p.id }));
            setPrices(filtered);
        }
    }, [activeTab, platform_prices]);

    const selectPlan = async () => {
        const metadata = {
            companyId: company._id,
            customer_email: user.data.email
        };
        try {
            props.onSpinner(true);
            const checkout = await fetch.Checkout.Create(
                prices,
                window.location.href,
                user.data.email,
                null,
                metadata
            );
            window.location.replace(checkout.url);
        } catch (error) {
            console.log(error.message);
        } finally {
            props.onSpinner(false);
        }
    };

    const cancelSubscription = async (subscriptionId) => {
        try {
            props.onSpinner(true);
            await fetch.Subscription.Update(subscriptionId, null);
            setTimeout(refreshSubscriptionStatus, 1000);
        } catch (error) {
            console.log(error.message);
        } finally {
            props.onSpinner(false);
        }
    };

    const refreshSubscriptionStatus = async () => {
        const subscriptionStatus = await api.StripeAccount.getSubscriptionStatus(
            user.data.email,
            process.env.REACT_APP_COMPANY_ID,
            undefined
        );
        onLoad({ ...user, ...{ premium_services: subscriptionStatus } });
    };

    return (
        <div>
            <OnBack defaultHref={'/profile'} title={t('SelectYourPlan')} />
            <div className={styles.profileWrapper} style={{ minHeight: '100%' }}>
                <div className={styles.header}></div>
                <div style={{ padding: '2vh 2vw' }}>
                    <Typography sx={{ color: 'rgba(60, 60, 67, 0.6)' }} textAlign={'center'}>
                        {t('ManageSubscriptionsTip1')}
                    </Typography>
                    <Typography sx={{ color: 'rgba(60, 60, 67, 0.6)' }} textAlign={'center'}>
                        {t('ManageSubscriptionsTip2')}
                    </Typography>
                    <Tabs
                        variant="fullWidth"
                        textColor="primary"
                        value={activeTab}
                        onChange={handleTabChange}>
                        <Tab label="Monthly" />
                        <Tab label="Anually" />
                    </Tabs>
                    <Typography
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            margin: '1vh 15vw 2vh 0'
                        }}>
                        {t('Discount')}
                    </Typography>
                    {activeTab === 0 && (
                        <div
                            style={{
                                border: '1px solid #CCC',
                                borderRadius: '16px',
                                textAlign: 'center',
                                padding: '5%',
                                backgroundColor:
                                    planSelected || user.premium_services?.interval === MONTH
                                        ? 'black'
                                        : '',
                                color:
                                    planSelected || user.premium_services?.interval === MONTH
                                        ? 'white'
                                        : ''
                            }}>
                            <div>
                                <Button
                                    sx={{
                                        backgroundColor: '#32E7B2',
                                        color: 'black !important',
                                        borderRadius: '48px',
                                        textTransform: 'capitalize',
                                        width: '100%',
                                        maxWidth:
                                            planSelected ||
                                            user.premium_services?.interval === MONTH
                                                ? isMobile
                                                    ? '60vw'
                                                    : '20vw'
                                                : isMobile
                                                ? '52vw'
                                                : '15vw',
                                        '&:active, &:focus, &:hover': {
                                            backgroundColor: '#32E7B2'
                                        }
                                    }}>
                                    {planSelected || user.premium_services?.interval === MONTH
                                        ? 'Your selected plan'
                                        : 'Most popular'}
                                </Button>
                                <Typography sx={{ margin: '2vh 0' }}>FNTSY Basic</Typography>
                                <Typography fontSize={'24px'}>€11.99 / month</Typography>

                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        margin: '2vh 0',
                                        borderTop: '1px solid  #CCC',
                                        paddingTop: '3vh'
                                    }}>
                                    <div
                                        style={{
                                            display: 'grid',
                                            gridTemplateColumns: isMobile
                                                ? '25px 40vw '
                                                : '25px 17vw'
                                        }}>
                                        <CheckIcon color="primary" />
                                        <Typography sx={{ marginLeft: '2vw', textAlign: 'left' }}>
                                            Unlimited account
                                        </Typography>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        margin: '2vh 0'
                                    }}>
                                    <div
                                        style={{
                                            display: 'grid',
                                            gridTemplateColumns: isMobile
                                                ? '25px 40vw '
                                                : '25px 17vw'
                                        }}>
                                        <CheckIcon color="primary" />
                                        <Typography sx={{ marginLeft: '2vw', textAlign: 'left' }}>
                                            Custom domain
                                        </Typography>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        margin: '2vh 0'
                                    }}>
                                    <div
                                        style={{
                                            display: 'grid',
                                            gridTemplateColumns: isMobile
                                                ? '25px 40vw '
                                                : '25px 17vw'
                                        }}>
                                        <CheckIcon color="primary" />
                                        <Typography sx={{ marginLeft: '2vw', textAlign: 'left' }}>
                                            50 GB storage
                                        </Typography>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        margin: '2vh 0'
                                    }}>
                                    <div
                                        style={{
                                            display: 'grid',
                                            gridTemplateColumns: isMobile
                                                ? '25px 40vw '
                                                : '25px 17vw'
                                        }}>
                                        <CheckIcon color="primary" />
                                        <Typography sx={{ marginLeft: '2vw', textAlign: 'left' }}>
                                            Unlimited streaming
                                        </Typography>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        margin: '1vh 0'
                                    }}>
                                    <div
                                        style={{
                                            display: 'grid',
                                            gridTemplateColumns: isMobile
                                                ? '25px 40vw '
                                                : '25px 17vw'
                                        }}>
                                        <CheckIcon color="primary" />
                                        <Typography sx={{ marginLeft: '2vw', textAlign: 'left' }}>
                                            Unlimited traffic
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                            <Button
                                className={styles.previewButton}
                                fullWidth
                                onClick={() => {
                                    if (!user.premium_services?.premium) {
                                        setPlanSelected(!planSelected);
                                        selectPlan();
                                    } else if (user.premium_services?.premium) {
                                        setOpenModal(true);
                                    }
                                }}
                                sx={{
                                    backgroundColor: 'black !important',
                                    color: 'white !important',
                                    width: '100%',
                                    height: '8vh',
                                    marginTop: '5vh',
                                    border:
                                        planSelected || user.premium_services?.interval === MONTH
                                            ? '1px solid white !important'
                                            : '',
                                    '&.MuiButton-active': {
                                        backgroundColor: 'black'
                                    }
                                }}>
                                {user.premium_services?.interval
                                    ? 'Cancel Subscription'
                                    : 'Select Plan'}
                            </Button>
                        </div>
                    )}
                    {activeTab === 1 && (
                        <div
                            style={{
                                border: '1px solid #CCC',
                                borderRadius: '16px',
                                textAlign: 'center',
                                padding: '5%',
                                backgroundColor:
                                    planSelected || user.premium_services?.interval === YEAR
                                        ? 'black'
                                        : '',
                                color:
                                    planSelected || user.premium_services?.interval === YEAR
                                        ? 'white'
                                        : ''
                            }}>
                            <div>
                                <Button
                                    sx={{
                                        backgroundColor: '#32E7B2',
                                        color: 'black !important',
                                        borderRadius: '48px',
                                        textTransform: 'capitalize',
                                        width: '100%',
                                        maxWidth:
                                            planSelected || user.premium_services?.interval === YEAR
                                                ? isMobile
                                                    ? '60vw'
                                                    : '20vw'
                                                : isMobile
                                                ? '52vw'
                                                : '15vw',
                                        '&:active, &:focus, &:hover': {
                                            backgroundColor: '#32E7B2'
                                        }
                                    }}>
                                    {planSelected || user.premium_services?.interval === YEAR
                                        ? 'Your selected plan'
                                        : 'Most popular'}
                                </Button>
                                <Typography sx={{ margin: '2vh 0' }}>FNTSY Basic</Typography>
                                <Typography fontSize={'24px'}>€99.99 / year</Typography>

                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        margin: '2vh 0',
                                        borderTop: '1px solid  #CCC',
                                        paddingTop: '3vh'
                                    }}>
                                    <div
                                        style={{
                                            display: 'grid',
                                            gridTemplateColumns: isMobile
                                                ? '25px 40vw '
                                                : '25px 17vw'
                                        }}>
                                        <CheckIcon color="primary" />
                                        <Typography sx={{ marginLeft: '2vw', textAlign: 'left' }}>
                                            Unlimited account
                                        </Typography>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        margin: '2vh 0'
                                    }}>
                                    <div
                                        style={{
                                            display: 'grid',
                                            gridTemplateColumns: isMobile
                                                ? '25px 40vw '
                                                : '25px 17vw'
                                        }}>
                                        <CheckIcon color="primary" />
                                        <Typography sx={{ marginLeft: '2vw', textAlign: 'left' }}>
                                            Custom domain
                                        </Typography>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        margin: '2vh 0'
                                    }}>
                                    <div
                                        style={{
                                            display: 'grid',
                                            gridTemplateColumns: isMobile
                                                ? '25px 40vw '
                                                : '25px 17vw'
                                        }}>
                                        <CheckIcon color="primary" />
                                        <Typography sx={{ marginLeft: '2vw', textAlign: 'left' }}>
                                            50 GB storage
                                        </Typography>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        margin: '2vh 0'
                                    }}>
                                    <div
                                        style={{
                                            display: 'grid',
                                            gridTemplateColumns: isMobile
                                                ? '25px 40vw '
                                                : '25px 17vw'
                                        }}>
                                        <CheckIcon color="primary" />
                                        <Typography sx={{ marginLeft: '2vw', textAlign: 'left' }}>
                                            Unlimited streaming
                                        </Typography>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        margin: '1vh 0'
                                    }}>
                                    <div
                                        style={{
                                            display: 'grid',
                                            gridTemplateColumns: isMobile
                                                ? '25px 40vw '
                                                : '25px 17vw'
                                        }}>
                                        <CheckIcon color="primary" />
                                        <Typography sx={{ marginLeft: '2vw', textAlign: 'left' }}>
                                            Unlimited traffic
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                            <Button
                                onClick={() => {
                                    if (!user.premium_services?.premium) {
                                        setPlanSelected(!planSelected);
                                        selectPlan();
                                    } else if (user.premium_services?.premium) {
                                        setOpenModal(true);
                                    }
                                }}
                                sx={{
                                    backgroundColor: 'black !important',
                                    color: 'white !important',
                                    width: '100%',
                                    textTransform: 'none !important',
                                    height: '8vh',
                                    marginTop: '5vh',
                                    border:
                                        planSelected || user.premium_services?.interval === YEAR
                                            ? '1px solid white !important'
                                            : '',
                                    '&.MuiButton-active': {
                                        backgroundColor: 'black'
                                    }
                                }}>
                                {user?.premium_services?.premium
                                    ? 'Cancel Subscription'
                                    : 'Select Plan'}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
            <Modal open={openModal} onClose={() => setOpenModal(false)}>
                <div className={isMobile ? stylesModal.modalMobile : stylesModal.modal}>
                    <Typography
                        marginTop={isMobile ? '3%' : '-1%'}
                        fontSize={'18px'}
                        textAlign={'center'}>
                        {t('CancelSubscription')}
                    </Typography>
                    <div className={stylesModal.flexContainer}>
                        <Button
                            className={
                                isMobile
                                    ? stylesModal.confirmButtonMobile
                                    : stylesModal.confirmButton
                            }
                            onClick={() => {
                                cancelSubscription(user.premium_services.subscription);
                                setOpenModal(false);
                            }}>
                            {t('Yes')}
                        </Button>
                        <Button
                            className={
                                isMobile ? styles.cancelButtonMobile : stylesModal.cancelButton
                            }
                            onClick={() => {
                                setOpenModal(false);
                            }}>
                            {t('No')}
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageSubscriptions);
