import {
    Box,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import sanity from '../../../../sanity';
import {
    CONVERTING_STATUS,
    UPLOADING_STATUS,
    CONVERT_STATUS,
    NOT_PUBLISHED
} from '../../../../constants';
import Event from '../../../../core/entitites/event.entity';
import styles from '../profile.module.scss';
import EventThumbnail from '../../../Event/EventThumbnail';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import colors from '../../../../utils/colors';
import { isMobile } from 'react-device-detect';
import fetch from '../../../../fetch';
import { getSymbolForCurrency } from '../../../../utils';
import { fromUnitAmount } from '../../../../utils/unitAmountHelper';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';

const mapStateToProps = (state) => ({
    events: state.event.events,
    company: state.common.company,
    upload: state.common.upload
});

const Analytics = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [events, setEvents] = useState([]);
    const [videos, setVideos] = useState([]);
    const month = 30 * 24 * 60 * 60;
    const week = 7 * 24 * 60 * 60;
    const day = 24 * 60 * 60;
    const [filter, setFilter] = useState(month);
    const today = new Date();

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                setLoading(true);
                let events = await sanity.Events.GetEvents(true);

                const publishingEvents = events
                    .filter(
                        (event) =>
                            event.processingStatus === CONVERTING_STATUS ||
                            event.processingStatus === UPLOADING_STATUS ||
                            event.processingStatus === CONVERT_STATUS
                    )
                    .map((e) => {
                        const runtimeProps = props.upload.find(
                            (uploadedEvent) => uploadedEvent.jobId === e.correlation_id
                        );
                        return runtimeProps ||
                            e.processingStatus === CONVERTING_STATUS ||
                            e.processingStatus === CONVERT_STATUS
                            ? Event.new({ ...e, ...runtimeProps })
                            : null;
                    })
                    .filter((e) => e !== null);
                const eventList = events.filter(
                    (e) =>
                        !publishingEvents.some((p) => p.id === e.id) &&
                        e.stream_status !== NOT_PUBLISHED
                );
                setEvents(eventList);
            } catch (error) {
                console.log(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchVideos();
    }, [props.events, props.upload, props.company]);

    useEffect(() => {
        const fetchEarnings = async () => {
            try {
                if (props.company && events) {
                    setLoading(true);
                    const result = await fetch.StripeAccount.getEarnings(
                        props.company._id,
                        Math.floor(today.getTime() / 1000) - filter
                    );
                    const earnings = result.earnings;
                    const paidVideos = Object.entries(earnings).flatMap(([key, value]) => {
                        const currencyKey = Object.keys(value)[0];
                        const currencyData = value[currencyKey];

                        return events
                            .filter((event) => event.id === key)
                            .map((event) => {
                                Object.assign(event, currencyData, { currency: currencyKey });
                                return event;
                            });
                    });
                    setVideos(paidVideos);
                }
            } catch (error) {
                setLoading(false);
                console.log('ERROR: ', error);
            } finally {
                setLoading(false);
            }
        };
        fetchEarnings();
    }, [props.company, filter, events]);

    const handleChange = (event) => {
        setFilter(event.target.value);
    };

    return (
        <Box>
            <Typography
                sx={{ marginBottom: '1rem', borderBottom: '1px solid rgba(0, 0, 0, 0.2)' }}
                fontSize={'36px'}>
                {t('Analytics')}
            </Typography>
            <Box className={styles.flexBetween}>
                <Typography>{t('ContentPerforming')}</Typography>
                <FormControl sx={{ width: '50%' }}>
                    <InputLabel
                        sx={{
                            top: '-1vh',
                            color: 'black !important'
                        }}
                        id="demo-simple-select-label">
                        Filter view
                    </InputLabel>
                    <Select
                        sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#F8F8F8'
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#F8F8F8'
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#F8F8F8'
                            },
                            '&.Mui-focused': {
                                backgroundColor: '#F8F8F8'
                            },
                            '& .MuiSelect-select': {
                                backgroundColor: '#F8F8F8'
                            },
                            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                                {
                                    borderColor: '#F8F8F8'
                                },
                            backgroundColor: '#F8F8F8',
                            borderRadius: '12px',
                            height: '5vh'
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filter}
                        label="Filter view"
                        onChange={handleChange}>
                        <MenuItem value={month}>Last 30 days</MenuItem>
                        <MenuItem value={week}>Last 7 days</MenuItem>
                        <MenuItem value={day}>Last 24 hours</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            {loading ? (
                <Box
                    sx={{
                        marginTop: '2rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                    <CircularProgress sx={{ color: colors.primary }} />
                </Box>
            ) : (
                <>
                    {isMobile ? (
                        <Box sx={{ marginTop: '2rem' }}>
                            {videos.map((video) => (
                                <Box className={styles.videos} key={video.id}>
                                    <Box className={styles.flexBetween2}>
                                        <Box className={styles.flexBetween} sx={{ gap: '1rem' }}>
                                            <EventThumbnail
                                                borderRadius={'12px'}
                                                event={video}
                                                width={70}
                                                height={70}
                                            />
                                            <Box>
                                                <Typography fontSize={'20px'}>
                                                    {video.title}
                                                </Typography>
                                                <Typography sx={{ opacity: 0.5 }} fontSize={'16px'}>
                                                    {video.description}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box
                                            style={{
                                                width: '35px',
                                                height: '35px',
                                                backgroundColor:
                                                    video.trend > 0
                                                        ? colors.primary
                                                        : video.trend === 0
                                                        ? '#F8F8F8'
                                                        : colors.secondary,
                                                borderRadius: '50%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                cursor: 'pointer'
                                            }}>
                                            {video.trend > 0 ? (
                                                <TrendingUpIcon />
                                            ) : video.trend === 0 ? (
                                                <TrendingFlatIcon />
                                            ) : (
                                                <TrendingDownIcon />
                                            )}
                                        </Box>
                                    </Box>
                                    <Box className={styles.grid}>
                                        <Box>
                                            <Typography fontSize={'20px'}>
                                                {' '}
                                                {getSymbolForCurrency(video.currency)}{' '}
                                                {fromUnitAmount(video.total)}
                                            </Typography>
                                            <Typography sx={{ opacity: 0.5 }} fontSize={'16px'}>
                                                {t('TotalEarnings')}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography fontSize={'20px'}>{video.count}</Typography>
                                            <Typography sx={{ opacity: 0.5 }} fontSize={'16px'}>
                                                {t('Streams')}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    ) : (
                        <Box sx={{ marginTop: '4rem' }}>
                            {videos.map((video) => (
                                <Box className={styles.videos} key={video.id}>
                                    <Box className={styles.flexBetween} sx={{ gap: '1rem' }}>
                                        <EventThumbnail
                                            borderRadius={'12px'}
                                            event={video}
                                            width={200}
                                            height={100}
                                        />
                                        <Box>
                                            <Typography fontSize={'20px'}>{video.title}</Typography>
                                            <Typography sx={{ opacity: 0.5 }} fontSize={'16px'}>
                                                {video.description}
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Box>
                                        <Typography fontSize={'20px'}>
                                            {' '}
                                            {getSymbolForCurrency(video.currency)}{' '}
                                            {fromUnitAmount(video.total)}
                                        </Typography>
                                        <Typography sx={{ opacity: 0.5 }} fontSize={'16px'}>
                                            {t('TotalEarnings')}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography fontSize={'20px'}>{video.count}</Typography>
                                        <Typography sx={{ opacity: 0.5 }} fontSize={'16px'}>
                                            {t('Streams')}
                                        </Typography>
                                    </Box>
                                    <Box
                                        style={{
                                            width: '35px',
                                            height: '35px',
                                            backgroundColor: colors.primary,
                                            borderRadius: '50%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            cursor: 'pointer'
                                        }}>
                                        <TrendingUpIcon />
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    )}
                </>
            )}
        </Box>
    );
};

export default connect(mapStateToProps, null)(Analytics);
