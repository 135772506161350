import { goBack } from 'connected-react-router';
import { connect } from 'react-redux';
import searchIcon from '../../assets/images/search-icon.svg';

import React, { useEffect, useState } from 'react';
import { MAX_INVITES } from '../../constants';
import { INVITE_BY_SMS, SET_USER_DATA, SPINNER_VISIBLE } from '../../constants/actionTypes';
import OnBack from '../Auth/OnBack';
import styles from './invite.module.scss';
// import PhoneInput from 'react-phone-number-input';
import { useTranslation } from 'react-i18next';
import cognitoService from '../../core/service/cognito.service';

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser,
        company: state.common.company
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGoBack: () => dispatch(goBack()),
    sendSMS: (phoneNumber, name) => {
        dispatch({ type: INVITE_BY_SMS, payload: { phoneNumber, name } });
    },
    onSetUserData: (payload) => dispatch({ type: SET_USER_DATA, payload }),
    onSpinner: (payload) => dispatch({ type: SPINNER_VISIBLE, payload })
});

const InviteView = (props) => {
    // const DEFAULT_COUNTRY_CODE = '+46';
    const { t } = useTranslation();
    const { data: user } = props.user;
    const [filter, setFilter] = useState('');
    const [contacts, setContacts] = useState([]);
    const [filteredContacts, setFilteredContacts] = useState([]);
    const [invitesLeft, setInvitesLeft] = useState(0);
    const [title, setTitle] = useState('Du har ' + invitesLeft + ' inbjudningar');

    // const [manualPhoneNumber, setManualPhoneNumber] = useState('');
    // console.log(manualPhoneNumber);

    useEffect(() => {
        const userInvitesLeft =
            user['custom:invites_left'] === undefined ? MAX_INVITES : user['custom:invites_left'];
        setInvitesLeft(userInvitesLeft);
        setTitle(t('InvitesLeft', { invites_left: userInvitesLeft }));
        if (contacts.length === 0) {
            window.ContactsX &&
                window.ContactsX.find(
                    function (success) {
                        props.onSpinner(true);

                        let promises = success.map(async (contact) => {
                            contact.initials = initialsOf(contact);

                            if (!isNumeric(contact.firstName) && !isNumeric(contact.familyName)) {
                                const firstName = contact.firstName ? contact.firstName : '';
                                const familyName = contact.familyName ? contact.familyName : '';
                                contact.display = firstName + ' ' + familyName;
                            }

                            let phoneNumber = '';
                            if (contact.phoneNumbers && contact.phoneNumbers.length > 0) {
                                phoneNumber = contact.phoneNumbers[0].value;
                                phoneNumber = phoneNumber.replaceAll(' ', '');
                                const code = await getCode(phoneNumber);
                                if (!phoneNumber.startsWith('00') && !phoneNumber.startsWith('+')) {
                                    phoneNumber = code + phoneNumber;
                                }
                            } else if (
                                isNumeric(contact.firstName) &&
                                isNumeric(contact.familyName) &&
                                !contact.phoneNumber
                            ) {
                                // contact.display = contact.displayName;
                                phoneNumber = contact.displayName;
                                phoneNumber = phoneNumber.replaceAll(' ', '');
                                const code = await getCode(phoneNumber);
                                if (!phoneNumber.startsWith('00') && !phoneNumber.startsWith('+')) {
                                    phoneNumber = code + phoneNumber;
                                }
                            }

                            contact.phoneNumberWithCode = phoneNumber;
                            return contact;
                        });
                        Promise.all(promises).then(() => {
                            success.sort((c1, c2) => {
                                if (c1.display && c2.display)
                                    return c1.display.localeCompare(c2.display, undefined, {
                                        numeric: true
                                    });
                                return 1;
                            });
                            setContacts(success);
                            setFilteredContacts(success);
                            console.log(success);
                            props.onSpinner(false);
                        });
                    },
                    function (error) {
                        console.error(error);
                        props.onSpinner(false);
                    },
                    {
                        fields: {
                            phoneNumbers: true
                        }
                    }
                );
        }
    }, [user]);
    const initialsOf = (contact) => {
        const firstName = contact.firstName ? contact.firstName.charAt(0) : '';
        const familyName = contact.familyName ? contact.familyName.charAt(0) : '';
        return firstName + familyName;
    };

    // const changePhoneNumber = (input) => {
    //     setManualPhoneNumber(input);
    // };

    const inviteContact = async (contact, i) => {
        if (contact.phoneNumbers && contact.phoneNumbers.length > 0 && invitesLeft > 0) {
            let phoneNumber = contact.phoneNumbers[0].value;
            const DEFAULT_COUNTRY_CODE = await getCode(phoneNumber);
            if (!phoneNumber.startsWith('00') && !phoneNumber.startsWith('+')) {
                phoneNumber = DEFAULT_COUNTRY_CODE + phoneNumber;
            }
            props.sendSMS(phoneNumber, user.given_name);
            const newContacts = [...contacts];
            newContacts[i].disabled = true;
            setContacts(newContacts);
            const newInvitesLeft = invitesLeft - 1;
            setInvitesLeft(newInvitesLeft);
            cognitoService.setInvitesLeft(newInvitesLeft);
            const userData = await cognitoService.getUserData();
            userData.data['custom:invites_left'] = newInvitesLeft;
            props.onSetUserData(userData);
            setTitle(t('InvitesLeft', { invites_left: newInvitesLeft }));
        }
    };
    const handleFilter = (e) => {
        setFilter(e.target.value);
        const filtered = [];
        contacts.forEach((contact) => {
            if (contact.display?.toLowerCase().includes(e?.target?.value?.toLowerCase())) {
                filtered.push(contact);
            }
            setFilteredContacts(filtered);
        });
        setFilteredContacts(filtered);
    };

    function isNumeric(value) {
        // alert(value);
        return /^-?\d+$/.test(value);
    }

    const getCode = async (phoneNumber) => {
        const url =
            'https://oozwn0ywvi.execute-api.eu-north-1.amazonaws.com/prod/phone-format?phone=' +
            phoneNumber;

        var data = null;

        data = await fetch(url)
            .then((response) => {
                return response.json();
            })
            .then(function (jsonData) {
                if (jsonData.isValid) {
                    return jsonData.countryCode;
                } else {
                    return '';
                }
            });
        return data;
    };

    return (
        <div>
            <OnBack defaultHref={'/events'} title={title} />
            <div className={styles.inviteWrapper}>
                <p>{t('InvitesLeft', { company_name: props.company && props.company.name })}</p>
                {/*<PhoneInput*/}
                {/*    placeholder="ex. 0708720443"*/}
                {/*    onChange={(e) => changePhoneNumber(e)}*/}
                {/*    defaultCountry="SE"*/}
                {/*    countries={availableCountries}*/}
                {/*    addInternationalOption={false}*/}
                {/*/>*/}
                <div className={styles.searchGroup}>
                    <img src={searchIcon} alt={t('SearchContacts')} />
                    <input type="text" id="header-search" onChange={handleFilter} value={filter} />
                </div>
                {filteredContacts &&
                    filteredContacts.map((contact, index) => {
                        // alert(contacts.display);
                        return (
                            <div className={styles.contact} key={contact.id}>
                                <div className={styles.profilePicture}>{contact.initials}</div>
                                <div className={styles.contactName}>
                                    {contact.display != null ? (
                                        <p className={styles.contactLabel}>{contact.display}</p>
                                    ) : (
                                        ''
                                    )}
                                    {contact.phoneNumberWithCode}
                                </div>
                                <button
                                    disabled={invitesLeft <= 0 || contact.disabled}
                                    onClick={() => inviteContact(contact, index)}>
                                    {t('Send')}
                                </button>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteView);
