import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import styles from './createVideo.module.scss';
import colors from '../../utils/colors';
import { UPLOAD_REMOVE } from '../../constants/actionTypes';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import sanity from '../../sanity';
import { CONVERT_FINISHED_STATUS } from '../../constants';
import Event from '../../core/entitites/event.entity';
import fetch from '../../fetch';

const mapStateToProps = (state) => ({
    events: state.event.events,
    isAdmin: state.user.currentUser.groups && state.user.currentUser.groups.includes('Admin'),
    user: state.user.currentUser,
    company: state.common.company,
    upload: state.common.upload,
    event: state.event.events,
    connected_account: state.common.connected_account
});

const mapDispatchToProps = (dispatch) => ({
    onRemoveUpload: (payload) => dispatch({ type: UPLOAD_REMOVE, payload })
});

const baseDomain = process.env.REACT_APP_API_URL?.split('api')[1];

const Share = (props) => {
    const { t } = useTranslation();
    const [correlationId, setCorrelationId] = useState('');
    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showOnboardingMessage, setShowOnboardingMessage] = useState(false);
    const [onboardingLoader, setOnboardingLoader] = useState(false);
    const [stripeErrorMessage, setStripeErrorMessage] = useState('');

    useEffect(() => {
        const fetchId = async () => {
            const correlationId = await props.createEvent();
            setCorrelationId(correlationId);
        };
        if (props.selectedCard === 3) {
            if (props.connected_account?.onboarded) {
                if (props.videoProgress?.upload_url) {
                    if (props.hasTrailer) {
                        if (props.videoProgress?.trailer_url) {
                            fetchId();
                        }
                    } else {
                        fetchId();
                    }
                }
            } else {
                setShowOnboardingMessage(true);
            }
        }
        // console.log('props.videoProgress', props.videoProgress);
    }, [props.selecedCard, props.videoProgress]);

    useEffect(() => {
        if (correlationId) {
            if (correlationId === props.event[props.event.length - 1]?.correlation_id) {
                setEvent(props.event[props.event.length - 1]);
            }
        }
    }, [props.event, correlationId]);

    useEffect(() => {
        if (correlationId && event) {
            if (
                correlationId === event?.correlation_id &&
                event?.processingStatus === CONVERT_FINISHED_STATUS
            ) {
                props.setLinkCreated(true);
            }
        }
    }, [event, correlationId]);

    const sendMail = async () => {
        try {
            setLoading(true);
            await fetch.Event.EventShareMail(event.id);
        } catch (error) {
            console.log('ERROR: ', error);
        } finally {
            setLoading(false);
            props.setSelectedCard(1);
        }
    };

    const metadata = props.connected_account?.metadata
        ? JSON.parse(props.connected_account?.metadata)
        : {};

    const stripeOnboard = async () => {
        try {
            setOnboardingLoader(true);
            let localDomain = undefined;
            const hostname = window.location.hostname;
            if (hostname === 'localhost' || hostname === '127.0.0.1') {
                localDomain = 'http://localhost:3000';
            }
            const domain = !props.company.domain
                ? `${props.company?.name}${baseDomain}`
                : props.company.domain;
            const companyDomain = localDomain || `https://${domain}`;
            const stripeAccountOnboardingInput = {
                companyId: props.company._id,
                companyDomain,
                country: metadata.country,
                email: metadata.email,
                business_type: metadata.business_type,
                firstName: metadata.firstName,
                lastName: metadata.lastName,
                callback_path: '/home'
            };
            let stripeLink = await fetch.StripeAccount.createAccountStripe({
                method: 'POST',
                body: JSON.stringify(stripeAccountOnboardingInput)
            });

            if (stripeLink) {
                window.location.replace(stripeLink.url);
            }
        } catch (e) {
            setOnboardingLoader(false);
            setStripeErrorMessage(e.message);
            return;
        } finally {
            setOnboardingLoader(false);
        }
    };

    return (
        <>
            {correlationId === event?.correlation_id &&
            event?.processingStatus === CONVERT_FINISHED_STATUS ? (
                <>
                    <Typography
                        fontSize={'36px'}
                        color={'white'}
                        fontFamily={'InstrumentSans-Bold'}>
                        {t('ShareLink')}
                    </Typography>
                    <Typography color={'white'} fontSize={'20px'}>
                        {t('AllSet')}
                    </Typography>
                </>
            ) : (
                <>
                    <Typography
                        fontSize={'36px'}
                        color={'white'}
                        fontFamily={'InstrumentSans-Bold'}>
                        {t('CreateLink')}
                    </Typography>
                    <Typography color={'white'} fontSize={'20px'}>
                        {t('EarnMoney')}
                    </Typography>
                </>
            )}
            {showOnboardingMessage ? (
                <Box className={styles.shareContent}>
                    {props?.videoProgress?.upload_show && <Box className={styles.flexCenter}></Box>}
                    <Box sx={{ marginTop: '1rem' }}>
                        <Typography fontSize={'18px'} textAlign={'center'} color={colors.primary}>
                            {t('RegisterBankAccountHeader')}
                        </Typography>
                        <Typography
                            fontSize={'14px'}
                            textAlign={'center'}
                            sx={{ marginTop: '1rem' }}>
                            {t('RegisterBankAccountText')}
                        </Typography>
                        {onboardingLoader ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItem: 'center',
                                    marginTop: '1rem'
                                }}>
                                <CircularProgress sx={{ color: colors.primary }} size={60} />
                            </Box>
                        ) : (
                            <Button
                                onClick={() => stripeOnboard()}
                                sx={{
                                    '&:active, &:focus, &:hover': {
                                        background: 'white',
                                        color: 'black'
                                    }
                                }}
                                fullWidth>
                                {t('RegisterBankAccount')}
                            </Button>
                        )}
                        {stripeErrorMessage && (
                            <Typography color={'red'}>{stripeErrorMessage}</Typography>
                        )}
                    </Box>
                </Box>
            ) : (
                <>
                    {props?.videoProgress?.upload_show ? (
                        <Box className={styles.shareContent}>
                            <Typography fontSize={'18px'} textAlign={'center'}>
                                {t('UploadWait')}
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItem: 'center',
                                    marginTop: '3rem'
                                }}>
                                <CircularProgressWithLabel
                                    color={colors.primary}
                                    showValue
                                    textColor={'white'}
                                    size={70}
                                    value={props?.videoProgress?.upload_progress}
                                />
                            </Box>
                        </Box>
                    ) : !correlationId || !event ? (
                        <Box className={styles.shareContent}>
                            <Typography fontSize={'18px'} textAlign={'center'}>
                                {t('UploadWait')}
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItem: 'center',
                                    marginTop: '3rem'
                                }}>
                                <CircularProgressWithLabel
                                    color={colors.primary}
                                    showValue
                                    textColor={'white'}
                                    size={70}
                                    value={99}
                                />
                            </Box>
                        </Box>
                    ) : correlationId &&
                      event &&
                      event?.processingStatus !== CONVERT_FINISHED_STATUS ? (
                        <Box className={styles.shareContent}>
                            {props?.videoProgress?.upload_show && (
                                <Box className={styles.flexCenter}></Box>
                            )}
                            <Box sx={{ marginTop: '1rem' }}>
                                <Typography
                                    fontSize={'14px'}
                                    textAlign={'center'}
                                    sx={{ marginTop: '1rem', opacity: '0.5' }}>
                                    {/* {t('Notify')} */}
                                    Your upload has finished, we are publishing the video. This may
                                    take a while but you can click the button in order to be
                                    notified when the publishing is done
                                </Typography>
                                {loading ? (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItem: 'center',
                                            marginTop: '2rem'
                                        }}>
                                        <CircularProgress
                                            sx={{ color: colors.primary }}
                                            size={60}
                                        />
                                    </Box>
                                ) : (
                                    <Button
                                        onClick={() => sendMail()}
                                        sx={{
                                            '&:active, &:focus, &:hover': {
                                                background: 'white',
                                                color: 'black'
                                            }
                                        }}
                                        fullWidth>
                                        {t('MailMe')}
                                    </Button>
                                )}
                            </Box>
                        </Box>
                    ) : correlationId === event?.correlation_id &&
                      event?.processingStatus === CONVERT_FINISHED_STATUS ? (
                        <Box className={styles.readyLink}>
                            <Typography>{t('YourWork')}</Typography>
                            <Box position="relative">
                                <TextField
                                    sx={{
                                        borderRadius: '16px',
                                        height: 'auto',
                                        backgroundColor: colors.primaryLightGray,
                                        '& .MuiInputBase-input::placeholder': {
                                            color: 'black',
                                            opacity: 1
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                border: 'none'
                                            }
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            // <Box
                                            //     position="absolute"
                                            //     right="8px"
                                            //     top="50%"
                                            //     transform="translateY(-50%)">
                                            <Button
                                                onClick={() =>
                                                    navigator.clipboard.writeText(
                                                        `${window.location.origin}/events/${event.slug}`
                                                    )
                                                }
                                                sx={{
                                                    '&:hover': {
                                                        backgroundColor: colors.primary
                                                    }
                                                }}>
                                                {t('CopyLink')}
                                            </Button>
                                            // </Box>
                                        )
                                    }}
                                    value={
                                        `${window.location.origin}/events/${event.slug}`.length > 20
                                            ? `${`${window.location.origin}/events/${event.slug}`.substring(
                                                  0,
                                                  23
                                              )}...`
                                            : `${window.location.origin}/events/${event.slug}`
                                    }
                                    fullWidth
                                />
                            </Box>

                            <Typography
                                sx={{ marginTop: '1rem' }}
                                color={'#6C6C6C'}
                                fontSize={'12px'}
                                textAlign={'center'}>
                                {t('FindLinks')}
                                <span style={{ color: 'black' }}> {t('Library')}</span>{' '}
                            </Typography>
                        </Box>
                    ) : null}
                </>
            )}
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Share);
