import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { SET_USER_DATA, SOCKET_DISCONNNECT } from '../../constants/actionTypes';
import cognitoService from '../../core/service/cognito.service';
import OnBack from '../Auth/OnBack';
import styles from '../Profile/Customize/customize.module.scss';

const mapDispatchToProps = (dispatch) => ({
    onLogout: () => {
        dispatch({
            type: SET_USER_DATA,
            payload: {
                data: null,
                token: null,
                loaded: true,
                imagePreview: null,
                name: null,
                nickname: null,
                step: false,
                surname: null,
                username: null
            }
        });
        dispatch({ type: SOCKET_DISCONNNECT });
    }
});

const mapStateToProps = (state) => ({
    company: state.common.company,
    isAdmin: state.user.currentUser.groups && state.user.currentUser.groups.includes('Admin'),
    connected_account: state.common.connected_account
});

const Settings = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    let images = '/images';
    const premiumOnly = process.env.REACT_APP_PREMIUM_ONLY === 'true';

    if (window.cordova) {
        images = 'images';
    }

    const deleteCookie = (name) => {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    };

    const onLogout = () => {
        cognitoService.logoutUser();
        props.onLogout();
        deleteCookie('PremiumStatus');
        history.push('/');
    };

    return (
        <div>
            <OnBack defaultHref={'/profile'} title={t('AccountSettings')} />
            <div className={styles.profileWrapper} style={{ minHeight: '100%' }}>
                <div className={styles.header}></div>
                <div style={{ padding: '6vh 2vw 0vh 2vw' }}>
                    {!premiumOnly && (
                        <Link to={`/profile/settings/account`}>
                            <div className={styles.itemContainer}>
                                <div>
                                    <Typography className={styles.itemTitle}>
                                        {t('Account')}
                                    </Typography>
                                    <Typography className={styles.itemSubtitle}>
                                        {t('NameAndPicture')}
                                    </Typography>
                                </div>
                                <ChevronRightIcon />
                            </div>
                        </Link>
                    )}
                    {props.connected_account?.onboarded && props.isAdmin ? (
                        <Link to={`/profile/settings/products`}>
                            <div className={styles.itemContainer}>
                                <div>
                                    <Typography className={styles.itemTitle}>
                                        {t('ProductConfiguration')}
                                    </Typography>
                                    <Typography className={styles.itemSubtitle}>
                                        {t('Product')}
                                    </Typography>
                                </div>
                                <ChevronRightIcon />
                            </div>
                        </Link>
                    ) : (
                        <p />
                    )}
                    {!premiumOnly && (
                        <>
                            <Link to={`/profile/settings/preferences`}>
                                <div className={styles.itemContainer}>
                                    <div>
                                        <Typography className={styles.itemTitle}>
                                            {t('Preferences')}
                                        </Typography>
                                        <Typography className={styles.itemSubtitle}>
                                            {t('Video')}
                                        </Typography>
                                    </div>
                                    <ChevronRightIcon />
                                </div>
                            </Link>
                            <Link to={`/profile/settings/secrecy`}>
                                <div className={styles.itemContainer}>
                                    <div>
                                        <Typography className={styles.itemTitle}>
                                            {t('Secrecy')}
                                        </Typography>
                                        <Typography className={styles.itemSubtitle}>
                                            {t('PrivacySettings')}
                                        </Typography>
                                    </div>
                                    <ChevronRightIcon />
                                </div>
                            </Link>
                        </>
                    )}
                    <Link to={`/profile/settings/account/deletion`}>
                        <span
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                color: 'red',
                                textDecoration: 'underline',
                                textAlign: 'center'
                            }}>
                            {t('DeleteAccount')}
                        </span>
                    </Link>
                    <div
                        style={{
                            marginTop: '10em',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                        <Typography className={styles.itemSubtitle}>
                            “{props?.company?.name}” appversion v.1.0.0.0
                        </Typography>
                        <Button
                            variant="outlined"
                            color="gray"
                            fullWidth
                            onClick={onLogout}
                            sx={{
                                color: 'gray',
                                marginTop: '2em',
                                '&:active, &:focus, &:hover': { backgroundColor: 'white' }
                            }}>
                            Log out
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
