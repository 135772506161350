import { Box, Card, CardContent, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import backgroundPhoto from '../../assets/images/homeImage.png';
import { CONVERT_STATUS, UPLOADING_STATUS } from '../../constants';
import {
    CONNECT_PRICE_CHANGE,
    CREATE_EVENT,
    SCHEDULE_CREATE_EVENT
} from '../../constants/actionTypes';
import fetchData from '../../fetch';
import { getBase64 } from '../../helpers/FileReader';
import colors from '../../utils/colors';
import { fromUnitAmount } from '../../utils/unitAmountHelper';
import NavDesktop from '../Nav/NavDesktop';
import NavMobile from '../Nav/NavMobile';
import { EventTypes } from '../Profile/Streaming/Helpers/eventTypes';
import styles from './createVideo.module.scss';
import SetPrice from './SetPrice';
import Share from './Share';
import UploadContent from './UploadContent';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import AddIcon from '@mui/icons-material/Add';

const mapStateToProps = (state) => {
    return {
        ...state.liveStreaming,
        user: state.user.currentUser,
        upload: state.common.upload,
        connected_account: state.common.connected_account,
        connected_products: state.common.connected_products,
        connected_prices: state.prices.connected_prices
    };
};
const mapDispatchToProps = (dispatch) => ({
    onNewEvent: (payload) => dispatch({ type: SCHEDULE_CREATE_EVENT, payload }),
    onPriceChange: (payload) => dispatch({ type: CONNECT_PRICE_CHANGE, payload })
});

const Home = (props) => {
    const [selectedCard, setSelectedCard] = useState(1);
    const date = new Date();
    const [now, setNow] = useState();
    const history = useHistory();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [minimumPrice, setMinimumPrice] = useState(0);
    const [price, setPrice] = useState(null);
    const [titleError, setTitleError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [priceError, setPriceError] = useState('');
    const { t } = useTranslation();
    const eventTypes = EventTypes();
    const [photo, setPhoto] = useState('');
    const [eventType, setEventType] = useState('video_event');
    const [streamType, setStreamType] = useState('url_pull');
    const [videoFile, setVideoFile] = useState(null);
    const [videoDuration, setVideoDuration] = useState(null);
    const [eventProducts, setEventProducts] = useState([]);
    const [jobId, setJobId] = useState(uuidv4());
    const [fileUpload, setFileUpload] = useState(false);
    const [fileUploadError, setFileUploadError] = useState('');
    const [defaultPrice, setDefaultPrice] = useState({});
    const [upload, setUpload] = useState();
    const [loading, setLoading] = useState(false);
    const [videoOrientation, setVideoOrientation] = useState('');
    const [finishedFlag, setFinishedFlag] = useState(false);
    const location = useLocation();
    const hasBackground = location.pathname === '/home';
    const [linkCreated, setLinkCreated] = useState(false);
    const [hasTrailer, setHasTrailer] = useState(false);

    useEffect(() => {
        let defaultValues = {};
        let showPriceField = {};
        if (props.connected_prices && props.connected_prices.length > 0) {
            for (const eventProduct of eventProducts) {
                showPriceField = { ...showPriceField, [eventProduct.id]: false };
                defaultValues[eventProduct.id] = props.connected_prices
                    .filter((p) => p.product === eventProduct.id)
                    .map((p) => {
                        fromUnitAmount(p.price.unit_amount, p.price.currency);
                    })
                    .pop();
            }
        }
        const price = [{ ...defaultValues }];
        setDefaultPrice(price);
    }, [props.connected_prices, eventProducts, finishedFlag]);

    useEffect(() => {
        if (jobId && props.upload) {
            const upload = props.upload.find((x) => x.jobId === jobId);
            setUpload(upload);
        }
    }, [props.upload, jobId]);

    useEffect(() => {
        if (props.connected_products && props.connected_products.length > 0) {
            setEventProducts(
                props.connected_products.filter((ca) => ca.metadata['setup-price'] === 'event')
            );
        }
    }, [props.connected_products, finishedFlag]);

    useEffect(() => {
        setFileUpload('');
        setTitle('');
        setVideoFile(null);
        setDescription('');
        setPrice('');
        setMinimumPrice(0);
        setNow(new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString());
    }, [finishedFlag]);

    // useEffect(() => {
    //     async function fetchData() {
    //         if (props.user.data != null) {
    //             const userData = props.user;
    //             if (userData.data['custom:log_counter'] === undefined) {
    //                 cognitoService.setLogNumber(1);
    //                 userData.data['custom:log_counter'] = parseInt('1');
    //                 props.onSetUserData(userData);
    //                 //  setOpenModal(true);
    //             } else {
    //                 const logNumber = parseInt(userData.data['custom:log_counter']);
    //                 if (logNumber < 10) {
    //                     cognitoService.setLogNumber(logNumber + 1);
    //                     userData.data['custom:log_counter'] = logNumber + 1;
    //                     props.onSetUserData(userData);
    //                 }
    //             }
    //         }
    //     }

    //     fetchData();
    // }, []);

    const createEvent = async () => {
        if (streamType === 'url_pull' && !fileUpload) {
            setFileUploadError(t('RequiredVideo'));
            setSelectedCard(1);
            return;
        }
        if (title.trim() === '') {
            setTitleError(t('RequiredTitle'));
            setSelectedCard(1);
            return;
        }
        if (description.trim() === '') {
            setDescriptionError(t('RequiredDescription'));
            setSelectedCard(1);
            return;
        }
        const formattedPrice = price.replace(',', '.');
        if (parseFloat(formattedPrice) < minimumPrice || formattedPrice === '') {
            setPriceError(t('RequiredPrice', { minimumPrice: `${minimumPrice.toString()}€` }));
            setSelectedCard(2);
            return;
        }

        const startDate = dayjs(undefined).valueOf() ?? now;
        let endDate = null;
        if (videoDuration && !isNaN(videoDuration) && startDate) {
            endDate = new Date(new Date(startDate).getTime() + Math.ceil(videoDuration) * 1000);
        }
        if (startDate) {
            if (startDate < now) {
                // presentToast('Please select date in the future', closeCircleSharp, 'danger', 1500);
            } else {
                let _thumbnail = null;
                setLoading(true);
                try {
                    if (photo) {
                        const base64Image = await getBase64(photo);
                        const data = base64Image.split(';base64,')[1];
                        const requestOptions = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({
                                img: {
                                    data,
                                    name: new Date().getTime()
                                }
                            })
                        };
                        const res = await fetchData.Event.UploadThumbnail(requestOptions);

                        _thumbnail = { ..._thumbnail, _ref: res._id };
                    }

                    const _url = upload?.upload_url;

                    const _eventSlug = title.toLowerCase().replace(/[^a-z0-9]/gi, '-');
                    const _eType = eventTypes.find((et) => et.value === eventType);
                    const _resource_creation_time = 1.5;
                    let priceId;

                    const [[product, default_price]] = Object.entries(defaultPrice[0]);
                    if (default_price != formattedPrice) {
                        const newPrice = await fetchData.Prices.Create(
                            product,
                            props.connected_account.accountId,
                            formattedPrice
                        );
                        priceId = newPrice.id;
                        props.onPriceChange({ product: product, price: parseInt(formattedPrice) });
                    } else {
                        priceId = props.connected_prices
                            .filter((pp) => pp.product === product)
                            .map((pp) => pp.price.id)
                            .pop();
                    }

                    const eventData = {
                        title: title,
                        slug: {
                            type: 'slug',
                            current: `${_eventSlug}`
                        },
                        description: description,
                        startDate: new Date(startDate),
                        endDate: new Date(endDate),
                        event_type: _eType.value,
                        stream_type: streamType,
                        endpoint: _url,
                        trailer: upload?.trailer_url,
                        streamStatus: 'upcoming',
                        processingStatus: _url ? CONVERT_STATUS : UPLOADING_STATUS,
                        user_id: props.user.data.sub,
                        tags: [_eType.title],
                        thumbnail: _thumbnail,
                        correlation_id: jobId,
                        resource_creation_time: _resource_creation_time,
                        pay_per_view_price: priceId,
                        pay_per_view_price_amount: formattedPrice,
                        pay_per_view_price_currency: 'eur',
                        subscriber_exclusive: true,
                        sports_team: {
                            _ref: process.env.REACT_APP_COMPANY_ID,
                            _type: 'reference'
                        },
                        convert_start_dates: [],
                        convert_end_dates: [],
                        convert_mode: videoOrientation
                    };

                    props.onNewEvent(eventData);

                    // props.setScheduleEventOpen
                    //     ? props.setScheduleEventOpen(false)
                    //     : setSelectedCard(3);
                    setFinishedFlag(!finishedFlag);
                    return eventData.correlation_id;
                } catch (e) {
                    console.error(e.message);
                } finally {
                    setLoading(false);
                }
            }
        }
    };

    const handleCardClick = (index) => {
        setSelectedCard(index);
    };

    const getCardStyle1 = (card) => {
        return {
            top: card === 1 ? '0%' : `${card * 10}%`,
            left: card === 1 ? '0%' : card === 2 ? '-5%' : '0%',
            width: card === 1 ? '100%' : card === 2 ? '110%' : '100%',
            zIndex: card === 1 ? 10 : card === 2 ? 2 : 1,
            transform: card === 1 ? 'scale(1.1)' : 'scale(0.9)'
        };
    };
    const getCardStyle2 = (card) => {
        return {
            top: card === 2 ? '0%' : card === 1 ? '-20%' : '20%',
            left: card === 2 ? '0%' : '-5%',
            width: card === 2 ? '100%' : '110%',
            zIndex: card === 2 ? 10 : 1,
            transform: card === 2 ? 'scale(1.1)' : 'scale(0.9)'
        };
    };
    const getCardStyle3 = (card) => {
        return {
            top: card === 3 ? '0%' : card === 2 ? '-20%' : '-30%',
            left: card === 3 ? '0%' : card === 2 ? '-5%' : '0%',
            width: card === 3 ? '100%' : card === 2 ? '110%' : '100%',
            zIndex: card === 3 ? 10 : card === 2 ? 2 : 1,
            transform: card === 3 ? 'scale(1.1)' : 'scale(0.9)'
        };
    };

    const getBoxStyle1 = (card) => {
        return {
            alignItems: 'flex-end',
            margin: card === 2 ? '-1rem 0 0 -5%' : '-1rem 0 0 0'
        };
    };
    const getBoxStyle2 = (card) => {
        return {
            alignItems: card === 1 ? 'flex-start' : 'flex-end',
            margin: card === 1 ? '0.5rem 0 0 -5%' : '-0.5rem 0 0 -5%'
        };
    };
    const getBoxStyle3 = (card) => {
        return {
            alignItems: 'flex-start',
            margin: card === 1 ? '1rem 0 0 0' : '1rem 0 0 -5%'
        };
    };
    return (
        <Box>
            {isMobile ? <NavMobile /> : <NavDesktop hasBackground={hasBackground} />}
            <Box className={styles.content}>
                <Box
                    className={styles.mainSection}
                    sx={{
                        marginTop:
                            selectedCard === 1
                                ? isMobile
                                    ? '5%'
                                    : '6%'
                                : selectedCard === 2
                                ? isMobile
                                    ? '40%'
                                    : '25%'
                                : selectedCard == 3
                                ? isMobile
                                    ? '25%'
                                    : '25%'
                                : ''
                    }}>
                    <Box
                        sx={{
                            position: 'relative',
                            width: 350,
                            height:
                                selectedCard === 1
                                    ? isMobile
                                        ? 480
                                        : 530
                                    : selectedCard === 2
                                    ? isMobile
                                        ? 310
                                        : 350
                                    : linkCreated
                                    ? 380
                                    : isMobile
                                    ? 380
                                    : 380
                        }}>
                        {[1, 2, 3].map((card) => (
                            <Card
                                key={card}
                                onClick={() => handleCardClick(card)}
                                sx={{
                                    display:
                                        linkCreated &&
                                        selectedCard === 3 &&
                                        (card === 1 || card === 2)
                                            ? 'none'
                                            : '',
                                    position: 'absolute',
                                    height: '100%',
                                    transition: 'all 0.4s ease',
                                    borderRadius: '34px',
                                    backgroundColor:
                                        card === 1
                                            ? `${colors.primary}`
                                            : card === 2
                                            ? `${colors.secondary}`
                                            : 'black',
                                    ...(selectedCard === 1
                                        ? getCardStyle1(card)
                                        : selectedCard === 2
                                        ? getCardStyle2(card)
                                        : getCardStyle3(card))
                                }}>
                                {selectedCard === card ? (
                                    <CardContent
                                        sx={{
                                            width: 'inherit',
                                            height: 'inherit',
                                            position: 'relative'
                                        }}>
                                        {card === 1 ? (
                                            <UploadContent
                                                title={title}
                                                description={description}
                                                setTitle={setTitle}
                                                setDescription={setDescription}
                                                setMinimumPrice={setMinimumPrice}
                                                setPhoto={setPhoto}
                                                selectedCard={selectedCard}
                                                fileUploadError={fileUploadError}
                                                titleError={titleError}
                                                descriptionError={descriptionError}
                                                setFileUploadError={setFileUploadError}
                                                setTitleError={setTitleError}
                                                setDescriptionError={setDescriptionError}
                                                setFileUpload={setFileUpload}
                                                fileUpload={fileUpload}
                                                videoFile={videoFile}
                                                setVideoFile={setVideoFile}
                                                setVideoOrientation={setVideoOrientation}
                                                setVideoDuration={setVideoDuration}
                                                jobId={jobId}
                                                setHasTrailer={setHasTrailer}
                                            />
                                        ) : card === 2 ? (
                                            <SetPrice
                                                minimumPrice={minimumPrice}
                                                price={price}
                                                setPrice={setPrice}
                                                selectedCard={selectedCard}
                                                priceError={priceError}
                                                setPriceError={setPriceError}
                                            />
                                        ) : (
                                            <Share
                                                createEvent={createEvent}
                                                selectedCard={selectedCard}
                                                setSelectedCard={setSelectedCard}
                                                videoProgress={upload}
                                                setLinkCreated={setLinkCreated}
                                                hasTrailer={hasTrailer}
                                            />
                                        )}
                                    </CardContent>
                                ) : (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            height: '100%',
                                            width: 'inherit',
                                            ...(selectedCard === 1
                                                ? getBoxStyle1(card)
                                                : selectedCard === 2
                                                ? getBoxStyle2(card)
                                                : getBoxStyle3(card))
                                        }}>
                                        {card === 1 && (
                                            <Typography fontSize={'20px'} color={'black'}>
                                                {upload?.upload_show ? t('Uploading') : t('Upload')}
                                            </Typography>
                                        )}
                                        {card === 2 && (
                                            <Typography fontSize={'20px'} color={'black'}>
                                                {card === 3 && price
                                                    ? t('VideoPrice', { price: price })
                                                    : t('SetPrice')}
                                            </Typography>
                                        )}
                                        {card === 3 && (
                                            <Typography fontSize={'20px'} color={'white'}>
                                                {t('CreateLink')}
                                            </Typography>
                                        )}
                                    </Box>
                                )}
                            </Card>
                        ))}
                    </Box>
                    {selectedCard === 3 && (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: linkCreated
                                    ? isMobile
                                        ? '80%'
                                        : '83%'
                                    : isMobile
                                    ? '80%'
                                    : '83%',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '1rem'
                            }}>
                            <Box
                                onClick={() => {
                                    setLinkCreated(false);
                                    setJobId(uuidv4());
                                    setSelectedCard(1);
                                }}
                                style={{
                                    width: '35px',
                                    height: '35px',
                                    backgroundColor: colors.primary,
                                    borderRadius: '14px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    cursor: 'pointer'
                                }}>
                                <AddIcon />
                            </Box>
                            <Typography>{t('StartNewUpload')}</Typography>
                        </Box>
                    )}
                </Box>
                {!isMobile && (
                    <Box className={styles.imageSection}>
                        <img className={styles.image} src={backgroundPhoto} alt="home-image" />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
