import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { goBack } from 'connected-react-router';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Resizer from 'react-image-file-resizer';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js';
import { SET_COMPANY_DATA } from '../../../constants/actionTypes';
import sanity from '../../../sanity';
import { changeDynamicFavicon, getTruncatedFileName, transformToSanityUrl } from '../../../utils';
import OnBack from '../../Auth/OnBack';
import { uploadCompanyLogo } from '../../core-components/uploadImageToSanity.js';
import styles from './customize.module.scss';

const mapStateToProps = (state) => {
    return {
        company: state.common.company
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGoBack: () => dispatch(goBack()),
    setCompanyData: (payload) => dispatch({ type: SET_COMPANY_DATA, payload })
});

const AppIconPage = (props) => {
    const [company, setCompany] = useState(props.editCompany);
    const [companyLogo, setCompanyLogo] = useState('');
    const { t } = useTranslation();
    const fileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(
        props.company ? sanity.urlFor(props.company.photo.asset) : null
    );
    const [predefinedMessages, setPredefinedMessages] = useState('');
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    // const [present] = useIonToast();
    // const presentToast = (message, icon, color) => {
    //     present({
    //         message: message,
    //         duration: 1500,
    //         position: 'top',
    //         icon: icon,
    //         color: color
    //     });
    // };
    const premiumOnly = process.env.REACT_APP_PREMIUM_ONLY === 'true';

    const resizeFile = (file) => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                300,
                300,
                'PNG',
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                'blob'
            );
        });
    };

    useEffect(() => {
        async function setPredefinedMessages() {
            await sanity.GlobalParams.GetGlobalParams();
        }
        setPredefinedMessages();
    });
    const [requestSendMessage, setRequestSendMessage] = useState(false);

    const handleFileUploadClick = () => {
        fileInputRef.current.click();
    };

    const [companyLogoPreview, setCompanyLogoPreview] = useState(
        company ? sanity.urlFor(company.photo.asset) : null
    );

    const handleLogoAndTextChange = async (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let currentFile = e.target.files[0];
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        const fileExtension = currentFile.name.split('.').pop().toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
            // presentToast(
            //     'Please select a valid image file (jpg, jpeg, png).',
            //     closeCircleSharp,
            //     'danger'
            // );
            return;
        }
        reader.onloadend = () => {
            setSelectedFile(reader.result);
            setImagePreview(reader.result);
            // currentFile = reader.result;
        };
        try {
            if (currentFile) {
                const image = await resizeFile(currentFile);
                setCompanyLogo(image);
                reader.readAsDataURL(image);
            }
        } catch (err) {
            console.error('ERROR with thumbnail: ', err);
        }
    };

    const handleRemoveImage = () => {
        setSelectedFile(null);
        setImagePreview(null);
    };

    const updateCompany = async () => {
        const logoAsset = {
            _ref: companyLogo ? companyLogo : '',
            _type: 'reference'
        };
        setLoading(true);
        try {
            let res = await uploadCompanyLogo(companyLogo);
            logoAsset._ref = res._id;
            const cmp = {
                photo: { _type: 'image', asset: logoAsset }
            };

            await sanity.Companies.EditCompany(props.company, cmp, props.company.creator);
            const image = sanity.urlFor(cmp.photo.asset);
            const transformedImage = transformToSanityUrl(image.options);
            changeDynamicFavicon(transformedImage);
            props.setCompanyData(sanity.Companies.GetCompanyById(process.env.REACT_APP_COMPANY_ID));
            setTimeout(() => {
                history.goBack();
            }, 1600);
        } catch (err) {
            // presentToast('Error uploading icon', closeCircleSharp, 'danger');
        } finally {
            setLoading(false);
            // presentToast('Company app icon changed.', checkmarkCircleSharp, 'success');
        }
    };

    return (
        <div>
            <OnBack
                defaultHref={premiumOnly ? '/profile/branding' : '/profile/customize'}
                title={t('AppIcon')}
                showButtonOrLoader={loading ? 'loader' : 'button'}
                buttonText={t('Save')}
                buttonDisabled={selectedFile === null}
                onClick={() => updateCompany()}
            />
            <div className={styles.profileWrapper} style={{ minHeight: '100%' }}>
                <div className={styles.header}></div>
                <div style={{ padding: '6vh 2vw 0vh 2vw' }}>
                    <Typography
                        className={styles.itemSubtitle}
                        style={{ textAlign: 'center', marginBottom: '1rem' }}>
                        {t('AppIconTip')}
                    </Typography>
                    {imagePreview && (
                        <div
                            style={{
                                margin: '0 auto',
                                position: 'relative',
                                width: '200px',
                                height: '200px'
                            }}>
                            <img
                                src={imagePreview}
                                style={{
                                    width: '200px',
                                    height: '200px',
                                    border: '1px solid black',
                                    borderRadius: '30px'
                                }}
                            />
                            <IconButton
                                onClick={handleRemoveImage}
                                sx={{
                                    background: 'lightgray',
                                    padding: '5px',
                                    position: 'absolute',
                                    top: '-10px',
                                    right: '-10px'
                                }}>
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    )}
                    <div
                        style={{
                            padding: '8vh 0 3vh 0'
                        }}>
                        <Typography style={{ marginBottom: '1vh' }}>
                            {t('UploadLogotype')}
                        </Typography>
                        <TextField
                            fullWidth
                            placeholder={t('UploadFile')}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{
                                style: {
                                    height: '5vh'
                                }
                            }}
                            InputProps={{
                                value: selectedFile ? getTruncatedFileName(selectedFile.name) : '',
                                readOnly: true,
                                onClick: handleFileUploadClick,
                                endAdornment: (
                                    <InputAdornment
                                        sx={{
                                            ' &:hover': {
                                                cursor: 'pointer'
                                            }
                                        }}
                                        position="end">
                                        <FileUploadOutlinedIcon
                                            style={{
                                                fontSize: '40px',
                                                color: 'white',
                                                backgroundColor: 'black',
                                                borderRadius: '4px'
                                            }}
                                        />
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            onChange={handleLogoAndTextChange}
                                        />
                                    </InputAdornment>
                                )
                            }}></TextField>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(AppIconPage);
