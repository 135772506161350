import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from '../profile.module.scss';
import { connect } from 'react-redux';
import colors from '../../../../utils/colors';

const mapStateToProps = (state) => {
    return {
        connected_account: state.common.connected_account
    };
};

const Payment = (props) => {
    const { t } = useTranslation();

    return (
        <Box>
            <Typography
                sx={{ marginBottom: '1rem', borderBottom: '1px solid rgba(0, 0, 0, 0.2)' }}
                fontSize={'36px'}>
                {t('Payment')}
            </Typography>
            {props?.connected_account ? (
                props.connected_account?.onboarded ? (
                    <Box className={styles.paymentStripe}>
                        <Typography fontSize={'18px'}>{t('Stripe')}</Typography>
                        <Button>{t('Connected')}</Button>
                    </Box>
                ) : null
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '1rem'
                    }}>
                    <CircularProgress sx={{ color: colors.primary }} />
                </Box>
            )}
        </Box>
    );
};

export default connect(mapStateToProps, null)(Payment);
