import {
    call,
    cancel,
    fork,
    put,
    take,
    takeEvery,
    select,
    delay,
    takeLatest
} from 'redux-saga/effects';
import {
    CONVERSION_STATUS,
    CONVERT_VIDEO,
    CREATE_EVENT,
    REMOVE_EVENT_FROM_QUEUE,
    SCHEDULE_CREATE_EVENT,
    UPDATE_EVENT,
    UPLOAD_PROGRESS,
    UPLOAD_REMOVE,
    UPLOAD_VIDEO,
    UPLOAD_VIDEO_FINISHED
} from '../constants/actionTypes';
import { buffers, END, eventChannel } from 'redux-saga';
import { Uploader } from '../components/Profile/UploadVideoMP';
import fetchData from '../fetch';
import axios from 'axios';
import { CONVERT_STATUS, UPLOADING_STATUS } from '../constants';

const convertVideo = (socket, body) => {
    if (!socket.Client || socket.Client.readyState !== WebSocket.OPEN) {
        setTimeout(() => convertVideo(socket, body), 300);
    } else {
        const payload = JSON.stringify({
            action: 'convertvideo',
            body: {
                ...body
            }
        });

        socket.Client.send(payload);
    }
};

const conversionStatus = (socket, body) => {
    if (!socket.Client || socket.Client.readyState !== WebSocket.OPEN) {
        setTimeout(() => conversionStatus(socket, body), 300);
    } else {
        const payload = JSON.stringify({
            action: 'conversionstatus',
            body: {
                ...body
            }
        });

        socket.Client.send(payload);
    }
};

function* uploadVideo(channel, action) {
    const { file, jobId, trailer } = action.payload;
    if (file) {
        while (true) {
            const { progress = 0, error, url, show } = yield take(channel, buffers.sliding(5));

            if (error) {
                yield put({
                    type: UPLOAD_PROGRESS,
                    payload: { progress, error, jobId, show, trailer }
                });
                channel.close();
                return;
            }
            if (url) {
                const payload = {
                    progress,
                    jobId,
                    processingStatus: CONVERT_STATUS
                };
                if (trailer) {
                    payload.trailer = url;
                } else {
                    payload.endpoint = url;
                }
                yield put({
                    type: UPLOAD_VIDEO_FINISHED,
                    payload: { progress, jobId, url, trailer }
                });
                channel.close();
                return;
            } else if (progress < 100) {
                yield put({ type: UPLOAD_PROGRESS, payload: { progress, jobId, show , trailer } });
            }
            // yield put({ type: UPLOAD_PROGRESS, payload: { progress, jobId, show } });
        }
    }
}

// Start the event channel in a forked task
function* startUpload(action) {
    const { file, type } = action.payload;
    const channel = yield call(uploadFileChannel, file, type);
    const task = yield fork(uploadVideo, channel, action);

    // Wait for a cancellation action
    yield take(UPLOAD_REMOVE); // Replace with your cancellation action type
    // Cancel the task and close the channel
    yield cancel(task);
}

// function* handleCreateEvent(action) {
//     const { correlation_id } = action.payload;
//     console.info("ACTION ITINATED WITH THIS PAYLOAD" , action.payload);
//     let isUploadComplete = false;
//     const maxRetries = 5;
//     let attempts = 0;
//     yield put({ type: 'CREATE_EVENT', payload: { ...action.payload } });
//     while (!isUploadComplete && attempts < maxRetries) {
//         const upload = yield select(state => state.common.upload.find((x) => x.jobId === correlation_id));
//         console.info("URL FORM SELECT ========> " , upload);
//         if (upload?.upload_url) {
//             console.info("ABOUT TO CREATE EVENT" ,  { ...action.payload , processingStatus:  upload.upload_url ? CONVERT_STATUS : UPLOADING_STATUS, endpoint: upload.upload_url , trailer: upload?.trailer });
//             yield put({ type: 'CREATE_EVENT', payload: { ...action.payload , processingStatus:  upload.upload_url ? CONVERT_STATUS : UPLOADING_STATUS, endpoint: upload.upload_url , trailer: upload?.trailer } });
//             isUploadComplete = true;
//         } else {
//             console.info("DELAY" ,attempts);
//             attempts += 1;
//             yield delay(1000);  // Wait 2 seconds before checking again
//         }
//     }
//
//     // if (!isUploadComplete) {
//     //     console.info("CREATE EVENT WITH WHAT YOU HAVE AND EXPECT UPDATE EVENT" , action.payload);
//     //
//     // }
// }

function* processCreateEventQueue() {
    while (true) {
        const createEventQueue = yield select((state) => state.common.createEventQueue);

        // Filter records that are still in processing status
        const recordsToProcess = createEventQueue.filter(
            (record) => record.processingStatus === CONVERT_STATUS
        );

        // Process each record in the queue
        for (const record of recordsToProcess) {
            try {
                yield put({ type: CREATE_EVENT, payload: { ...record } });

                yield put({
                    type: REMOVE_EVENT_FROM_QUEUE,
                    payload: { correlation_id: record.correlation_id }
                });
            } catch (error) {
                console.error('Error creating event for record:', record, error);
                // Optionally handle retry logic or mark the event with a failure status
            }
        }

        // Wait for a certain time before checking the queue again
        yield delay(1000); // Wait for 2 seconds (or any other interval)
    }
}

function uploadFileChannel(file, type) {
    return eventChannel((emitter) => {
        upload(emitter, file, type);
        // The subscriber must return an unsubscribe function
        return () => {
            emitter({ END });
        };
    });
}

async function uploadDummy(emitter, file, type) {
    let interval;
    let count = 0;

    function emitEvent() {
        count++;
        const event = count === 100 ? 'end' : 'event';
        emitter({ show: true, progress: count });

        if (event === 'end') {
            emitter({ show: false, url: 'http://dummy-url' });
            clearInterval(interval);
        }
    }

    interval = setInterval(emitEvent, 1000);

    // Function to cancel execution
    function cancel() {
        clearInterval(interval);
        console.log('Execution canceled.');
    }

    // Return a cancellation function
    return cancel;
}

async function upload(emitter, file, type) {
    let doneUploading = false;
    if (file?.size <= 10485760) {
        const { presignedPost, url } = await fetchData.Event.UploadVideo({
            method: 'POST',
            body: JSON.stringify({
                create: true,
                videoType: type
            })
        });

        const config = {
            onUploadProgress: function (progressEvent) {
                var percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );

                if (percentCompleted < 100) {
                    emitter({ show: true, progress: percentCompleted });
                }
            }
        };

        const formData = new FormData();
        Object.entries(presignedPost.fields).forEach(([k, v]) => {
            formData.append(k, v);
        });
        formData.append('file', file);
        await axios
            .post(`${presignedPost.url}`, formData, config)
            .then(() => {
                emitter({ show: false });
            })
            .catch((error) => {
                emitter({ error });
            });

        const finalUrl = await handleUploadFinish(url, type);
        emitter({ show: false, url: finalUrl });
    } else {
        const uploaderOptions = {
            file,
            chunkSize: 10,
            threadsQuantity: 6,
            useTransferAcceleration: false
        };
        const uploader = new Uploader(uploaderOptions);

        let percentage = undefined;

        uploader
            .onProgress(async ({ percentage: newPercentage }) => {
                // to avoid the same percentage to be logged twice
                if (percentage === 100 && !doneUploading) {
                    const finalUrl = await handleUploadFinish(uploader.fileKey, type);
                    doneUploading = true;
                    emitter({ show: false, url: finalUrl });
                }
                if (newPercentage !== percentage) {
                    percentage = newPercentage;
                    emitter({ show: true, progress: percentage });
                }
            })
            .onError((error) => {
                emitter({ error });
            });

        uploader.start();
    }
}

async function handleUploadFinish(url, type) {
    const signedOriginalUrl = await fetchData.Event.UploadVideo({
        method: 'POST',
        body: JSON.stringify({
            get: true,
            url: url,
            videoFileType: type
        })
    });
    return signedOriginalUrl.signedOriginalUrl[0];
}

const handleNewVideoMessages = function* handleNewVideoMessages({ socket }) {
    yield takeEvery(CONVERT_VIDEO, (action) => {
        convertVideo(socket, action.payload);
    });

    yield takeEvery(CONVERSION_STATUS, (action) => {
        conversionStatus(socket, action.payload);
    });

    yield takeEvery(UPLOAD_VIDEO, startUpload);
    yield takeLatest(SCHEDULE_CREATE_EVENT, processCreateEventQueue);
};

export default handleNewVideoMessages;
