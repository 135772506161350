import { Box, Button, Tab, Tabs, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import NavMobile from '../../Nav/NavMobile';
import NavDesktop from '../../Nav/NavDesktop';
import { useEffect, useState } from 'react';
import Payment from './Tabs/Payment';
import Analytics from './Tabs/Analytics';
import Upgrade from './Tabs/Upgrade';
import Support from './Tabs/Support';
import styles from './profile.module.scss';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import colors from '../../../utils/colors';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import fetch from '../../../fetch';
import { fromUnitAmount, toUnitAmount } from '../../../utils/unitAmountHelper';
import { getSymbolForCurrency } from '../../../utils';
import StatsMobile from './StatsMobile';
import StatsDesktop from './StatsDesktop';

const mapStateToProps = (state) => {
    return {
        company: state.common.company
    };
};
const mapDispatchToProps = (dispatch) => ({
    // onNewEvent: (payload) => dispatch({ type: CREATE_EVENT, payload }),
    // onPriceChange: (payload) => dispatch({ type: CONNECT_PRICE_CHANGE, payload })
});

const Profile = (props) => {
    const { t } = useTranslation();
    const [earnings, setEarnings] = useState(null);
    const allTabs = [t('Payment'), t('Analytics'), t('Upgrade'), t('Support')];
    const [totalEarned, setTotalEarned] = useState(null);
    const [totalStreams, setTotalStreams] = useState(null);
    const [currency, setCurrency] = useState('eur');
    const [totalStorage, setTotalStorage] = useState(null);
    const [activeTab, setActiveTab] = useState(0);

    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    useEffect(() => {
        const fetchEarnings = async () => {
            if (props.company) {
                const result = await fetch.StripeAccount.getEarnings(props.company._id);
                const earnings = result.earnings;
                setEarnings(earnings);
                let sum = 0;
                let count = 0;

                Object.entries(earnings).forEach(([key, value]) => {
                    const currencyKey = Object.keys(value)[0];
                    setCurrency(currencyKey);
                    const currencyData = value[currencyKey];

                    sum += fromUnitAmount(currencyData.total, currencyKey);
                    count += currencyData.count;
                });
                setTotalEarned(sum);
                setTotalStreams(count);
            }
        };
        if (props.company?.streamingStats?.storage) {
            setTotalStorage(props.company?.streamingStats?.storage);
        }
        fetchEarnings();
    }, [props.company]);

    return (
        <Box>
            {isMobile && <NavMobile />}
            <Box className={styles.topContainer}>
                {!isMobile && <NavDesktop />}
                <Box className={styles.content}>
                    <Box>
                        <Typography fontSize={'30px'}>{t('MyProfile')}</Typography>
                        <Typography fontSize={'30px'} sx={{ opacity: 0.6 }}>
                            {props?.company?.name}
                        </Typography>
                    </Box>
                    <Button>
                        <EditIcon sx={{ width: '18px', height: '18px' }} />
                        {isMobile ? '' : t('Edit')}
                    </Button>
                </Box>
                {isMobile ? (
                    <StatsMobile
                        currency={currency}
                        totalEarned={totalEarned}
                        totalStreams={totalStreams}
                        totalStorage={totalStorage}
                    />
                ) : (
                    <StatsDesktop
                        currency={currency}
                        totalEarned={totalEarned}
                        totalStreams={totalStreams}
                        totalStorage={totalStorage}
                    />
                )}
            </Box>
            {isMobile && (
                <Box sx={{ marginTop: '1rem' }}>
                    <Tabs
                        sx={{
                            '& .MuiTabs-indicator': {
                                backgroundColor: 'black'
                            }
                        }}
                        value={activeTab}
                        onChange={handleChange}>
                        {allTabs.map((tab, index) => (
                            <Tab
                                sx={{
                                    width: '25vw',
                                    borderBottom: '1px solid rgba(0, 0, 0, 0.20)',
                                    color: 'rgba(0, 0, 0, 0.60) !important'
                                }}
                                key={index}
                                label={tab}
                            />
                        ))}
                    </Tabs>
                    <Box className={styles.main}>
                        {activeTab === 0 && <Payment />}
                        {activeTab === 1 && <Analytics />}
                        {activeTab === 2 && <Upgrade />}
                        {activeTab === 3 && <Support />}
                    </Box>
                </Box>
            )}
            <Box className={styles.main}>
                {!isMobile && (
                    <>
                        <>
                            {activeTab === 0 && <Payment />}
                            {activeTab === 1 && <Analytics />}
                            {activeTab === 2 && <Upgrade />}
                            {activeTab === 3 && <Support />}
                        </>
                        <Box className={styles.settings}>
                            <Typography>{t('Settings')}</Typography>
                            <Box onClick={() => setActiveTab(0)}>
                                {activeTab === 0 && <ArrowBackIosIcon />}
                                {t('Payment')}
                            </Box>
                            <Box onClick={() => setActiveTab(1)}>
                                {' '}
                                {activeTab === 1 && <ArrowBackIosIcon />}
                                {t('Analytics')}
                            </Box>
                            <Box onClick={() => setActiveTab(2)}>
                                {' '}
                                {activeTab === 2 && <ArrowBackIosIcon />}
                                {t('Upgrade')}
                            </Box>
                            <Box onClick={() => setActiveTab(3)}>
                                {' '}
                                {activeTab === 3 && <ArrowBackIosIcon />}
                                {t('Support')}
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
