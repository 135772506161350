import BlockContent from '@sanity/block-content-to-react';
import React, { useEffect, useState } from 'react';
import sanity from '../../sanity.js';
import OnBack from '../Auth/OnBack';
import styles from './privacy.module.scss';

function CookiePolicy() {
    const [cookiePolicy, setCookiePolicy] = useState(false);
    useEffect(() => {
        const getPage = async () => {
            const cookiePolicyBlocks = await sanity.General.GetPage(
                'cookiePolicy',
                process.env.REACT_APP_COMPANY_ID
            );
            setCookiePolicy(cookiePolicyBlocks);
        };
        getPage();
    }, []);
    return (
        <div>
            <OnBack defaultHref={'/register'} title={cookiePolicy.subtitle} />
            <div className={styles.privacy}>
                <div className={styles.content_wrap}>
                    <BlockContent blocks={cookiePolicy.body} />
                </div>
            </div>
        </div>
    );
}

export default CookiePolicy;
