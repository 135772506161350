import {
    Button,
    ButtonGroup,
    ClickAwayListener,
    FormControl,
    Grow,
    IconButton,
    InputAdornment,
    MenuItem,
    MenuList,
    OutlinedInput,
    Paper,
    Popper
} from '@mui/material';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import { goBack } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { CHANGE_USER_GROUP, GET_FOLLOWERS } from '../../constants/actionTypes';
import OnBack from '../Auth/OnBack';
import styles from './settings.module.scss';
import { isMobile } from 'react-device-detect';
import stylesSearch from '../Search/search.module.scss';
import CancelIcon from '@mui/icons-material/Cancel';
import searchIcon from '../../assets/images/search-icon.svg';

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser.data,
        followers: state.user.currentUser.followers
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGoBack: () => dispatch(goBack()),
    onChangeUserGroup: (payload) => dispatch({ type: CHANGE_USER_GROUP, payload }),
    onLoadFollowers: (payload) => dispatch({ type: GET_FOLLOWERS, payload })
});
const ADMIN_GROUP = 'Admin';
const MODERATOR_GROUP = 'Moderator';
const Followers = (props) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState({});
    const [selectedIndex, setSelectedIndex] = useState({});
    const [searchMode, setSearchMode] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [followersList, setFollowersList] = useState([]);
    const anchorRef = React.useRef([]);
    const options = [
        { name: 'User actions' },
        { condition: { admin: false, group: ADMIN_GROUP }, name: 'Promote to Admin' },
        { condition: { admin: true, group: ADMIN_GROUP, type: 'remove' }, name: 'Demote Admin' },
        { condition: { moderator: false, group: MODERATOR_GROUP }, name: 'Promote to Moderator' },
        {
            condition: { moderator: true, group: MODERATOR_GROUP, type: 'remove' },
            name: 'Demote Moderator'
        }
    ];

    const onBack = () => {
        props.onGoBack();
    };

    useEffect(() => {
        props.onLoadFollowers({ company_id: process.env.REACT_APP_COMPANY_ID });
    }, []);

    useEffect(() => {
        setFollowersList(props.followers);
        let index = {};
        let open = {};
        // console.info('FOLLOWERS', props.followers);
        if (props.followers) {
            for (const follower of props.followers) {
                index = { ...index, [follower.username]: 0 };
                open = { ...open, [follower.username]: false };
            }
        }
        setSelectedIndex(index);
        setOpen(open);
    }, [props.followers]);

    useEffect(() => {
        if (followersList) {
            const filteredFollowers = props.followers.filter((user) => {
                // user.attributes.nickname
                //     ? user.attributes.nickname.toLowerCase().includes(searchQuery) ||
                //       user.attributes.email.toLowerCase().includes(searchQuery)
                console.log('SEARchq: ', searchQuery);
                console.log('EMAs :', user.attributes.email);
                return user.attributes.email.toLowerCase().includes(searchQuery);
            });
            console.log('FILTER: ', filteredFollowers);
            setFollowersList(filteredFollowers);
        }
    }, [searchQuery]);

    const changeUserGroup = (user, condition) => {
        // console.info(user, condition);
        props.onChangeUserGroup({
            company_id: process.env.REACT_APP_COMPANY_ID,
            type: condition.type,
            group: condition.group,
            user: user.username
        });
    };

    const handleMenuItemClick = (event, user, index) => {
        // console.info('user , index', user, index);
        setSelectedIndex({ ...selectedIndex, [user.username]: index });
        setOpen({ ...open, [user.username]: false });
    };

    const handleToggle = (event, user) => {
        setOpen({ ...open, [user.username]: !open[user.username] });
    };

    const handleClose = (event, index, user) => {
        if (anchorRef.current[index] && anchorRef.current[index].contains(event.target)) {
            return;
        }

        setOpen({ ...open, [user.username]: false });
    };

    const getInitials = (user) => {
        let nickname = user.nickname;
        if (!nickname) {
            return 'CB';
        }
        return nickname.length === 1
            ? nickname[0].substring(0, 2)
            : nickname[0].charAt(0) + nickname[nickname.length - 1].charAt(0);
    };
    // console.info('OPEN', open);
    // console.info('index', selectedIndex);
    return (
        <div>
            <OnBack defaultHref={'/profile/myvideos'} title={t('Followers')} />
            <div className={styles.profileWrapper}>
                <div className={stylesSearch.header}>
                    <div style={{ display: 'flex', width: '100%' }}>
                        <FormControl fullWidth>
                            <OutlinedInput
                                inputProps={{
                                    style: {
                                        border: 'none',
                                        marginBottom: 0,
                                        backgroundColor: '#E4E4E4'
                                    }
                                }}
                                sx={{
                                    paddingRight: '0',
                                    borderColor: 'transparent',
                                    backgroundColor: '#E4E4E4',
                                    '& fieldset': { border: 'none' }
                                }}
                                id="outlined-adornment-amount"
                                placeholder="Search"
                                size="small"
                                value={searchQuery}
                                onClick={() => setSearchMode(true)}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <img src={searchIcon} />
                                    </InputAdornment>
                                }
                                endAdornment={
                                    searchQuery && (
                                        <IconButton
                                            sx={{ color: '#ccc' }}
                                            onClick={() => {
                                                setSearchMode(true);
                                                setSearchQuery('');
                                            }}>
                                            <CancelIcon />
                                        </IconButton>
                                    )
                                }
                            />
                        </FormControl>
                        {searchMode && (
                            <Button
                                sx={{ color: '#666', textTransform: 'capitalize' }}
                                onClick={() => {
                                    setSearchMode(false);
                                    setSearchQuery('');
                                }}>
                                Cancel
                            </Button>
                        )}
                    </div>
                    <div></div>
                </div>
                <main>
                    <div
                        className={styles.settingsList}
                        style={{
                            background: 'white',
                            border: 'none'
                        }}>
                        {followersList &&
                            followersList.map((user, index) => {
                                return (
                                    <div
                                        key={index}
                                        style={{
                                            display: 'grid',
                                            gridTemplateColumns: isMobile
                                                ? '8vw 35vw 173px'
                                                : '15vw 1fr 173px',
                                            gap: '8px',
                                            border: 'none'
                                        }}>
                                        <div style={{ gridColumn: 1 }}>
                                            <span
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    fontWeight: 'normal'
                                                }}>
                                                {user.avatar ? (
                                                    <img
                                                        style={{
                                                            width: '35px',
                                                            height: '35px',
                                                            borderRadius: '40px',
                                                            objectFit: 'cover'
                                                        }}
                                                        src={user.avatar}
                                                        alt="Block Icon"
                                                    />
                                                ) : (
                                                    <div className={styles.profilePictureGenerated}>
                                                        {getInitials(user.attributes)}
                                                    </div>
                                                )}

                                                {!isMobile && (
                                                    <span
                                                        style={{
                                                            margin: '8px',
                                                            color: 'black'
                                                        }}>
                                                        {user.attributes.nickname}
                                                    </span>
                                                )}
                                            </span>
                                        </div>
                                        <span
                                            style={{
                                                gridColumn: 2,
                                                color: 'black',
                                                fontSize: '14px',
                                                wordBreak: 'break-word'
                                                // marginLeft: isMobile ? '30px' : ''
                                            }}>
                                            {user.attributes.email}
                                        </span>
                                        <div
                                            style={{
                                                gridColumn: 3,
                                                justifySelf: 'flex-end'
                                            }}>
                                            <ButtonGroup
                                                variant="contained"
                                                ref={(element) =>
                                                    (anchorRef.current[index] = element)
                                                }
                                                aria-label="split button">
                                                <Button
                                                    onClick={() =>
                                                        changeUserGroup(
                                                            user,
                                                            options[selectedIndex[user.username]]
                                                                ?.condition
                                                        )
                                                    }>
                                                    {options[selectedIndex[user.username]]?.name}
                                                </Button>
                                                <Button
                                                    size="small"
                                                    aria-controls={
                                                        open[user.username]
                                                            ? 'split-button-menu'
                                                            : undefined
                                                    }
                                                    aria-expanded={
                                                        open[user.username] ? 'true' : undefined
                                                    }
                                                    aria-label="select merge strategy"
                                                    aria-haspopup="menu"
                                                    onClick={(e) => handleToggle(e, user)}>
                                                    <ArrowDropDownIcon />
                                                </Button>
                                            </ButtonGroup>
                                        </div>
                                        <Popper
                                            sx={{
                                                zIndex: 1
                                            }}
                                            open={open[user.username] || false}
                                            anchorEl={anchorRef.current[index]}
                                            role={undefined}
                                            transition
                                            disablePortal>
                                            {({ TransitionProps, placement }) => (
                                                <Grow
                                                    {...TransitionProps}
                                                    style={{
                                                        transformOrigin:
                                                            placement === 'bottom'
                                                                ? 'center top'
                                                                : 'center bottom'
                                                    }}>
                                                    <Paper>
                                                        <ClickAwayListener
                                                            onClickAway={(e) =>
                                                                handleClose(e, index, user)
                                                            }>
                                                            <MenuList
                                                                id="split-button-menu"
                                                                autoFocusItem>
                                                                {options.map(
                                                                    (option, index) =>
                                                                        index > 0 && (
                                                                            <MenuItem
                                                                                key={index}
                                                                                disabled={
                                                                                    !(
                                                                                        option
                                                                                            .condition
                                                                                            ?.admin ===
                                                                                            user
                                                                                                .attributes
                                                                                                .admin ||
                                                                                        option
                                                                                            .condition
                                                                                            ?.moderator ===
                                                                                            user
                                                                                                .attributes
                                                                                                .moderator
                                                                                    )
                                                                                }
                                                                                selected={
                                                                                    index ===
                                                                                    selectedIndex[
                                                                                        user
                                                                                            .username
                                                                                    ]
                                                                                }
                                                                                onClick={(event) =>
                                                                                    handleMenuItemClick(
                                                                                        event,
                                                                                        user,
                                                                                        index
                                                                                    )
                                                                                }>
                                                                                {option.name}
                                                                            </MenuItem>
                                                                        )
                                                                )}
                                                            </MenuList>
                                                        </ClickAwayListener>
                                                    </Paper>
                                                </Grow>
                                            )}
                                        </Popper>
                                    </div>
                                );
                            })}
                    </div>
                </main>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Followers);
