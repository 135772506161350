import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import styles from './login.module.scss';
import EnterCode from './EnterCode';
import { useTranslation } from 'react-i18next';
import { SET_USER_DATA, SPINNER_VISIBLE } from '../../constants/actionTypes';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { useState } from 'react';

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser
    };
};

const mapDispatchToProps = (dispatch) => ({
    onSetUserData: (payload) => dispatch({ type: SET_USER_DATA, payload }),
    onSpinner: (payload) => dispatch({ type: SPINNER_VISIBLE, payload })
});

const AccountCreation = (props) => {
    const { t } = useTranslation();
    const [placeholder, setPlaceholder] = useState('e.g. arnold@mail.com');

    return (
        <>
            <Typography sx={{ marginBottom: '1rem' }}>{t('YourEmail')}</Typography>
            <TextField
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            border: 'none'
                        }
                    }
                }}
                type="email"
                onChange={(e) => props.setUsername(e.target.value)}
                placeholder={placeholder}
                onBlur={(e) => {
                    if (e.target.value === '') {
                        setPlaceholder(t('e.g. arnold@mail.com'));
                    }
                }}
                value={props.username}
                onFocus={() => {
                    setPlaceholder('');
                    props.setEmailError('');
                }}
                InputProps={{
                    sx: {
                        backgroundColor: '#F2F2F2',
                        border: 'none !important',
                        borderRadius: '8px'
                    }
                }}
            />
            {props.emailError && <Typography color={'red'}>{props.emailError}</Typography>}
            {props.confirmCode && <EnterCode isLogin={props?.isLogin ? props?.isLogin : false} />}
            {!props.confirmCode ? (
                props.loading ? (
                    <Box className={styles.flexProgress}>
                        <CircularProgress size={60} />
                    </Box>
                ) : (
                    <Button
                        onClick={props.handleSubmit}
                        disabled={props.username.length < 1}
                        className={
                            props.username.length < 1
                                ? styles.disabledCodeButton
                                : styles.getCodeButton
                        }>
                        {t('GetCode')}
                    </Button>
                )
            ) : null}
        </>
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountCreation);
