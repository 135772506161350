import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { SET_USER_DATA, SPINNER_VISIBLE } from '../../constants/actionTypes';
import cognitoService from '../../core/service/cognito.service';
import styles from '../../styles/auth.module.scss';
import OnBack from './OnBack';
import SubmitButton from './SubmitButton';

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser
    };
};

const mapDispatchToProps = (dispatch) => ({
    onSetUserData: (payload) => dispatch({ type: SET_USER_DATA, payload }),
    onSpinner: (payload) => dispatch({ type: SPINNER_VISIBLE, payload })
});

const EnterNameStep = (props) => {
    const { t } = useTranslation();
    const [name, setName] = useState(props.user.name || '');
    const [surname, setSurname] = useState(props.user.surname || '');
    const [nickname, setNickname] = useState('');
    const history = useHistory();
    const [nameErrorMessage, setNameErrorMessage] = useState('');
    const [surnameErrorMessage, setSurnameErrorMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!name.trim()) {
                setNameErrorMessage(t('RequiredName'));
                return;
            }

            if (!surname.trim()) {
                setSurnameErrorMessage(t('RequiredSurname'));
                return;
            }

            let nick = nickname.trim();
            if (!nick) {
                nick = name.trim() + ' ' + surname.trim();
            }

            props.onSpinner(true);
            const resp = await cognitoService.updateUser(name.trim(), surname.trim(), nick);
            props.onSpinner(false);
            if (resp) {
                props.onSetUserData({ name, surname });
                history.push('/upload-image');
            }
        } catch (e) {
            props.onSpinner(false);
        }
    };
    const onBack = () => {
        history.goBack();
    };
    return (
        <div>
            <OnBack
                defaultHref={'/confirmation'}
                disableBack={true}
                onBack={onBack}
                title={t('WhatIsYourName')}
            />
            <div className={styles.authWrapper}>
                <form onSubmit={handleSubmit} style={{ flex: 0 }}>
                    <main>
                        <input
                            type="text"
                            placeholder={t('Name')}
                            onChange={(e) => {
                                setName(e.target.value);
                                setNameErrorMessage('');
                            }}
                            value={name}
                            style={{
                                marginTop: '36px',
                                border: nameErrorMessage ? '1px solid red' : ''
                            }}
                        />
                        <p style={{ marginBottom: '12px' }} className={styles.authError}>
                            {nameErrorMessage}
                        </p>
                        <input
                            type="text"
                            placeholder={t('Surname')}
                            onChange={(e) => {
                                setSurname(e.target.value);
                                setSurnameErrorMessage('');
                            }}
                            value={surname}
                            style={{ border: surnameErrorMessage ? '1px solid red' : '' }}
                        />
                        <p style={{ marginBottom: '12px' }} className={styles.authError}>
                            {surnameErrorMessage}
                        </p>
                        <p>{t('NicknameQuestion')}</p>
                        <input
                            type="text"
                            placeholder={t('Nickname')}
                            onChange={(e) => setNickname(e.target.value)}
                            value={nickname}
                        />
                    </main>
                    <SubmitButton buttonText={t('Next')} />
                </form>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(EnterNameStep);
