import { Box, Button, CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { REFETCH_USER_DATA, SET_USER_DATA, SOCKET_CONNNECT } from '../../constants/actionTypes';
import cognitoService from '../../core/service/cognito.service';
import styles from './login.module.scss';

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser
    };
};

const mapDispatchToProps = (dispatch) => ({
    onSetUserData: (payload) => dispatch({ type: SET_USER_DATA, payload }),
    onSocketConnect: () => dispatch({ type: SOCKET_CONNNECT }),
    onRefetchUserData: () => dispatch({ type: REFETCH_USER_DATA })
});

const EnterCode = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [code, setCode] = useState();
    const [errorMessage, setErrorMessage] = useState('');
    const SUCCESS_MESSAGE = 'DONE';
    const [codeInputs, setCodeInputs] = useState(['', '', '', '']);
    const [disabledButton, setDisabledButton] = useState(true);
    const [loading, setLoading] = useState(false);
    const [redBorder, setRedBorder] = useState(false);

    useEffect(() => {
        if (code && code.length === 4) {
            setDisabledButton(false);
        }
    }, [code]);

    const isNumeric = (val) => {
        return /^\d+$/.test(val);
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            const resp = await cognitoService.respondToChallengeSMS(code);
            if (resp) {
                const currentUser = await cognitoService.getUserData();
                console.log('Current USER: ', currentUser);
                setErrorMessage(null);
                codeSuccess();
                props.onSocketConnect();
                props.onSetUserData(currentUser);
                props.onRefetchUserData();
                setLoading(false);
                if (currentUser?.groups && currentUser?.groups.includes('Admin')) {
                    return;
                } else {
                    history.push('/my-library');
                }
            }
        } catch (e) {
            setDisabledButton(true);
            if (e.code && e.code === 'NotAuthorizedException') {
                props.onSetUserData({ step: false });
                // history.push('/');
            }
            setErrorMessage(e.message);
            setRedBorder(true);
            let codeInputs = Array.from(document.getElementsByClassName('code'));
            if (codeInputs && codeInputs.length) {
                codeInputs.forEach((c) => (c.value = ''));
            }
            setLoading(false);
        } finally {
            // setLoading(false);
        }
    };

    const codeSuccess = async () => {
        let codeInputs = Array.from(document.getElementsByClassName('code'));
        const message = SUCCESS_MESSAGE.split('');
        codeInputs.forEach((c, index) => {
            c.type = 'text';
            c.value = message[index];
        });
    };

    const handleCodeInput = (event, index) => {
        //let value = window.innerWidth < 600 ? event.key : event.target.value;
        let value = event.key;
        let dataToUpdate;
        //check for paste code event
        if (event.code == 'MetaLeft' || event.code == 'KeyV') {
            return;
        }
        if (event.code == 'Backspace') {
            handleBackspace(index);
            return;
        }
        if (value.length <= 1) {
            const newCodeInputs = [...codeInputs];
            if (!isNumeric(value)) {
                value = '';
            }
            newCodeInputs[index] = value;
            setCodeInputs(newCodeInputs);

            // Move focus to next input field automatically
            if (value.length === 1 && index !== codeInputs.length - 1) {
                const nextInput = event.target.nextElementSibling;
                nextInput.focus();
            }
            if (newCodeInputs.filter((c) => c !== '').length === codeInputs.length) {
                dataToUpdate = newCodeInputs.map((c) => c).join('');
                setCode(dataToUpdate);
            }
        }
    };

    const handlePasteCode = (event) => {
        const newCodeInputs = [...codeInputs];
        setCode(event.clipboardData.getData('text/plain'));
        const code = event.clipboardData
            .getData('text/plain')
            .split('')
            .slice(0, codeInputs.length);
        code.map((n, index) => {
            newCodeInputs[index] = n;
            setCodeInputs(newCodeInputs);
        });
        setCodeInputs(code);
        const nextInput = document.getElementById(`input${codeInputs.length - 1}`);
        nextInput.focus();
    };

    const handleBackspace = (i) => {
        let inputData = Array.from(document.getElementsByClassName('code'));
        let dataToUpdate;
        const newCodeInputs = [...codeInputs];
        newCodeInputs[i] = '';
        setCodeInputs(newCodeInputs);
        dataToUpdate = inputData.map((c) => c.value).join('');
        //setCode(dataToUpdate);
        if (i > 0) {
            const nextInput = document.getElementById(`input${i - 1}`);
            nextInput.focus();
        }
    };

    const handleOnChange = () => {
        setRedBorder(false);
        return;
    };

    return (
        <Box>
            <Typography margin={'1rem 0'}>{t('CheckInbox')}</Typography>
            <Box id={'code-group'} className={styles.codeSection}>
                {codeInputs.map((code, index) => (
                    <input
                        onChange={handleOnChange}
                        type="number"
                        inputMode="numeric"
                        autoComplete="one-time-code"
                        pattern="\d{1}"
                        key={index}
                        style={{
                            textAlign: 'center',
                            fontSize: '32px',
                            padding: 0,
                            border: redBorder ? '1px solid red' : 'none',
                            borderRadius: '8px',
                            color: redBorder ? 'red' : '',
                            height: isMobile ? '18vw' : '7vw',
                            width: isMobile ? '20vw' : '8vw',
                            backgroundColor: '#F2F2F2'
                        }}
                        value={code}
                        onKeyUp={(event) => handleCodeInput(event, index)}
                        onPaste={handlePasteCode}
                        id={`input${index}`}
                    />
                ))}
            </Box>
            <Typography>{errorMessage}</Typography>
            {loading ? (
                <Box className={styles.flexProgress}>
                    <CircularProgress size={60} />
                </Box>
            ) : (
                <Button
                    fullWidth
                    disabled={disabledButton}
                    onClick={handleSubmit}
                    className={styles.getCodeButton}>
                    {props?.isLogin ? t('ConfirmLogin') : t('ConfirmSignUp')}
                </Button>
            )}
        </Box>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(EnterCode);
