import { Button, CircularProgress, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { SET_USER_DATA, SPINNER_VISIBLE } from '../../../constants/actionTypes';
import { default as api, default as fetch } from '../../../fetch';
import { getSymbolForCurrency } from '../../../utils';
import { fromUnitAmount } from '../../../utils/unitAmountHelper';
import OnBack from '../../Auth/OnBack';
import stylesModal from '../../Profile/Customize/customize.module.scss';
import styles from '../Customize/customize.module.scss';

const mapDispatchToProps = (dispatch) => ({
    onSpinner: (payload) => dispatch({ type: SPINNER_VISIBLE, payload }),
    onLoad: (payload) => dispatch({ type: SET_USER_DATA, payload })
});

const UserSubscriptions = (props) => {
    const { t } = useTranslation();
    // const [planSelected, setPlanSelected] = useState(false);
    const company = useSelector((state) => state.common.company);
    const user = useSelector((state) => state.user.currentUser);
    const isAdmin = useSelector(
        (state) => state.user.currentUser.groups && state.user.currentUser.groups.includes('Admin')
    );
    const connected_account = useSelector((state) => state.common.connected_account);
    const [buttonText, setButtonText] = useState('Cancel Plan');
    const [transactionList, setTransactionsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const parseStatus = (date) => {
        const originalDate = new Date(date * 1000);
        const nextMonth = new Date(originalDate);
        nextMonth.setMonth(nextMonth.getMonth() + 1);

        return `${nextMonth.toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric'
        })}`;
    };

    const cancelSubscription = async (subscriptionId) => {
        try {
            props.onSpinner(true);
            const accountId = !isAdmin ? connected_account?.accountId : undefined;
            await fetch.Subscription.Update(subscriptionId, accountId);
            setTimeout(refreshSubscriptionStatus, 1000);
        } catch (error) {
            console.log(error.message);
        } finally {
            props.onSpinner(false);
        }
    };

    const refreshSubscriptionStatus = async () => {
        const subscriptionStatus = await api.StripeAccount.getSubscriptionStatus(
            user.data.email,
            process.env.REACT_APP_COMPANY_ID,
            undefined
        );
        props.onLoad({ ...user, ...{ premium_services: subscriptionStatus } });
    };

    useEffect(() => {
        const fetchData = async () => {
            if (user && user.premium_services?.subscription) {
                setLoading(true);
                setButtonText('Cancel Plan');
                try {
                    const accountId = !isAdmin ? connected_account?.accountId : undefined;
                    const transactionsList = await fetch.Invoice.getAllInvoices(
                        user.premium_services?.subscription,
                        accountId
                    );
                    setTransactionsList(transactionsList);
                } catch (error) {
                    console.error('Error:', error);
                }

                setLoading(false);
            } else if (!user.premium_services?.subscription) {
                setButtonText('Upgrade');
            }
        };

        fetchData();
    }, [user]);

    return (
        <div>
            <OnBack defaultHref={'/profile'} title={t('Subscriptions')} />
            <div className={styles.profileWrapper} style={{ minHeight: '100%' }}>
                <div style={{ padding: '2vh 2vw' }}>
                    <Typography sx={{ color: 'rgba(60, 60, 67, 0.6)' }} textAlign={'center'}>
                        {t('UserSubscriptions')}
                    </Typography>
                    {loading ? (
                        <div
                            style={{
                                position: 'relative',
                                marginTop: '2%'
                            }}>
                            <CircularProgress
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)'
                                }}
                            />
                        </div>
                    ) : (
                        <div>
                            {transactionList.map((item) => (
                                <div
                                    key={item.id}
                                    style={{
                                        border: '1px solid #CCC',
                                        borderRadius: '16px',
                                        textAlign: 'center',
                                        padding: '5%',
                                        backgroundColor: 'black',
                                        color: 'white',
                                        marginTop: '5vh'
                                    }}>
                                    <div>
                                        <Button
                                            sx={{
                                                backgroundColor: '#32E7B2',
                                                color: 'black !important',
                                                borderRadius: '48px',
                                                textTransform: 'capitalize',
                                                width: '100%',
                                                maxWidth: isMobile ? '30vw' : '10vw',
                                                '&:active, &:focus, &:hover': {
                                                    backgroundColor: '#32E7B2'
                                                }
                                            }}>
                                            {company?.name}
                                        </Button>
                                        <Typography fontSize={'24px'} sx={{ margin: '2vh 0' }}>
                                            {user.premium_services?.interval === 'month'
                                                ? 'Premium'
                                                : 'Day pass'}
                                        </Typography>
                                        <Typography fontSize={'17px'} color={'#CCC'}>
                                            {`${getSymbolForCurrency(
                                                item.currency
                                            )} ${fromUnitAmount(
                                                item.amount_paid + item.amount_remaining,
                                                item.currency
                                            )}`}{' '}
                                            /{' '}
                                            {user.premium_services?.interval === 'month'
                                                ? 'Month'
                                                : '24 hrs'}
                                        </Typography>

                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                margin: '2vh 0',
                                                borderTop: '1px solid  #CCC',
                                                paddingTop: '3vh'
                                            }}></div>
                                        <Typography fontSize={'17px'} color={'#888'}>
                                            Expires on {`${parseStatus(item.created)}`}
                                        </Typography>
                                    </div>
                                    <Button
                                        className={styles.previewButton}
                                        fullWidth
                                        onClick={() => setOpenModal(true)}
                                        sx={{
                                            backgroundColor:
                                                buttonText === 'Upgrade'
                                                    ? '#32E7B2'
                                                    : 'black !important',
                                            color:
                                                buttonText === 'Upgrade' ? '' : 'white !important',
                                            width: '100%',
                                            height: '8vh',
                                            marginTop: '5vh',
                                            border:
                                                buttonText === 'Upgrade'
                                                    ? ''
                                                    : '1px solid white !important',
                                            '&:active, &:focus, &:hover': {
                                                backgroundColor:
                                                    buttonText === 'Upgrade' ? '#32E7B2' : 'black'
                                            },
                                            fontFamily: 'InstrumentSans-Bold',
                                            borderRadius: '14px'
                                        }}>
                                        {buttonText}
                                    </Button>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <Modal open={openModal} onClose={() => setOpenModal(false)}>
                <div className={isMobile ? stylesModal.modalMobile : stylesModal.modal}>
                    <Typography
                        marginTop={isMobile ? '3%' : '-1%'}
                        fontSize={'18px'}
                        textAlign={'center'}>
                        {t('CancelSubscription')}
                    </Typography>
                    <div className={stylesModal.flexContainer}>
                        <Button
                            className={
                                isMobile
                                    ? stylesModal.confirmButtonMobile
                                    : stylesModal.confirmButton
                            }
                            onClick={() => {
                                cancelSubscription(user.premium_services.subscription);
                                setOpenModal(false);
                            }}>
                            {t('Yes')}
                        </Button>
                        <Button
                            className={
                                isMobile ? styles.cancelButtonMobile : stylesModal.cancelButton
                            }
                            onClick={() => {
                                setOpenModal(false);
                            }}>
                            {t('No')}
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default connect(null, mapDispatchToProps)(UserSubscriptions);
